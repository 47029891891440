import styled, { keyframes } from "styled-components";
import { useState, useEffect } from "react";
import {
  BodyMedium,
  BodySmallRegular,
  Heading2,
  Heading3Jobs,
} from "../../commonComponents/Text";
import JobsArrowIcon from "../../assets/images/jobsArrowIcon.svg";
import Input from "../../components/Input";
import { COUNTRIES, COUNTRIES_ALPHA_2 } from "../../utils/content";
import InputCombo from "../../components/InputCombo";
import UploadIcon from "../../assets/images/uploadIcon.svg";
import CloseModalIcon from "../../assets/images/closeModalIcon.svg";
import SuccessIcon from "../../assets/images/successIcon.svg";
import ComboboxWithFilter from "../../components/ComboboxWithFilter";
import emailjs from "@emailjs/browser";
import { storageRef } from "../../utils/firebase";
import LoaderModal from "../../commonComponents/LoaderModal";
import MarkerIcon from "../../assets/images/markerIcon.svg";

const JobForm = ({
  position,
  area,
  description,
  location,
}: {
  position: string;
  area: string;
  description: string;
  location: string;
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [fullName, setFullName] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [acronimus, setAcronimus] = useState<string>("US");
  const [phone, setPhone] = useState<string>("");
  const [country, setCountry] = useState<string>("");
  const [showModal, setShowModal] = useState<boolean>(false);
  const [countries, setCountries] = useState(COUNTRIES);
  const [filter, setFilter] = useState<string>("");
  const [complete, setComplete] = useState<boolean>(false);
  const [cv, setCv] = useState<any>(null);
  const [loaderSend, setLoaderSend] = useState<boolean>(false);
  const [emailValidate, setEmailValidate] = useState<boolean>(false);

  useEffect(() => {
    function filterCountries() {
      if (filter) {
        const filteredCountries = COUNTRIES.filter((country) =>
          country.toLowerCase().includes(filter.toLowerCase())
        );
        setCountries(filteredCountries);
      } else {
        setCountries(COUNTRIES);
      }
    }
    filterCountries();
  }, [filter]);

  useEffect(() => {
    function evaluateFormComplete() {
      const emailRegex =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      const phoneRegex = /^\+?[1-9]\d{1,14}$/;
      const validateEmail = emailRegex.test(email);
      const validatePhone = phoneRegex.test(phone);
      setEmailValidate(validateEmail);
      if (fullName && validateEmail && country && validatePhone && cv) {
        setComplete(true);
      } else {
        setComplete(false);
      }
    }

    evaluateFormComplete();
  }, [fullName, email, country, phone, cv]);

  async function handleSubmit() {
    if (cv) {
      try {
        setLoaderSend(true);
        const extension = cv[0].name.split(".").pop();
        const imageName = `${fullName}-${phone}-${position}.${extension}`;
        const imageRef = storageRef.child(
          "position/" + position + "/" + imageName
        );
        await imageRef.delete().catch(() => {}); // deletes prev image if exists
        await imageRef.put(cv[0]); // upload new image
        const cvUrl = await imageRef.getDownloadURL();

        const inputs = {
          fullName,
          email,
          acronimus,
          phone,
          country,
          cvUrl,
          position,
          area,
        };

        emailjs
          .send(
            "service_2pgmeaj",
            "template_4krtrth",
            inputs,
            "user_TXF9lD7Q4e7VeIu6q2Ojp"
          )
          .then(
            () => {
              setLoaderSend(false);
              setShowModal(true);
              setFullName("");
              setEmail("");
              setPhone("");
              setCountry("");
              setCv(null);
            },
            (error) => {
              setLoaderSend(false);
            }
          );
      } catch (error) {
        setLoaderSend(false);
      }
    }
  }

  return (
    <>
      <ContainerForm isOpen={isOpen}>
        <ShowFormContainer>
          <LeftContainer>
            <Heading3Jobs isSecondary>{position}</Heading3Jobs>
            <BodyMedium
              style={{
                color: "#9D9D9D",
              }}
            >
              {area}
            </BodyMedium>
          </LeftContainer>
          <RightContainer>
            <OpenIcon
              onClick={() => setIsOpen((prev) => !prev)}
              src={JobsArrowIcon}
              alt="arrow"
              isOpen={isOpen}
            />
          </RightContainer>
        </ShowFormContainer>
        <TopForm>
          <AddressContainer>
            <img src={MarkerIcon} alt="marker icon" />
            <BodyMedium>{location}</BodyMedium>
          </AddressContainer>
          <HtmlContainer
            dangerouslySetInnerHTML={{ __html: description }}
          ></HtmlContainer>
          <InputContainer>
            <BodyMedium>Full name</BodyMedium>
            <Input
              placeholder="Full name"
              value={fullName}
              onChange={(e) => setFullName(e.target.value)}
            />
          </InputContainer>
          <InputContainer>
            <BodyMedium>Email Address</BodyMedium>
            <Input
              placeholder="Example@domain.com"
              value={email}
              error={!emailValidate}
              onChange={(e) => setEmail(e.target.value)}
            />
          </InputContainer>
          <InputContainer>
            <BodyMedium>Phone</BodyMedium>
            <InputCombo
              options={COUNTRIES_ALPHA_2}
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
              placeholder="+1 (000) 000-000"
              acronimus={acronimus}
              setAcronimus={setAcronimus}
            />
          </InputContainer>
          <InputContainer
            style={{
              margin: 0,
            }}
          >
            <BodyMedium>Country</BodyMedium>
            <ComboboxWithFilter
              label={"Select a country"}
              options={countries}
              value={country}
              setValue={setCountry}
              filterInput={filter}
              setFilterInput={setFilter}
            />
          </InputContainer>
          <InputContainer>
            <LabelInput
              style={{
                display: "flex",
                width: "100%",
                justifyContent: "space-between",
              }}
              htmlFor="files"
            >
              Upload CV <img src={UploadIcon} alt="Upload Icon" />{" "}
            </LabelInput>
            <InputPdf
              id="files"
              type="file"
              accept=".pdf"
              onChange={(e) => setCv(e.target.files)}
            />
          </InputContainer>
        </TopForm>
        <SendButtonContainer>
          <Button
            isOpen={!isOpen}
            disabled={!complete}
            onClick={() => handleSubmit()}
          >
            Apply now
          </Button>
        </SendButtonContainer>
      </ContainerForm>
      {loaderSend && (
        <ModalContainer>
          <BackgroundModal
            className={loaderSend ? "show" : ""}
          ></BackgroundModal>
          <CardModal>
            <LoaderModal />
          </CardModal>
        </ModalContainer>
      )}
      {showModal && (
        <ModalContainer>
          <BackgroundModal
            onClick={() => setShowModal(false)}
            className={showModal ? "show" : ""}
          ></BackgroundModal>
          <CardModal>
            <CardBottomContainer>
              <img
                style={{
                  width: "100px",
                  height: "100px",
                }}
                src={SuccessIcon}
                alt="Success Icon"
              />
              <Heading2
                isSecondary
                style={{
                  textAlign: "center",
                  width: "100%",
                }}
              >
                Thanks for applying!
              </Heading2>
              <BodySmallRegular
                style={{
                  textAlign: "center",
                  width: "100%",
                }}
              >
                We will review your application and contact you as soon as
                possible.
              </BodySmallRegular>
            </CardBottomContainer>
            <CloseModal
              src={CloseModalIcon}
              onClick={() => setShowModal(false)}
            />
          </CardModal>
        </ModalContainer>
      )}
    </>
  );
};

const LabelInput = styled.label`
  background-color: transparent;
  border-radius: 5px;
  color: var(--primario, #3876ff);
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  padding: 16px;
  cursor: pointer;
  user-select: none;
  transition: all 0.3s;
  right: 0;
  top: 0px;
  border: 1px solid #3876ff;
  :focus {
    outline: none;
  }
  @media (max-width: 768px) {
    padding: 15px 15px;
    font-size: 13px;
    line-height: 13px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    right: auto;
    top: auto;
  }
  @media (max-width: 768px) {
    margin-top: 80px;
  }
`;
const InputPdf = styled.input`
  display: none;
`;
const HtmlContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 32px;
  p {
    font-family: Montserrat;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    text-align: start;
    color: #dfdfdf;
  }
  a {
    color: #dfdfdf;
    text-decoration: underline;
  }
  img {
    width: 100%;
    height: auto;
  }
  ul {
    list-style-type: none;
    padding-left: 30px;
    li {
      font-family: Montserrat;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 24px;
      text-align: start;
      color: #dfdfdf;
      margin-bottom: 10px;
      &:before {
        content: "•";
        color: #dfdfdf;
        display: inline-block;
        width: 1em;
        margin-left: -1em;
      }
    }
  }
  * {
    color: #dfdfdf;
  }
`;
const OpenIcon = styled.img<{ isOpen: boolean }>`
  cursor: pointer;
  transform: rotate(${(props) => (props.isOpen ? "90deg" : "0deg")});
  transition: all 0.3s ease-in-out;
`;
const ContainerForm = styled.div<{ isOpen: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  border-radius: 4px;
  width: 100%;
  height: ${(props) => (props.isOpen ? "auto" : "0px")};
  min-height: 100px;
  padding: 16px 0;
  transition: all 0.3s ease-in-out;
  overflow: hidden;
  @media (max-width: 768px) {
    min-height: auto;
    height: ${(props) => (props.isOpen ? "auto" : "100px")};
  }
`;
const ShowFormContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 100px;
  padding: 0 60px;
  transition: all 0.2s ease;
  border-bottom: 1px solid #333;
  padding-bottom: 16px;
  @media (max-width: 768px) {
    height: auto;
    padding: 0;
  }
`;
const LeftContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 80%;
  @media (max-width: 768px) {
    width: 100%;
    flex-direction: column;
    align-items: start;
  }
`;
const RightContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
`;
const Button = styled.button<{ isOpen: boolean; disabled: boolean }>`
  background: ${(props) => (props.disabled ? "#9D9D9D" : "#2e6fff")};
  border: none;
  border-radius: 4px;
  padding: 8px 16px;
  color: var(--gray-white, #fff);
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  outline: none;
  cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
  transition: all 0.3s ease-in-out;
  &:hover {
    background: ${(props) => (props.disabled ? "#9D9D9D" : "#1d4ed8")};
  }
`;
const TopForm = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  padding: 32px 60px;
  flex-wrap: wrap;
  @media (max-width: 768px) {
    flex-direction: column;
    padding: 32px 0;
  }
`;
const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: flex-start;
  width: 24%;
  margin-bottom: 16px;
  padding-right: 16px;
  gap: 8px;
  position: relative;
  input {
    width: 100%;
  }
  @media (max-width: 768px) {
    width: 100%;
  }
`;
const SendButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: flex-end;
  width: 100%;
  padding: 0 60px;
  padding-bottom: 60px;
  @media (max-width: 768px) {
    padding: 0;
    justify-content: flex-start;
    align-items: flex-start;
  }
`;
const FadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;
const ModalContainer = styled.div`
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 101;
  display: flex;
  justify-content: center;
  align-items: center;
  animation: ${FadeIn} 0.5s ease-in-out;
  top: 0;
  left: 0;
`;
const CloseModal = styled.img`
  width: 24px;
  height: 24px;
  position: absolute;
  top: 30px;
  right: 30px;
  cursor: pointer;
`;
const BackgroundModal = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  background: black;
  opacity: 0;
  z-index: -1;
  transition: all 0.3s ease-in-out;
  &.show {
    opacity: 0.5;
    z-index: 101;
  }
`;

const CardModal = styled.div`
  width: 660px;
  height: 500px;
  border-radius: 10px;
  border: 2px solid var(--primario, #3876ff);
  display: flex;
  flex-direction: column;
  z-index: 102;
  background: #000407;
  position: relative;
  @media (max-width: 768px) {
    width: 95%;
  }
`;
const CardBottomContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 40px 60px;
  border-radius: 10px;
  gap: 32px;
  background: linear-gradient(135deg, rgba(15, 22, 33, 0.3) 0%, #0f1621 100%);
  backdrop-filter: blur(5px);
  @media (max-width: 768px) {
    padding: 40px 8px;
  }
`;
const AddressContainer = styled.div`
  font-size: 1rem;
  color: white;
  display: flex;
  flex-direction: row;
  gap: 16px;
  width: 600px;
  justify-content: start;
  align-items: center;
  margin-bottom: 16px;
  @media (max-width: 768px) {
    padding-top: 32px;
  }
`;
export default JobForm;
