import Page from "../../commonComponents/Page";
import styled from "styled-components";
import { Link } from "react-router-dom";
import EditIcon from "@mui/icons-material/Edit";
import useSupportList from "../hooks/useSupportList";
import DeleteIcon from "@mui/icons-material/Delete";
export default function SupportList() {
  const { support, loading, error, handleDelete } = useSupportList();

  return (
    <Page
      title="Supports & Parternships"
      icon="support"
      addLink="supports-partners/add"
    >
      <Table>
        <Thead>
          <Tr>
            <Th>Name</Th>
            <Th>Type</Th>
            <Th>Image</Th>
            <Th>Actions</Th>
          </Tr>
        </Thead>
        <Tbody>
          {support?.length &&
            support.map((supportOrPartnership) => (
              <Tr key={supportOrPartnership.uid}>
                <Td>{supportOrPartnership.name}</Td>
                <Td>{supportOrPartnership.type}</Td>
                <Td>
                  <Img
                    src={supportOrPartnership.imageURL}
                    alt={supportOrPartnership.name}
                  />
                </Td>
                <Td>
                  <Link
                    to={"/admin/supports-partners/" + supportOrPartnership.uid}
                  >
                    <Span>
                      <EditIcon
                        style={{
                          fill: "#354b79",
                        }}
                      />
                    </Span>
                  </Link>
                  <Span
                    onClick={() => {
                      handleDelete(supportOrPartnership.uid);
                    }}
                  >
                    <DeleteIcon
                      style={{
                        fill: "#354b79",
                      }}
                    />
                  </Span>
                </Td>
              </Tr>
            ))}
          {!loading && !support?.length && (
            <Tr>
              <Td>No se encontraron Supports & Parterships</Td>
            </Tr>
          )}
          {loading && (
            <Tr>
              <Td>Cargando Supports & Parterships...</Td>
            </Tr>
          )}
          {error && (
            <Tr>
              <Td>Ha ocurrido un error al cargar los Supports & Parterships</Td>
            </Tr>
          )}
        </Tbody>
      </Table>
    </Page>
  );
}
const Img = styled.img`
  width: 60px;
  height: 100%;
`;
const Table = styled.table`
  width: 100%;
  color: #354b79;
`;
const Tr = styled.tr`
  color: #354b79;
`;
const Th = styled.th`
  padding: 0.5rem;
  color: #354b79;
`;
const Td = styled.td`
  padding: 0.5rem;
  color: #354b79;
  text-transform: capitalize;
`;
const Thead = styled.thead``;
const Tbody = styled.tbody`
  & ${Tr}:nth-of-type(even) {
    background-color: white;
  }
  & ${Tr}:nth-of-type(odd) {
    background-color: rgba(0, 0, 0, 0.05);
  }
`;
const Span = styled.span`
  color: #354b79;
  cursor: pointer;
`;
