import styled from "styled-components";
export default function Button({
  children,
  onClick,
  type,
}: {
  children: React.ReactNode;
  onClick?: () => void;
  type: "primary" | "secondary" | "disabled" | "back";
}) {
  return (
    <ButtonContainer color={type} onClick={onClick}>
      {children}
    </ButtonContainer>
  );
}

const ButtonContainer = styled.button`
  background-color: ${({ color }: { color: string }) => {
    switch (color) {
      case "primary":
        return "#354b79";
      case "secondary":
        return "#ffffff";
      case "disabled":
        return "#1282974d";
      case "back":
        return "#505050";
      default:
        return "#354b79";
    }
  }};
  color: ${({ color }: { color: string }) => {
    switch (color) {
      case "primary":
        return "#ffffff";
      case "secondary":
        return "#354b79";
      default:
        return "#ffffff";
    }
  }};
  border: ${({ color }: { color: string }) => {
    switch (color) {
      case "primary":
        return "none";
      case "secondary":
        return "1px solid #354b79";
      default:
        return "none";
    }
  }};
  border-radius: 5px;
  text-transform: uppercase;
  font-family: "Monserrat";
  font-size: 15px;
  line-height: 15px;
  padding: 20px 30px;
  cursor: pointer;
  user-select: none;
  transition: all 0.3s;
  :focus {
    outline: none;
  }
  @media (max-width: 768px) {
    padding: 15px 30px;
    font-size: 13px;
    line-height: 13px;
  }
`;
