import styled from "styled-components";
import { palette } from "../style/palette";
export function TextMDMedium({ children }: { children: React.ReactNode }) {
  return <TextMDMediumContainer>{children}</TextMDMediumContainer>;
}
export function TextMD({ children }: { children: React.ReactNode }) {
  return <TextMDContainer>{children}</TextMDContainer>;
}
export function TitleLarge({
  children,
  isSecondary,
  style,
}: {
  children: React.ReactNode;
  isSecondary?: boolean;
  style?: any;
}) {
  return (
    <TitleLargeContainer
      style={{
        color: isSecondary ? palette.light.primary : palette.light.white,
        ...style,
      }}
    >
      {children}
    </TitleLargeContainer>
  );
}
export function TitleMedium({
  children,
  isSecondary,
  style,
}: {
  children: React.ReactNode;
  isSecondary?: boolean;
  style?: any;
}) {
  return (
    <TitleMediumContainer
      style={{
        color: isSecondary ? palette.light.primary : palette.light.white,
        ...style,
      }}
    >
      {children}
    </TitleMediumContainer>
  );
}
export function TitleSmall({
  children,
  isSecondary,
  style,
}: {
  children: React.ReactNode;
  isSecondary?: boolean;
  style?: any;
}) {
  return (
    <TitleSmallContainer
      style={{
        color: isSecondary ? palette.light.primary : palette.light.white,
        ...style,
      }}
    >
      {children}
    </TitleSmallContainer>
  );
}
export function Label({ children }: { children: React.ReactNode }) {
  return <LabelContainer>{children}</LabelContainer>;
}
export function BodyLarge({
  children,
  style,
  isSecondary,
}: {
  children: React.ReactNode;
  style?: any;
  isSecondary?: boolean;
}) {
  return (
    <BodyLargeContainer
      style={{
        color: isSecondary ? palette.light.primary : palette.light.white,
        ...style,
      }}
    >
      {children}
    </BodyLargeContainer>
  );
}
export function BodyMedium({
  children,
  style,
  isSecondary,
}: {
  children: React.ReactNode;
  style?: any;
  isSecondary?: boolean;
}) {
  return (
    <BodyMediumContainer
      style={{
        color: isSecondary ? palette.light.primary : palette.light.white,
        ...style,
      }}
    >
      {children}
    </BodyMediumContainer>
  );
}
export function BodySmallRegular({
  children,
  style,
  isSecondary,
}: {
  children: React.ReactNode;
  style?: any;
  isSecondary?: boolean;
}) {
  return (
    <BodySmallRegularContainer
      style={{
        color: isSecondary ? palette.light.primary : palette.light.white,
        ...style,
      }}
    >
      {children}
    </BodySmallRegularContainer>
  );
}
export function Heading1({
  children,
  isSecondary,
  style,
}: {
  children: React.ReactNode;
  isSecondary?: boolean;
  style?: any;
}) {
  return (
    <Heading1Container
      style={{
        color: isSecondary ? palette.light.primary : palette.light.white,
        ...style,
      }}
    >
      {children}
    </Heading1Container>
  );
}
export function Heading2({
  children,
  isSecondary,
  style,
}: {
  children: React.ReactNode;
  isSecondary?: boolean;
  style?: any;
}) {
  return (
    <Heading2Container
      style={{
        color: isSecondary ? palette.light.primary : palette.light.white,
        ...style,
      }}
    >
      {children}
    </Heading2Container>
  );
}
export function Heading3({
  children,
  isSecondary,
  style,
}: {
  children: React.ReactNode;
  isSecondary?: boolean;
  style?: any;
}) {
  return (
    <Heading3Container
      style={{
        color: isSecondary ? palette.light.primary : palette.light.white,
        ...style,
      }}
    >
      {children}
    </Heading3Container>
  );
}
export function Heading3Jobs({
  children,
  isSecondary,
  style,
}: {
  children: React.ReactNode;
  isSecondary?: boolean;
  style?: any;
}) {
  return (
    <Heading3ContainerJobs
      style={{
        color: isSecondary ? palette.light.primary : palette.light.white,
        ...style,
      }}
    >
      {children}
    </Heading3ContainerJobs>
  );
}
export function Heading4({
  children,
  isSecondary,
  style,
}: {
  children: React.ReactNode;
  isSecondary?: boolean;
  style?: any;
}) {
  return (
    <Heading4Container
      style={{
        color: isSecondary ? palette.light.primary : palette.light.white,
        ...style,
      }}
    >
      {children}
    </Heading4Container>
  );
}

const TextMDMediumContainer = styled.p`
  font-weight: 600;
  font-size: 40px;
  line-height: 56px;
  color: #f4f4f4;
  @media (min-width: 1500px) {
    font-size: 52px;
    font-style: normal;
    font-weight: 600;
    line-height: 66px;
  }
  @media (min-width: 1900px) {
    font-size: 64px;
    font-style: normal;
    font-weight: 600;
    line-height: 80px;
  }
`;
const TextMDContainer = styled.p`
  font-weight: 400;
  font-size: 40px;
  color: #f4f4f4;
  line-height: 48px;
  @media (min-width: 1500px) {
    font-size: 44px;
    font-style: normal;
    font-weight: 400;
    line-height: 62px;
  }
  @media (min-width: 1900px) {
    font-size: 64px;
    font-style: normal;
    font-weight: 600;
    line-height: 80px;
  }
`;
const TitleLargeContainer = styled.h5`
  color: #f4f4f4;
  font-weight: 600;
  font-size: 32px;
  line-height: 40px;
  span {
    font-weight: 600;
  }
  @media (max-width: 768px) {
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    span {
      font-weight: 600;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 24px;
    }
  }
  @media (min-width: 1500px) {
    font-size: 36px;
    font-style: normal;
    font-weight: 600;
    line-height: 44px;
    span {
      font-weight: 600;
    }
  }
  @media (min-width: 1900px) {
    font-size: 48px;
    font-style: normal;
    font-weight: 600;
    line-height: 56px;
    span {
      font-weight: 600;
    }
  }
`;
const TitleMediumContainer = styled.h5`
  font-weight: 700;
  color: #f4f4f4;
  font-size: 24px;
  line-height: 40px;
  @media (max-width: 768px) {
    font-size: 28px;
    font-style: normal;
    font-weight: 600;
    line-height: 40px; /* 142.857% */
  }
  @media (min-width: 1500px) {
    font-size: 28px;
    font-style: normal;
    font-weight: 600;
    line-height: 44px;
  }
  @media (min-width: 1900px) {
    font-size: 32px;
    font-style: normal;
    font-weight: 600;
    line-height: 48px;
  }
`;
const TitleSmallContainer = styled.h5`
  font-weight: 700;
  font-size: 16px;
  color: #f4f4f4;
  line-height: 40px;
  @media (max-width: 768px) {
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 32px;
  }
  @media (min-width: 1500px) {
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 44px;
  }
  @media (min-width: 1900px) {
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 48px;
  }
`;
const LabelContainer = styled.label`
  color: #f4f4f4;
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  @media (max-width: 768px) {
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
  }
  @media (min-width: 1500px) {
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 18px;
  }
  @media (min-width: 1900px) {
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
  }
`;
const BodyLargeContainer = styled.p`
  font-weight: 600;
  color: #f4f4f4;
  font-size: 24px;
  line-height: 40px;
  span {
    font-weight: 600;
  }
  @media (min-width: 1500px) {
    font-size: 28px;
    font-style: normal;
    font-weight: 600;
    line-height: 44px;
  }
  @media (min-width: 1900px) {
    font-size: 32px;
    font-style: normal;
    font-weight: 600;
    line-height: 48px;
  }
`;
const BodyMediumContainer = styled.p`
  font-weight: 500;
  color: #f4f4f4;
  font-size: 16px;
  line-height: 24px;
  @media (max-width: 768px) {
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    text-align: start;
    width: calc(100% - 32px);
  }
  @media (min-width: 1500px) {
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px;
  }
  @media (min-width: 1900px) {
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 32px;
  }
`;
const BodySmallRegularContainer = styled.p`
  font-size: 24px;
  color: #f4f4f4;
  font-style: normal;
  font-weight: 400;
  line-height: 40px;
  @media (min-width: 1500px) {
    font-size: 28px;
    font-style: normal;
    font-weight: 400;
    line-height: 44px;
  }
  @media (min-width: 1900px) {
    font-size: 32px;
    font-style: normal;
    font-weight: 400;
    line-height: 48px;
  }
`;
const Heading1Container = styled.h1`
  font-weight: 600;
  color: #f4f4f4;
  font-size: 64px;
  line-height: 80px;
  @media (min-width: 1500px) {
    font-size: 72px;
    font-style: normal;
    font-weight: 600;
    line-height: 88px;
  }
  @media (min-width: 1900px) {
    font-size: 88px;
    font-style: normal;
    font-weight: 600;
    line-height: 104px;
  }
`;
const Heading2Container = styled.h2`
  font-weight: 600;
  color: #f4f4f4;
  font-size: 48px;
  line-height: 64px;
  @media (min-width: 1500px) {
    font-size: 56px;
    font-style: normal;
    font-weight: 600;
    line-height: 72px;
  }
  @media (min-width: 1900px) {
    font-size: 64px;
    font-style: normal;
    font-weight: 600;
    line-height: 80px;
  }
`;
const Heading3Container = styled.h3`
  color: #f4f4f4;
  font-weight: 500;
  font-size: 32px;
  line-height: 47px;
  @media (max-width: 768px) {
    font-family: Montserrat;
    font-size: 32px;
    font-style: normal;
    font-weight: 500;
    line-height: 47px;
  }
  @media (min-width: 1500px) {
    font-size: 36px;
    font-style: normal;
    font-weight: 500;
    line-height: 52px;
  }
  @media (min-width: 1900px) {
    font-size: 40px;
    font-style: normal;
    font-weight: 500;
    line-height: 56px;
  }
`;
const Heading3ContainerJobs = styled.h3`
  font-weight: 500;
  color: #f4f4f4;
  font-size: 32px;
  line-height: 47px;
  @media (max-width: 768px) {
    font-family: Montserrat;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 18px;
  }
  @media (min-width: 1500px) {
    font-size: 36px;
    font-style: normal;
    font-weight: 500;
    line-height: 52px;
  }
  @media (min-width: 1900px) {
    font-size: 40px;
    font-style: normal;
    font-weight: 500;
    line-height: 56px;
  }
`;
const Heading4Container = styled.h4`
  color: #f4f4f4;
  font-weight: 500;
  font-size: 32px;
  line-height: 47px;
  @media (min-width: 1500px) {
    font-size: 36px;
    font-style: normal;
    font-weight: 500;
    line-height: 52px;
  }
  @media (min-width: 1900px) {
    font-size: 40px;
    font-style: normal;
    font-weight: 500;
    line-height: 56px;
  }
`;
