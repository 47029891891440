import styled, { keyframes } from "styled-components";
import { Heading2, Heading3, TitleLarge } from "../../commonComponents/Text";
import Input from "../../components/Input";
import SuccessIcon from "../../assets/images/successIcon.svg";
import CloseModalIcon from "../../assets/images/closeModalIcon.svg";
import { useEffect, useState } from "react";
import MailchimpSubscribe from "react-mailchimp-subscribe";
const NewsletterModal = () => {
  const [emailAddress, setEmailAddress] = useState<string>("");
  const [emailValidate, setEmailValidate] = useState<boolean>(false);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [complete, setComplete] = useState<boolean>(false);
  const [suscribed, setSuscribed] = useState<boolean>(false);
  const postUrl = `https://cultzyme.us21.list-manage.com/subscribe/post?u=3c9a75d368a60cd350eab1916&id=4cd627d5d7`;

  useEffect(() => {
    function evaluateFormComplete() {
      const emailRegex =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      const validateEmail = emailRegex.test(emailAddress);
      setEmailValidate(validateEmail);
      if (validateEmail) {
        setComplete(true);
      } else {
        setComplete(false);
      }
    }

    evaluateFormComplete();
  }, [emailAddress]);

  useEffect(() => {
    if (!window.localStorage.getItem("suscribed")) {
      setTimeout(() => {
        setShowModal(true);
      }, 3000);
    }
  }, []);

  return (
    <>
      {showModal ? (
        <ModalContainer>
          <BackgroundModal
            onClick={() => setShowModal(false)}
            className={showModal ? "show" : ""}
          ></BackgroundModal>
          <CardModal>
            {suscribed ? (
              <CardBottomContainer>
                <img
                  style={{
                    width: "80px",
                    height: "80px",
                  }}
                  src={SuccessIcon}
                  alt="Success Icon"
                />
                <Heading2
                  isSecondary
                  style={{
                    textAlign: "center",
                    width: "100%",
                    fontSize: "32px",
                  }}
                >
                  Thank you for subscribing!
                </Heading2>
              </CardBottomContainer>
            ) : (
              <>
                <TopContainer>
                  <TitleLarge
                    isSecondary
                    style={{
                      textTransform: "uppercase",
                      textShadow:
                        "0px 0px 10px rgba(56, 118, 255, 0.60), 0px 0px 4px rgba(56, 118, 255, 0.25)",
                      marginBottom: "32px",
                    }}
                  >
                    Newsletter
                  </TitleLarge>
                  <Heading3>
                    Subscribe to Cultzyme's newsletter and gain exclusive access
                    to industry trends, thought leadership insights, success
                    stories, and much more.
                  </Heading3>
                </TopContainer>
                <MailchimpSubscribe
                  url={postUrl}
                  render={({ subscribe, status, message }) => (
                    <FormContainer>
                      <RowInputs>
                        <InputContainer>
                          <Input
                            placeholder="Example@domain.com"
                            value={emailAddress}
                            onChange={(e) => {
                              setEmailAddress(e.target.value);
                            }}
                            error={!emailValidate}
                          />
                        </InputContainer>

                        <Button
                          onClick={() => {
                            subscribe({ EMAIL: emailAddress });
                            setEmailAddress("");
                            setTimeout(() => {
                              setSuscribed(true);
                              window.localStorage.setItem(
                                "suscribed",
                                JSON.stringify(true)
                              );
                              setTimeout(() => {
                                setShowModal(false);
                              }, 3000);
                            }, 1000);
                          }}
                          disabled={!complete}
                        >
                          Suscribe
                        </Button>
                      </RowInputs>
                    </FormContainer>
                  )}
                />
              </>
            )}
            <SuscriberText>
              I accept to subscribe to the newsletter to receive news and
              updates.
            </SuscriberText>
            <CloseModal
              src={CloseModalIcon}
              onClick={() => setShowModal(false)}
            />
          </CardModal>
        </ModalContainer>
      ) : (
        <></>
      )}
    </>
  );
};

const TopContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  padding: 0 10%;
  width: 100%;
  max-width: 1600px;
  @media (max-width: 540px) {
    padding: 0 5%;
  }
  h3 {
    font-size: 30px;
    @media (min-width: 1500px) {
      font-size: 34px;
    }
  }
`;
const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 10%;
  width: 100%;
  max-width: 1600px;
  @media (max-width: 540px) {
    padding: 0 5%;
  }
`;
const RowInputs = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  margin-bottom: 32px;
  gap: 32px;
  @media (max-width: 540px) {
    flex-direction: column;
  }
`;
const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 40%;
  gap: 8px;
  position: relative;
  @media (max-width: 540px) {
    width: 100%;
  }
`;
const Button = styled.button<{ disabled: boolean }>`
  background: ${(props) => (props.disabled ? "#9D9D9D" : "#2e6fff")};
  border: none;
  border-radius: 4px;
  padding: 8px 32px;
  color: var(--gray-white, #fff);
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  outline: none;
  cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
  transition: all 0.3s ease-in-out;
  &:hover {
    background: ${(props) => (props.disabled ? "#9D9D9D" : "#1d4ed8")};
  }
`;
const FadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;
const ModalContainer = styled.div`
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 101;
  display: flex;
  justify-content: center;
  align-items: center;
  animation: ${FadeIn} 0.5s ease-in-out;
  top: 0;
  left: 0;
  z-index: 301;
`;
const CloseModal = styled.img`
  width: 24px;
  height: 24px;
  position: absolute;
  top: 30px;
  right: 30px;
  cursor: pointer;
`;
const BackgroundModal = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  background: black;
  opacity: 0;
  z-index: -1;
  transition: all 0.3s ease-in-out;
  &.show {
    opacity: 0.5;
    z-index: 101;
  }
`;

const CardModal = styled.div`
  width: 900px;
  height: 90vh;
  border-radius: 10px;
  border: 2px solid var(--primario, #3876ff);
  display: flex;
  flex-direction: column;
  z-index: 102;
  background: #000407;
  position: relative;
  align-items: center;
  justify-content: center;
  gap: 30px;
  @media (max-width: 768px) {
    width: 95%;
  }
`;
const CardBottomContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 40px 60px;
  border-radius: 10px;
  gap: 32px;
  background: linear-gradient(135deg, rgba(15, 22, 33, 0.3) 0%, #0f1621 100%);
  backdrop-filter: blur(5px);
  @media (max-width: 768px) {
    padding: 40px 8px;
  }
`;
const SuscriberText = styled.p`
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  text-align: center;
  color: var(--gray-white, #fff);
  @media (max-width: 768px) {
    font-size: 14px;
  }
`;
export default NewsletterModal;
