import { useDocumentDataOnce } from "react-firebase-hooks/firestore";
import { firestore, auth } from "../../utils/firebase";
import firebase from "firebase/compat/app";
import Toast from "../../utils/toast";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { useNavigate, useParams } from "react-router-dom";

interface User {
  name: string;
  email: string;
  age: number;
  sex: string;
  active: boolean;
  updated_at: any;
  created_at?: any;
  uid?: string;
}

export default function useUsersForm() {
  const navigate = useNavigate();
  let { uid } = useParams();
  const add = uid === "add";
  const userRef = firestore.doc("users/" + uid);
  // const clientRef = firestore.doc("clients/" + uid);
  const [loadedUser] = useDocumentDataOnce(userRef);

  const MySwal = withReactContent(Swal);


  const submit = async (event: any) => {
    event.preventDefault();
    MySwal.fire({
      title: "Guardando",
      showCancelButton: false,
      allowOutsideClick: false,
    });
    MySwal.showLoading();
    // destructuring form fields into variables. Order matters
    let [
      { value: name },
      { value: email },
      { value: sex },
      { value: age },
      { checked: active },
    ] = event.target;

    if (!name || !email || !sex || !age) {
      Toast.fire({
        icon: "error",
        text: "Por favor complete todos los campos",
      });
      setTimeout(() => {
        MySwal.close();
      }, 2000);
      return;
    }

    if (add) {
      active = true;
    }

    const currentTime = firebase.firestore.FieldValue.serverTimestamp();

    const user: User = {
      name,
      email,
      age: +age, // age needs to be converted from string
      sex,
      active,
      updated_at: currentTime,
    };

    const collectionRef = firestore.collection("users");
    const promises = [];
    try {
      if (add) {
        const credentials = await auth.createUserWithEmailAndPassword(
          email,
          "123456"
        );
        uid = credentials.user?.uid;
        user.created_at = currentTime;
        user.uid = uid;

        promises.push(
          auth.sendPasswordResetEmail(email),
          collectionRef.doc(uid).set(user),
        );
      } else {
        promises.push(userRef.update(user));
      }
      await Promise.all(promises);
      MySwal.close();
      Toast.fire({ icon: "success", title: "Usuario guardado correctametne" });
      navigate("/admin/users");
    } catch (error) {
      MySwal.close();
      Toast.fire({
        icon: "error",
        title: "Ocurrió un error al guardar la noticia",
      });
      console.log("Error", error);
    }
  };

  return {
    add,
    submit,
    loadedUser,
  }
}
