import styled from "styled-components";

export default function Input({
  value,
  onChange,
  placeholder,
  error
}: {
  value: string;
  onChange: (e: any) => void;
  placeholder: string;
  error?: boolean;
}) {
  return (
    <InputComponent
      value={value}
      onChange={onChange}
      placeholder={placeholder}
      error={error || false}
    />
  );
}

const InputComponent = styled.input<{error: boolean}>`
  outline: none;
  border: 1px solid var(--gray-3, #333);
  border-radius: 8px;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  padding: 16px 12px;
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  color: var(--gray-2, #9d9d9d);
  transition: all 0.3s ease-in-out;
  background: transparent;
  &::placeholder {
    color: var(--gray-2, #9d9d9d);
  }
  &:focus {
    border: ${({error}) => error ? '1px solid var(--red, #ff0000)' : '1px solid var(--primario, #3876ff)'};
  }
  &:hover:not(:focus){
    border: 1px solid var(--white, #f4f4f4);
  }
`;
