import { Link } from "react-router-dom";
import styled from "styled-components";
import Page from "../../commonComponents/Page";
import Button from "../../commonComponents/Button";
import useJobsForm from "../hooks/useJobsForm";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
export default function JobsForm() {
  const { add, submit, loadedPress, content, setContent } = useJobsForm();

  return (
    <Page
      title={(add ? "Create" : "Edit") + " position"}
      icon="jobs"
      isHalf={false}
    >
      <ColumnsForm onSubmit={submit}>
        <Row>
          <FieldsContainer
            style={{
              width: "300px",
            }}
          >
            <Label>Position</Label>
            <Input
              type="text"
              name="position"
              placeholder="Position"
              defaultValue={loadedPress?.position}
            />
          </FieldsContainer>
          <FieldsContainer
            style={{
              width: "300px",
            }}
          >
            <Label>Location</Label>
            <Input
              type="text"
              name="location"
              placeholder="Location"
              defaultValue={loadedPress?.location}
            />
          </FieldsContainer>
        </Row>
        <FieldsContainer>
          <Label>Description</Label>
          <ReactQuill
            theme="snow"
            value={content}
            onChange={setContent}
            scrollingContainer={".ql-editor"}
          />
        </FieldsContainer>
        <ButtonsContainer style={{ gap: "1rem" }}>
          <Button type={"primary"}>Save</Button>
          <Link to="/admin/jobs">
            <Button type={"secondary"}>Back</Button>
          </Link>
        </ButtonsContainer>
      </ColumnsForm>
    </Page>
  );
}
const Row = styled.div`
  display: flex;
  gap: 1rem;
  width: 100%;
  justify-content: space-between;
`;
const ColumnsForm = styled.form`
  gap: 1rem;
`;
const FieldsContainer = styled.div`
  margin: 8px 0;
  .ql-editor{
    min-height: 400px;
  }
  .title{
    width: 100%;
  }
`;
const Label = styled.label`
  display: block;
  margin-bottom: 0.25rem;
  font-size: 0.75rem;
  color: #354b79;
`;
const Input = styled.input`
  &:not([type="checkbox"]) {
    padding: 0.5rem;
    width: 100%;
  }
  color: #354b79;
`;
const ButtonsContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  grid-column: 1/-1;
  margin-top: 16px;
`;
