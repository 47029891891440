import { Element } from "react-scroll";
import styled, { css, keyframes } from "styled-components";
import { useRef, useLayoutEffect, useContext, useState } from "react";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { sectionContext } from "../../context/sectionSelected";
import {
  BodyLarge,
  Heading1,
  Heading3,
  TitleLarge,
} from "../../commonComponents/Text";
const About = () => {
  const mainContainer: any = useRef();
  const firstText: any = useRef();
  const secondText: any = useRef();
  const thirdText: any = useRef();
  const fourthText: any = useRef();
  const { section } = useContext(sectionContext);
  const video2Ref: any = useRef();
  const [loadMetadata, setLoadMetadata] = useState<boolean>(false);
  const ellipseRef: any = useRef();
  useLayoutEffect(() => {
    if (loadMetadata) {
      gsap.registerPlugin(ScrollTrigger);
      ScrollTrigger.create({
        trigger: mainContainer.current,
        scrub: true,
        start: "top top",
        end: "+=250%",
        id: "pinhome",
        pinnedContainer: "#home",
        onLeave: function (self) {
          const element = document.getElementById("product");
          if (element) {
            element.scrollIntoView();
            setTimeout(() => {
              element.scrollIntoView();
            }, 25);
          }
        },
        onUpdate: function (self) {
          if (video2Ref.current && video2Ref.current.duration) {
            let scrollPos = self.progress;
            let videoDuration = video2Ref.current.duration;
            let videoCurrentTime = videoDuration * scrollPos;
            if (scrollPos === 0) {
              video2Ref.current.currentTime = 0;
            } else if (scrollPos > 0 && scrollPos < 1) {
              video2Ref.current.currentTime = videoCurrentTime;
            } else {
              video2Ref.current.currentTime = video2Ref.current.duration;
            }
          }
          if (firstText.current) {
            let scrollPos = self.progress;
            if (scrollPos >= 0 && scrollPos < 0.25) {
              slideFirstText(firstText, scrollPos * 28);
              fadeOutEllipse(ellipseRef, scrollPos);
              secondText.current.style.opacity = 0;
              thirdText.current.style.opacity = 0;
              fourthText.current.style.opacity = 0;
            } else if (scrollPos >= 0.25 && scrollPos < 0.3) {
              slideFirstText(firstText, scrollPos * 28);
            } else if (scrollPos >= 0.3 && scrollPos < 0.45) {
              slideFirstText(firstText, scrollPos * 28);
              slideSecondText(secondText, scrollPos - 0.3);
            } else if (scrollPos >= 0.45 && scrollPos < 0.6) {
              fadeOutSexondText(secondText, scrollPos - 0.45);
              slideSecondText(thirdText, scrollPos - 0.45);
            } else if (scrollPos >= 0.6 && scrollPos < 0.75) {
              fadeOutSexondText(thirdText, scrollPos - 0.6);
              slideSecondText(fourthText, scrollPos - 0.6);
            } else if (scrollPos >= 0.75 && scrollPos < 0.9) {
              fadeOutSexondText(fourthText, scrollPos - 0.75);
            } else if (scrollPos >= 0.9 && scrollPos < 1) {
            } else {
              firstText.current.style.opacity = 0;
              secondText.current.style.opacity = 0;
              fourthText.current.style.top = `38%`;
              secondText.current.style.top = `35%`;
              thirdText.current.style.top = `35%`;
              fourthText.current.style.opacity = 1;
            }
            if (scrollPos >= 0.35) {
              ellipseRef.current.style.opacity = 0;
            }
          }
        },
      });
    }
  }, [loadMetadata]); // eslint-disable-line react-hooks/exhaustive-deps

  function slideFirstText(firstText: any, progress: number) {
    firstText.current.style.opacity = 1 - 0.1 * progress;
  }
  function slideSecondText(secondText: any, progress: number) {
    secondText.current.style.top = `${100 - progress * 433}%`;
    secondText.current.style.opacity = progress * 7 <= 0.25 ? 0 : progress * 7;
  }
  function fadeOutSexondText(secondText: any, progress: number) {
    secondText.current.style.opacity = 1 - progress * 10;
  }

  function fadeOutEllipse(ellipse: any, progress: number) {
    ellipse.current.style.opacity = 1 - progress * 4;
  }

  return (
    <Element name="#home">
      <Container ref={mainContainer} id="home">
        <ScrollContainer animated={section === "home"}>
          <Video
            ref={video2Ref}
            preload="auto"
            webkit-playsinline="true"
            muted
            src="/background-liquido.mp4"
            onLoadedMetadata={() => setLoadMetadata(true)}
          />
          <EllipseContainer ref={ellipseRef}>
            <Ellipse />
          </EllipseContainer>
          <FirstText ref={firstText}>
            <Heading1
              style={{
                letterSpacing: "3.2px",
              }}
            >
              Powering innovation with intelligent bioprocessing.
            </Heading1>
            <Heading3 isSecondary>
              We are not just bringing biofabrication into the future. We are
              reinventing it.
            </Heading3>
          </FirstText>
          <SecondText ref={secondText}>
            <TitleLarge
              style={{
                textTransform: "uppercase",
                textShadow:
                  "0px 0px 10px rgba(56, 118, 255, 0.60), 0px 0px 4px rgba(56, 118, 255, 0.25)",
                marginBottom: "16px",
              }}
              isSecondary
            >
              Accelerate your bioprocesses
            </TitleLarge>
            <BodyLarge>
              Integrate the latest hardware and software developments, combining
              novel sensor technology
              <br />
              with AI-powered bioprocess identification for
              <br />
              precise control, enhanced optimisation and improved usability.
            </BodyLarge>
          </SecondText>
          <SecondText ref={thirdText}>
            <TitleLarge
              style={{
                textTransform: "uppercase",
                textShadow:
                  "0px 0px 10px rgba(56, 118, 255, 0.60), 0px 0px 4px rgba(56, 118, 255, 0.25)",
                marginBottom: "16px",
              }}
              isSecondary
            >
              Defining the future
            </TitleLarge>
            <BodyLarge>
              We give you the tools, data, and insights to
              <br />
              create something exceptional and truly different
              <br />
              with a simple click.
            </BodyLarge>
          </SecondText>
          <SecondText ref={fourthText}>
            <TitleLarge
              style={{
                textTransform: "uppercase",
                textShadow:
                  "0px 0px 10px rgba(56, 118, 255, 0.60), 0px 0px 4px rgba(56, 118, 255, 0.25)",
                marginBottom: "16px",
              }}
              isSecondary
            >
              Reduce costs and result times
            </TitleLarge>
            <BodyLarge>
              Efficient experiment design, improved control and optimisation
              reduces repetition to
              <br />
              minimize time and costs, and maximize results.
            </BodyLarge>
          </SecondText>
        </ScrollContainer>
      </Container>
    </Element>
  );
};
const Video = styled.video`
  position: absolute;
  max-width: 100%;
  max-height: 100%;
  object-fit: cover;
  z-index: -1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  opacity: 0.6;
`;
const BounceIn = keyframes`
0% {
    -webkit-transform: translateY(500px);
            transform: translateY(500px);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
    opacity: 0;
  }
  50% {
    -webkit-transform: translateY(-40px);
            transform: translateY(-40px);
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
    opacity: 1;
  }
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
  }
  `;

const Container = styled.div`
  display: flex;
  flex: 1;
  justify-content: start;
  flex-direction: column;
  position: relative;
  width: 100%;
  min-height: 350vh;
  background: #000407;
`;
const ScrollContainer = styled.div<{ animated: boolean }>`
  position: sticky;
  top: 0;
  left: 0;
  width: 100%;
  height: 100px;
  min-height: 100vh;
  overflow: hidden;
  animation: ${({ animated }) =>
    animated
      ? css`
          ${BounceIn} 1.5s ease-in-out
        `
      : "none"};
`;

const SecondText = styled.div`
  position: absolute;
  top: 100%;
  display: block;
  opacity: 0;
  transition: 0.1s ease;
  right: 0;
  left: 0;
  margin: auto;
  width: 594px;
  @media (min-width: 1500px) {
    width: 780px;
    h5 {
      font-size: 40px;
    }
    p {
      font-size: 32px;
    }
  }
  @media (min-width: 1900px) {
    width: 900px;
  }
`;

const FirstText = styled.div`
  position: absolute;
  left: 66px;
  width: 1030px;
  height: 120px;
  top: 35%;
  opacity: 1;
  transition: 0.1s ease;
  h3 {
    width: 632px;
  }
  @media (min-width: 1500px) {
    width: 1160px;
    h3 {
      width: 720px;
    }
  }
  @media (min-width: 1900px) {
    width: 1460px;
    h3 {
      width: 800px;
    }
  }
`;
const EllipseContainer = styled.div`
  position: absolute;
  width: 60vw;
  height: 60vw;
  left: -15%;
  top: -40%;
  transition: all 0.1s ease;
`;
const Ellipse = styled.div`
  background: radial-gradient(
    50% 50% at 50% 50%,
    rgba(73, 149, 182, 0.29) 0%,
    rgba(108, 201, 242, 0) 100%
  );
  filter: blur(24.733078002929688px);
  position: absolute;
  width: 60vw;
  height: 60vw;
`;
export default About;
