import { createGlobalStyle } from "styled-components";

const GlobalStyles = createGlobalStyle`

  *, html, body {
    margin: 0;
    padding: 0;
    font-family: 'Montserrat', sans-serif;
    /* font-style: normal; */
    /* font-weight: normal; */
    scroll-behavior: none;
    transition: color 0.3s ease-in-out; 
    box-sizing: border-box;
    /* color: #f4f4f4; */
    @media (max-width: 768px) {
      text-align: justify;
      -webkit-tap-highlight-color: transparent;

    }
  }
  html, body{
    background: #000407;
  }
  body{
    ::-webkit-scrollbar {
    width: 10px;
    }
    ::-webkit-scrollbar-track {
      background: rgba(150,150,150,0.1);
    }
    ::-webkit-scrollbar-thumb {
      background: #5b8bf4;
      height: 40px;
      border-radius: 10px;
    }
    ::-webkit-scrollbar-thumb:hover {
      background: #2e3fff;
    }
  }
  .pin-spacer > div {
    background: #000407;
  }

  .swiper {flex:1;}

  .swiper-slide {
    text-align: center;
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  
  .swiper-button-next,.swiper-button-prev{
    color: #D8670C;
  }

  .swiper-pagination-bullet {
    width: 6px;
    height: 6px;
    opacity: 1;
    background: #D8670C88;
  }

  .swiper-pagination-bullet-active {
    background: #D8670C;
  }

  @media (max-width: 480px) {
    .swiper-button-next, .swiper-button-prev{
      top: unset;
      bottom: -0;
    }
  }

  .swiper-button-next {
    display: none;
  }
  
  .swiper-button-next::after {
    display: none;
  }

  .swiper-button-prev {
    display: none;
  }
  
  .swiper-button-prev::after {
    display: none;
  }

  .banner-typing-effect-cursor {
    font-family: Neue Haas Grotesk Display Pro;
    font-size: 50px;
    color: #44c1a9;
    font-weight: bold;
  }
  
  .banner-typing-effect-title {
    z-index: 1;
  }
`;

export default GlobalStyles;
