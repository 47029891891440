import { Outlet, useNavigate } from "react-router-dom";
import styled, { keyframes } from "styled-components/macro";
import Logo from "../assets/images/logoHeader.svg";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import MenuIcon from "../assets/images/menuIcon.svg";
import CloseIcon from "../assets/images/closeIcon.svg";
import { useState } from "react";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import { getAuth, signOut } from "firebase/auth";
import ArticleIcon from "@mui/icons-material/Article";
import SupportIcon from "@mui/icons-material/Support";
import WorkIcon from "@mui/icons-material/Work";
export interface IItemMenu {
  name: string;
  path: string;
  icon: JSX.Element;
  show: boolean;
  menu?: boolean;
  items?: IItemMenu[];
  active?: boolean;
  onClick?: () => void;
}

export default function Admin() {
  const navigation = useNavigate();
  const path = window.location.pathname;
  const [showMenuResponsive, setShowMenuResponsive] = useState<boolean>(false);
  const auth = getAuth();
  async function handleSignOut() {
    await signOut(auth);
    navigation("/login");
  }
  const itemsMenu: IItemMenu[] = [
    {
      name: "Users",
      path: "/admin/users",
      icon: <PeopleAltIcon />,
      show: true,
      menu: false,
    },
    {
      name: "News",
      path: "/admin/press",
      icon: <ArticleIcon />,
      show: true,
      menu: false,
    },
    {
      name: "Supports & Partners",
      path: "/admin/supports-partners",
      icon: <SupportIcon />,
      show: true,
      menu: false,
    },
    {
      name: "Jobs",
      path: "/admin/jobs",
      icon: <WorkIcon />,
      show: true,
      menu: false,
    },
  ];

  return (
    <Container>
      <MenuMobileIconContainer
        onClick={() => setShowMenuResponsive(!showMenuResponsive)}
      >
        <img src={MenuIcon} alt="Menu Icon" />
      </MenuMobileIconContainer>
      <ResponsiveMenuContainer
        style={{ display: showMenuResponsive ? "flex" : "none" }}
      >
        <ResponsiveMenu>
          <CloseButtonMenu
            onClick={() => setShowMenuResponsive(!showMenuResponsive)}
          >
            <img src={CloseIcon} alt="Menu Icon" />
          </CloseButtonMenu>
          <BackgroundContainer></BackgroundContainer>
          <ContentContainer>
            <LogoContainer
              onClick={() => {
                navigation("/");
                setShowMenuResponsive(false);
              }}
            >
              <img src={Logo} alt="Logo YPF SOLAR" />
            </LogoContainer>
            <OptionsContainer>
              {itemsMenu.map((item, index) => {
                if (item.menu && item.show) {
                  return (
                    <ItemsContainer
                      style={{
                        maxHeight: item?.active
                          ? `${item?.items && item?.items.length * 46 + 46}px`
                          : "46px",
                      }}
                    >
                      <MainMenu
                        theme={
                          path.includes(item.path) ? "inactive" : "inactive"
                        }
                        onClick={item.onClick}
                      >
                        {item.icon}
                        <TextItem
                          color={
                            path.includes(item.path) ? "#FFFFFF" : "#FFFFFF"
                          }
                        >
                          {item.name}
                        </TextItem>
                      </MainMenu>
                      {item?.items?.map((subItem, index) => {
                        return subItem.show ? (
                          <SubMenu
                            theme={
                              path === subItem.path ? "active" : "inactive"
                            }
                            onClick={() => {
                              navigation(subItem.path);
                              setShowMenuResponsive(false);
                            }}
                          >
                            {subItem.icon}
                            <TextItem
                              color={
                                path === subItem.path ? "#128297" : "#FFFFFF"
                              }
                            >
                              {subItem.name}
                            </TextItem>
                          </SubMenu>
                        ) : null;
                      })}
                    </ItemsContainer>
                  );
                } else {
                  return item.show ? (
                    <Item
                      key={index}
                      theme={path === item.path ? "active" : "inactive"}
                      onClick={() => {
                        navigation(item.path);
                        setShowMenuResponsive(false);
                      }}
                    >
                      {item.icon}
                      <TextItem
                        color={path === item.path ? "#128297" : "#FFFFFF"}
                      >
                        {item.name}
                      </TextItem>
                    </Item>
                  ) : null;
                }
              })}
            </OptionsContainer>
          </ContentContainer>
        </ResponsiveMenu>
      </ResponsiveMenuContainer>
      <SideBar>
        <BackgroundContainer></BackgroundContainer>
        <ContentContainer>
          <LogoContainer onClick={() => navigation("/")}>
            <img src={Logo} alt="Logo YPF SOLAR" />
          </LogoContainer>
          <OptionsContainer>
            {itemsMenu.map((item, index) => {
              if (item.menu && item.show) {
                return (
                  <ItemsContainer
                    style={{
                      maxHeight: item.active
                        ? `${item?.items && item.items.length * 46 + 46}px`
                        : "46px",
                    }}
                  >
                    <MainMenu
                      theme={path.includes(item.path) ? "inactive" : "inactive"}
                      onClick={item.onClick}
                    >
                      {item.icon}
                      <TextItem
                        color={path.includes(item.path) ? "#FFFFFF" : "#FFFFFF"}
                      >
                        {item.name}
                      </TextItem>
                    </MainMenu>
                    {item?.items?.map((subItem, index) => {
                      return subItem.show ? (
                        <SubMenu
                          theme={path === subItem.path ? "active" : "inactive"}
                          onClick={() => {
                            navigation(subItem.path);
                          }}
                        >
                          {subItem.icon}
                          <TextItem
                            color={
                              path === subItem.path ? "#FFFFFF" : "#FFFFFF"
                            }
                          >
                            {subItem.name}
                          </TextItem>
                        </SubMenu>
                      ) : null;
                    })}
                  </ItemsContainer>
                );
              } else {
                return item.show ? (
                  <Item
                    key={index}
                    theme={path === item.path ? "active" : "inactive"}
                    onClick={() => {
                      navigation(item.path);
                    }}
                  >
                    {item.icon}
                    <TextItem
                      color={path === item.path ? "#FFFFFF" : "#FFFFFF"}
                    >
                      {item.name}
                    </TextItem>
                  </Item>
                ) : null;
              }
            })}
            <Item onClick={handleSignOut}>
              <ExitToAppIcon />
              <TextItem>Logout</TextItem>
            </Item>
          </OptionsContainer>
        </ContentContainer>
      </SideBar>
      <Main>
        <Outlet />
      </Main>
    </Container>
  );
}

const Entrance = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }`;
const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  position: relative;
  @media (max-width: 768px) {
    width: 100vw;
    max-width: 100vw;
    overflow-x: hidden;
  }
`;
const MenuMobileIconContainer = styled.div`
  display: none;
  @media (max-width: 768px) {
    display: flex;
    position: fixed;
    top: 25px;
    right: 13px;
    z-index: 30;
  }
`;
const CloseButtonMenu = styled.div`
  width: 30px;
  height: 30px;
  border-radius: 3px;
  background: #e7e7e7;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 10px;
  left: 20px;
  cursor: pointer;
`;
const ResponsiveMenuContainer = styled.div`
  display: none;
  @media (max-width: 768px) {
    width: 100vw;
    min-width: 60px;
    max-height: 100vh;
    overflow-x: hidden;
    position: fixed;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;
    z-index: 30;
    background-color: #515151;
    -webkit-animation: ${Entrance} 0.2s cubic-bezier(0.39, 0.575, 0.565, 1) both;
    animation: ${Entrance} 0.2s cubic-bezier(0.39, 0.575, 0.565, 1) both;
  }
`;
const ResponsiveMenu = styled.div`
  max-width: 230px;
  min-width: 60px;
  max-height: 100vh;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
`;

const SideBar = styled.div`
  width: 261px;
  min-width: 60px;
  height: 100%;
  position: fixed;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  z-index: 6;
  @media (max-width: 768px) {
    display: none;
  }
`;
const BackgroundContainer = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: -1;
  background-color: #587dca;
  filter: brightness(0.6);
  @media (max-width: 768px) {
    width: 230px;
  }
`;
const ContentContainer = styled.div`
  width: 261px;
  position: absolute;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  @media (max-width: 768px) {
    padding: 0px 10px;
    width: 230px;
    position: relative;
    overflow-y: auto;
  }
`;
const LogoContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 29px 15px 20px;
  border-bottom: 1px solid rgba(181, 181, 181, 0.3);
  cursor: pointer;
  @media (max-width: 768px) {
    width: 230px;
    padding: 15px 15px 15px;
  }
`;
const Main = styled.div`
  width: calc(100% - 261px);
  display: flex;
  flex-direction: column;
  margin-left: 261px;
  position: relative;
  justify-content: space-between;
  min-height: 100vh;
  overflow: hidden;
  @media (max-width: 768px) {
    width: 100vw;
    margin-left: 0;
    padding: 0px;
  }
`;
const OptionsContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 9px;
  margin-top: 14px;
  padding: 0 16px;
`;
const Item = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: 46px;
  color: ${(props) => (props.theme === "active" ? "#FFFFFF" : "#FFFFFF")};
  padding: 16px 10px;
  background-color: ${(props) =>
    props.theme === "active" ? "#ffffff2e" : "rgba(255, 255, 255, 0)"};
  gap: 22px;
  border-radius: 3px;
  cursor: pointer;
  transition: 0.4s;
  &:hover {
    background: ${(props) =>
      props.theme === "active" ? "#ffffffb8" : "rgba(255, 255, 255, 0.2)"};
  }
`;
const TextItem = styled.p`
  font-size: 14px;
  line-height: 14px;
  color: ${(props) => props.color};
  user-select: none;
`;
const ItemsContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  max-height: 46px;
  overflow: hidden;
  transition: all 0.4s ease-in-out;
`;
const MainMenu = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: 46px;
  color: ${(props) => (props.theme === "active" ? "#FFFFFF" : "#FFFFFF")};
  padding: 16px 10px;
  background-color: ${(props) =>
    props.theme === "active" ? "#ffffff2e" : "rgba(255, 255, 255, 0)"};
  gap: 22px;
  border-radius: 3px 3px 0 0;
  cursor: pointer;
  transition: 0.4s;
  &:hover {
    background: ${(props) =>
      props.theme === "active" ? "#ffffffb8" : "rgba(255, 255, 255, 0.2)"};
  }
`;
const SubMenu = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: 46px;
  color: ${(props) => (props.theme === "active" ? "#FFFFFF" : "#FFFFFF")};
  padding: 16px 10px 16px 40px;
  background-color: ${(props) =>
    props.theme === "active" ? "#ffffff2e" : "rgba(255, 255, 255, 0)"};
  gap: 22px;
  border-radius: 0 0 3px 3px;
  cursor: pointer;
  transition: 0.4s;
  &:hover {
    background: ${(props) =>
      props.theme === "active" ? "#ffffffb8" : "rgba(255, 255, 255, 0.2)"};
  }
`;
