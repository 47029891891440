import { ThemeProvider } from "./theme";
import { ScrollProvider } from "./scrollContext";
import { SectionProvider } from "./sectionSelected";

const ContextsProviders: React.FC = ({ children }): JSX.Element => (
  <SectionProvider>
    <ScrollProvider>
      <ThemeProvider>{children}</ThemeProvider>
    </ScrollProvider>
  </SectionProvider>
);

export default ContextsProviders;
