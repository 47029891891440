import { useState } from "react";
import styled from "styled-components";
import ArrowDropDownIcon from "../assets/images/arrowDropDownIcon.svg";
export default function ComboboxWithFilter({
  label,
  value,
  setValue,
  disabled,
  options,
  height = 58,
  filterInput,
  setFilterInput,
  style,
}: {
  label: string;
  value: string;
  setValue: (value: string) => void;
  disabled?: boolean;
  options: string[];
  height?: number;
  filterInput: string;
  setFilterInput: (value: string) => void;
  style?: any;
}) {
  const [showDropdown, setShowDropdown] = useState<boolean>(false);
  function selectOption(option: string) {
    setValue(option);
    setShowDropdown(false);
  }
  return (
    <>
      <DropDownField
        onClick={() => setShowDropdown(disabled ? false : !showDropdown)}
        style={{
          height: `${height}px`,
          zIndex: showDropdown ? 100 : 1,
          ...style,
        }}
      >
        <CurrentValueDropDown
          style={{
            paddingTop: "0px",
            fontSize: "16px",
          }}
        >
          {value || label}
        </CurrentValueDropDown>
        <IconConatiner
          style={{
            transform: showDropdown ? "rotateZ(-180deg)" : "rotateZ(0deg)",
          }}
        >
          <img src={ArrowDropDownIcon} alt="arrow drop down icon" />
        </IconConatiner>
      </DropDownField>
      {showDropdown && (
        <>
          <DropDownItems
            style={{
              zIndex: 10,
              minHeight: "43px",
              borderRadius: "8px 8px 0 0",
            }}
          >
            <InputCombo
              placeholder="Filter"
              value={filterInput}
              onChange={(e) => {
                setFilterInput(e.target.value);
              }}
              height={58}
              style={{
                width: "100%",
                border: "none",
              }}
            />
          </DropDownItems>
          <DropDownItems>
            {options.map((d: any, index: number) => (
              <Option
                key={index}
                onClick={() => {
                  selectOption(d);
                }}
                className={d === value ? "selected" : ""}
                style={{
                  paddingTop: index === 0 ? "51px" : "10px",
                }}
              >
                {d}
              </Option>
            ))}
          </DropDownItems>
        </>
      )}
    </>
  );
}

const DropDownField = styled.div`
  border: 1px solid var(--gray-3, #333);
  border-radius: 8px;
  padding: 16px 0;
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  cursor: pointer;
  position: absolute;
  color: var(--gray-2, #9d9d9d);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  overflow-y: hidden;
  background-color: #000407;
  z-index: 1;
  transition: all 0.3s ease-in-out;
  width: 100%;
  top: 32px;
  &:hover:not(:focus) {
    border: 1px solid var(--white, #f4f4f4);
  }
  @media (min-width: 1500px) {
    top: 36px;
  }
  @media (min-width: 1900px) {
    top: 40px;
  }
`;
const DropDownItems = styled.div`
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  z-index: 6;
  transition: all 0.4s;
  max-height: 220px;
  width: 100%;
  overflow-y: scroll;
  top: 100px;
  background-color: #000407;
  position: absolute;
  border: 1px solid var(--gray-3, #333);
  border-radius: 8px;
  &::-webkit-scrollbar {
    width: 0px;
    background: #000407;
  }
  &:first-child {
    padding-top: 54px;
  }
`;
const IconConatiner = styled.div`
  position: absolute;
  right: 16px;
  top: 19px;
  transform: rotateZ("0deg");
  transition: all 0.3s;
  width: 20px;
  height: 20px;
`;

const CurrentValueDropDown = styled.div`
  color: var(--gray-2, #9d9d9d);
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  padding-top: 10px;
  text-transform: capitalize;
  padding: 0 12px;
  @media (max-width: 768px) {
    font-family: Montserrat;
    min-height: 34px;
    font-size: 13px;
    line-height: 13px;
    padding-top: 10px;
  }
`;
const Option = styled.div`
  color: var(--white, #f4f4f4);
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  padding: 10px 14px;
  cursor: pointer;
  :hover {
    background: var(--gray-3, #333);
  }
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  :nth-last-child() {
    margin-bottom: 18px;
  }
  text-transform: capitalize;
  &.selected {
    background: var(--gray-3, #333);
  }
`;
const InputCombo = styled.input`
  border-radius: 8px 8px 0 0;
  padding: 8px 16px;
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  cursor: pointer;
  position: absolute;
  color: var(--gray-2, #9d9d9d);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  overflow-y: hidden;
  background-color: #000407;
  z-index: 1;
  transition: all 0.3s ease-in-out;
  width: 100%;
  top: 0;
`;
