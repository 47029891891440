import styled, { keyframes } from "styled-components";

import { useLayoutEffect, useContext, useCallback, useState } from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import JobForm from "./components/JobForm";
import { Heading2, Heading3 } from "../commonComponents/Text";
import useWindowSize from "../hooks/useWindowSize";
import HeaderResponsive from "../components/HeaderResponsive";
import FooterResponsive from "../components/FooterResponsive";
import { useCollectionData } from "react-firebase-hooks/firestore";
import { firestore } from "../utils/firebase";
import Loader from "../commonComponents/Loader";
import useWindowScroll from "../hooks/useWindowScroll";
import { scrollContext } from "../context/scrollContext";

const JoinUs = () => {
  const { width } = useWindowSize();
  const jobsFierstoreRef = firestore.collection("jobs");
  const [jobs, loading] = useCollectionData(jobsFierstoreRef);
  useWindowScroll();
  const {
    scroll: { scrollToTop },
  } = useContext(scrollContext);
  const [heightContainer, setheightContainer] = useState<number>(0);
  const preContainerRef = useCallback((node) => {
    if (node !== null) {
      setheightContainer(node.getBoundingClientRect().height);
    }
  }, []);
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      {width > 768 && (
        <>
          <Header background={true} />
          <Container>
            <TopContainer>
              <Title>Our Guiding Principles</Title>
              <Subtitle>
                Discover the values that fuel our innovative spirit and form the
                foundation of our culture.
              </Subtitle>
            </TopContainer>
            <PreContainer ref={preContainerRef}>
              <RightContainer>
                <Level>
                  <RadiusNummber style={{ opacity: 0 }}>1</RadiusNummber>
                  <TitleRight style={{ opacity: 0 }}>
                    Unique Thinking
                  </TitleRight>
                  <SubtitleRight style={{ opacity: 0 }}>
                    We celebrate the power of unique thinking. In our dynamic
                    team, diverse perspectives and creative problem-solving are
                    the keys to success. We believe that innovative solutions
                    stem from embracing individuality and fostering a culture of
                    exploration.
                  </SubtitleRight>
                </Level>
                <Level>
                  <RadiusNummber style={{ opacity: 0 }}>2</RadiusNummber>
                  <TitleRight
                    style={{
                      textAlign: "right",
                      paddingRight: "8px",
                    }}
                  >
                    Push the Frontier
                  </TitleRight>
                  <SubtitleRight
                    style={{
                      textAlign: "justify",
                      paddingRight: "8px",
                      textAlignLast: "right",
                    }}
                  >
                    Pushing with audacity the frontier of biotech innovation. We
                    thrive on the relentless pursuit of new horizons. Your ideas
                    and vision will be the driving force behind our journey to
                    break new ground and redefine what's possible in
                    biotechnology.
                  </SubtitleRight>
                </Level>
                <Level>
                  <RadiusNummber style={{ opacity: 0 }}>3</RadiusNummber>
                  <TitleRight style={{ opacity: 0 }}>
                    Make the Knowledge Take Ground
                  </TitleRight>
                  <SubtitleRight style={{ opacity: 0 }}>
                    Your career is about making knowledge accessible to all.
                    We're on a mission to transform industries by democratizing
                    biotech. Join our passionate team, where we work together to
                    take knowledge from the complexity and elevate it to a place
                    where everyone can obtain its potential.
                  </SubtitleRight>
                </Level>
              </RightContainer>
              <MiddleContainer>
                <VerticalScrollProgress
                  progress={
                    heightContainer ? (scrollToTop / heightContainer) * 100 : 0
                  }
                ></VerticalScrollProgress>
              </MiddleContainer>
              <RightContainer>
                <Level>
                  <RadiusNummber>1</RadiusNummber>
                  <TitleRight>Unique Thinking</TitleRight>
                  <SubtitleRight>
                    We celebrate the power of unique thinking. In our dynamic
                    team, diverse perspectives and creative problem-solving are
                    the keys to success. We believe that innovative solutions
                    stem from embracing individuality and fostering a culture of
                    exploration.
                  </SubtitleRight>
                </Level>
                <Level>
                  <RadiusNummber>2</RadiusNummber>
                  <TitleRight
                    style={{
                      opacity: 0,
                    }}
                  >
                    Push the Frontier
                  </TitleRight>
                  <SubtitleRight
                    style={{
                      opacity: 0,
                    }}
                  >
                    Pushing with audacity the frontier of biotech innovation. We
                    thrive on the relentless pursuit of new horizons. Your ideas
                    and vision will be the driving force behind our journey to
                    break new ground and redefine what's possible in
                    biotechnology.
                  </SubtitleRight>
                </Level>
                <Level>
                  <RadiusNummber>3</RadiusNummber>
                  <TitleRight>Make the Knowledge Take Ground</TitleRight>
                  <SubtitleRight>
                    Your career is about making knowledge accessible to all.
                    We're on a mission to transform industries by democratizing
                    biotech. Join our passionate team, where we work together to
                    take knowledge from the complexity and elevate it to a place
                    where everyone can obtain its potential.
                  </SubtitleRight>
                </Level>
              </RightContainer>
            </PreContainer>
            <JobsContainer>
              <TitleContainer>
                <Heading2>
                  Powering innovation with intelligent bioprocessing
                </Heading2>
              </TitleContainer>
              {jobs &&
                jobs.map((job) => (
                  <JobForm
                    position={job.position}
                    area={job.area}
                    description={job.description}
                    location={job.location}
                  />
                ))}
            </JobsContainer>
          </Container>

          <Footer />
        </>
      )}
      {width < 768 && (
        <>
          <HeaderResponsive />
          <Container>
            <TopContainer>
              <Title>Our Guiding Principles</Title>
              <Subtitle>
                Discover the values that fuel our innovative spirit and form the
                foundation of our culture.
              </Subtitle>
            </TopContainer>
            <PreContainer ref={preContainerRef}>
              <MiddleContainer>
                <VerticalScrollProgress
                  progress={
                    heightContainer ? (scrollToTop / heightContainer) * 100 : 0
                  }
                ></VerticalScrollProgress>
              </MiddleContainer>
              <RightContainer>
                <Level>
                  <RadiusNummber>1</RadiusNummber>
                  <TitleRight>Unique Thinking</TitleRight>
                  <SubtitleRight>
                    We celebrate the power of unique thinking. In our dynamic
                    team, diverse perspectives and creative problem-solving are
                    the keys to success. We believe that innovative solutions
                    stem from embracing individuality and fostering a culture of
                    exploration.
                  </SubtitleRight>
                </Level>
                <Level>
                  <RadiusNummber>2</RadiusNummber>
                  <TitleRight>Push the Frontier</TitleRight>
                  <SubtitleRight>
                    Pushing with audacity the frontier of biotech innovation. We
                    thrive on the relentless pursuit of new horizons. Your ideas
                    and vision will be the driving force behind our journey to
                    break new ground and redefine what's possible in
                    biotechnology.
                  </SubtitleRight>
                </Level>
                <Level>
                  <RadiusNummber>3</RadiusNummber>
                  <TitleRight>Make the Knowledge Take Ground</TitleRight>
                  <SubtitleRight>
                    Your career is about making knowledge accessible to all.
                    We're on a mission to transform industries by democratizing
                    biotech. Join our passionate team, where we work together to
                    take knowledge from the complexity and elevate it to a place
                    where everyone can obtain its potential.
                  </SubtitleRight>
                </Level>
              </RightContainer>
            </PreContainer>
            <JobsContainerResponsive>
              <TitleContainerResponsive>
                <Heading3>
                  Powering innovation with intelligent bioprocessing
                </Heading3>
              </TitleContainerResponsive>
              {jobs &&
                jobs.map((job) => (
                  <JobForm
                    position={job.position}
                    area={job.area}
                    location={job.location}
                    description={job.description}
                  />
                ))}
            </JobsContainerResponsive>
            {loading && <Loader />}
          </Container>
          <FooterResponsive />
        </>
      )}
    </>
  );
};

const FadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const JobsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  width: 100%;
  max-width: 90%;
  gap: 16px;
  padding-top: 10vh;
  overflow: hidden;
`;
const JobsContainerResponsive = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  width: 100vw;
  max-width: 100%;
  gap: 16px;
  overflow: hidden;
`;

const Container = styled.div`
  background-color: black;
  max-width: 100vw;
  min-height: 100vh;
  position: relative;
  padding: 100px 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  animation: ${FadeIn} 1s ease-in-out;
  overflow: hidden;
  @media (max-width: 768px) {
    padding: 80px 16px;
  }
`;
const TitleContainer = styled.div`
  display: flex;
  justify-content: center;
  text-align: center;
  width: 100%;
  padding-bottom: 16px;
  h2 {
    font-size: 40px;
    @media (min-width: 1500px) {
      font-size: 44px;
    }
    @media (min-width: 1900px) {
      font-size: 50px;
    }
  }
`;
const TitleContainerResponsive = styled.div`
  display: flex;
  width: 100%;
  h3 {
    text-align: start;
  }
`;
const PreContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  max-width: 100%;
  padding: 16px;
  justify-content: center;
  @media (max-width: 768px) {
    flex-direction: row;
    padding: 0;
    margin-bottom: 32px;
  }
`;
const RightContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
  padding: 8px;
  gap: 36px;
  padding: 16px 16px 16px 32px;
  @media (max-width: 768px) {
    width: 80%;
    gap: 30px;
    padding: 8px;
  }
`;
const Title = styled.h1`
  font-size: 50px;
  color: white;
  font-weight: 600;
  color: rgb(56, 118, 255);
  text-transform: uppercase;
  text-shadow: rgba(56, 118, 255, 0.6) 0px 0px 10px,
    rgba(56, 118, 255, 0.25) 0px 0px 4px;
  @media (min-width: 1500px) {
    font-size: 54px;
  }
  @media (min-width: 1900px) {
    font-size: 60px;
  }
  @media (max-width: 768px) {
    font-size: 30px;
    text-align: left;
  }
`;
const Subtitle = styled.h3`
  font-size: 20px;
  color: white;
  text-align: justify;
  line-height: 32px;
  font-weight: 500;
  @media (min-width: 1500px) {
    font-size: 24px;
    line-height: 36px;
  }
  @media (min-width: 1900px) {
    font-size: 30px;
    line-height: 40px;
  }
  @media (max-width: 768px) {
    font-size: 18px;
    line-height: 26px;
  }
`;
const Level = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 10px 16px 16px;
  position: relative;
  gap: 40px;
  @media (max-width: 768px) {
    padding: 0;
    gap: 20px;
  }
`;
const TitleRight = styled.h1`
  font-size: 36px;
  line-height: 36px;
  font-weight: 600;
  color: white;
  color: rgb(56, 118, 255);
  text-transform: uppercase;
  text-shadow: rgba(56, 118, 255, 0.6) 0px 0px 10px,
    rgba(56, 118, 255, 0.25) 0px 0px 4px;
  @media (min-width: 1500px) {
    font-size: 42px;
    line-height: 42px;
  }
  @media (min-width: 1900px) {
    font-size: 50px;
    line-height: 50px;
  }
  @media (max-width: 768px) {
    font-size: 28px;
    line-height: 28px;
    text-align: left;
    padding-left: 16px;
  }
`;
const SubtitleRight = styled.h3`
  font-size: 18px;
  color: white;
  font-weight: 300;
  line-height: 24px;
  text-align: justify;
  @media (min-width: 1500px) {
    font-size: 22px;
    line-height: 30px;
  }
  @media (min-width: 1900px) {
    font-size: 26px;
    line-height: 36px;
  }
`;
const MiddleContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 2px;
  padding: 16px;
`;
const VerticalScrollProgress = styled.div<{ progress: number }>`
  width: 2px;
  height: 70%;
  background-color: #e5e5e557;
  position: relative;
  overflow: hidden;
  padding-top: 30px;
  ::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 2px;
    height: ${(props) => props.progress * 2.5}%;
    transition: all 0.3s ease-in-out;
    background: linear-gradient(0deg, #1944a1 0%, #fff 100%);
  }
`;
const RadiusNummber = styled.div`
  width: 80px;
  height: 80px;
  border-radius: 20%;
  background-color: black;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 30px;
  font-weight: 900;
  position: absolute;
  left: -86px;
  top: -10px;
  border: 1px solid #a9c0ff;
  @media (max-width: 768px) {
    width: 50px;
    height: 50px;
    font-size: 16px;
    left: -49px;
    top: 0px;
  }
`;
const TopContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 16px;
  gap: 8px;
  align-items: center;
  justify-content: center;
  @media (max-width: 768px) {
    width: 100%;
    padding-top: 0;
  }
`;
export default JoinUs;
