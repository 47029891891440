import styled from "styled-components";
import { useRef, useLayoutEffect } from "react";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { BodyMedium, TitleLarge } from "../../commonComponents/Text";
import ReduceIcon from "../../assets/images/reduceIcon.svg";
import AchieveIcon from "../../assets/images/achieveIcon.svg";
import MaximizeIcon from "../../assets/images/maximizeIcon.svg";
const ConceptsMobile = () => {
  const mainContainer: any = useRef();
  const concept1Ref: any = useRef();
  const concept2Ref: any = useRef();
  const concept3Ref: any = useRef();
  useLayoutEffect(() => {
    //progress video with gsap
    gsap.registerPlugin(ScrollTrigger);
    ScrollTrigger.create({
      trigger: mainContainer.current,
      scrub: true,
      start: "top top",
      end: "+=100%",
      id: "pinConcepts",
      pinnedContainer: "#concepts",
      onUpdate: function (self) {
        if (concept1Ref.current && concept2Ref.current && concept3Ref.current) {
          let scrollPos = self.progress;
          if (scrollPos >= 0 && scrollPos <= 0.35) {
            slideSecondContainer(concept1Ref, scrollPos);
            concept2Ref.current.style.opacity = 0;
            concept3Ref.current.style.opacity = 0;
          } else if (scrollPos >= 0.35 && scrollPos < 0.7) {
            slideSecondContainer(concept2Ref, scrollPos - 0.35);
            concept1Ref.current.style.opacity = 1;
            concept1Ref.current.style.left = "calc(50% - 175px)";
          } else if (scrollPos >= 0.7 && scrollPos < 1) {
            slideSecondContainer(concept3Ref, scrollPos - 0.7);
            concept1Ref.current.style.opacity = 1;
            concept2Ref.current.style.opacity = 1;
            concept2Ref.current.style.left = "calc(50% - 175px)";
          } else {
            concept1Ref.current.style.opacity = 1;
            concept2Ref.current.style.opacity = 1;
            concept3Ref.current.style.opacity = 1;
            concept1Ref.current.style.left = "calc(50% - 175px)";
            concept2Ref.current.style.left = "calc(50% - 175px)";
            concept3Ref.current.style.left = "calc(50% - 175px)";
          }
        }
      },
    });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  function slideSecondContainer(container: any, progress: number) {
    container.current.style.left = `calc(${100 - progress * 143}% - 175px)`;
    container.current.style.opacity = progress * 3.5;
  }
  return (
    <Container ref={mainContainer} id="concepts">
      <ScrollContainer>
        <ConceptContainer ref={concept1Ref}>
          <IconContainer>
            <img src={ReduceIcon} alt="Reduce Icon" />
          </IconContainer>
          <TitleLarge
            style={{
              textTransform: "uppercase",
              marginTop: "4px",
              textAlign: "center",
            }}
          >
            Reduce
          </TitleLarge>
          <TitleLarge
            isSecondary
            style={{
              textTransform: "uppercase",
              textAlign: "center",
            }}
          >
            Contamination Risks
          </TitleLarge>
          <BodyMedium
            style={{
              textAlign: "center",
            }}
          >
            Protect your products from
            <br /> contamination and ensure the
            <br /> safety of your processes.
          </BodyMedium>
        </ConceptContainer>
        <ConceptContainer className="second" ref={concept2Ref}>
          <IconContainer>
            <img src={AchieveIcon} alt="Achieve Icon" />
          </IconContainer>
          <TitleLarge
            style={{
              textTransform: "uppercase",
              marginTop: "4px",
              textAlign: "center",
            }}
          >
            Optimize
          </TitleLarge>
          <TitleLarge
            isSecondary
            style={{
              textTransform: "uppercase",
              textAlign: "center",
            }}
          >
            Resource consumption
          </TitleLarge>
          <BodyMedium
            style={{
              textAlign: "center",
            }}
          >
            Optimize resource usage to reduce costs enhance sustainability.
          </BodyMedium>
        </ConceptContainer>
        <ConceptContainer className="third" ref={concept3Ref}>
          <IconContainer>
            <img src={MaximizeIcon} alt="Maximize Icon" />
          </IconContainer>

          <TitleLarge
            style={{
              textTransform: "uppercase",
              marginTop: "4px",
              textAlign: "center",
            }}
          >
            Maximize
          </TitleLarge>
          <TitleLarge
            isSecondary
            style={{
              textTransform: "uppercase",
              textAlign: "center",
            }}
          >
            Efficiency
          </TitleLarge>
          <BodyMedium
            style={{
              textAlign: "center",
            }}
          >
            Get the most out of our bioreactors and improve your production
            yields.
          </BodyMedium>
        </ConceptContainer>
      </ScrollContainer>
    </Container>
  );
};
const ScrollContainer = styled.div`
  position: sticky;
  top: 0;
  left: 0;
  width: 100%;
  height: 100px;
  min-height: 100vh;
  overflow: hidden;
`;
const Container = styled.div`
  display: flex;
  flex: 1;
  justify-content: start;
  flex-direction: column;
  position: relative;
  width: 100%;
  min-height: 200vh;
  background: #000407;
`;
const ConceptContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 10%;
  left: 100%;
  width: 350px;
  height: auto;
  gap: 2px;
  opacity: 0;
  &.second {
    left: 100%;
    top: 40%;
  }
  &.third {
    left: 100%;
    top: 70%;
  }
`;
const IconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 80px;
  height: 80px;
  img {
    width: 100%;
    height: 100%;
  }
`;

export default ConceptsMobile;
