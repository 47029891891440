import { Element } from "react-scroll";
import styled, { keyframes } from "styled-components";
import {
  BodyMedium,
  Heading2,
  Heading3,
  TitleLarge,
} from "../../commonComponents/Text";
import MarkerIcon from "../../assets/images/markerIcon.svg";
import Input from "../../components/Input";
import { useContext, useEffect, useState } from "react";
import Combobox from "../../components/Combobox";
import { COUNTRIES, COUNTRIES_ALPHA_2 } from "../../utils/content";
import InputCombo from "../../components/InputCombo";
import TextArea from "../../components/Textarea";
import ComboboxWithFilter from "../../components/ComboboxWithFilter";
import emailjs from "@emailjs/browser";
import CloseModalIcon from "../../assets/images/closeModalIcon.svg";
import SuccessIcon from "../../assets/images/successIcon.svg";
import LoaderModal from "../../commonComponents/LoaderModal";
import { sectionContext } from "../../context/sectionSelected";

const Contact = () => {
  const [firstName, setFirstName] = useState<string>("");
  const [emailAddress, setEmailAddress] = useState<string>("");
  const [company, setCompany] = useState<string>("");
  const [country, setCountry] = useState<string>("");
  const [requestType, setRequestType] = useState<string>("");
  const [acronimus, setAcronimus] = useState<string>("US");
  const [phone, setPhone] = useState<string>("");
  const [message, setMessage] = useState<string>("");
  const [complete, setComplete] = useState<boolean>(false);
  const [filter, setFilter] = useState<string>("");
  const [countries, setCountries] = useState(COUNTRIES);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [loaderSend, setLoaderSend] = useState<boolean>(false);
  const [emailValidate, setEmailValidate] = useState<boolean>(false);
  const { setSuscribed } = useContext(sectionContext);

  useEffect(() => {
    function evaluateFormComplete() {
      const emailRegex =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      const phoneRegex = /^\+?[1-9]\d{1,14}$/;
      const validateEmail = emailRegex.test(emailAddress);
      const validatePhone = phoneRegex.test(phone);
      setEmailValidate(validateEmail);
      if (
        firstName &&
        validateEmail &&
        company &&
        country &&
        requestType &&
        validatePhone &&
        message
      ) {
        setComplete(true);
      } else {
        setComplete(false);
      }
    }

    evaluateFormComplete();
  }, [firstName, emailAddress, company, country, requestType, phone, message]);

  useEffect(() => {
    function filterCountries() {
      if (filter) {
        const filteredCountries = COUNTRIES.filter((country) =>
          country.toLowerCase().includes(filter.toLowerCase())
        );
        setCountries(filteredCountries);
      } else {
        setCountries(COUNTRIES);
      }
    }
    filterCountries();
  }, [filter]);

  function handleSubmit() {
    setLoaderSend(true);
    const inputs = {
      firstName,
      emailAddress,
      company,
      country,
      requestType,
      phone,
      message,
      acronimus,
    };
    emailjs
      .send(
        "service_2pgmeaj",
        "template_8r2h1o6",
        inputs,
        "user_TXF9lD7Q4e7VeIu6q2Ojp"
      )
      .then(
        () => {
          setLoaderSend(false);
          setShowModal(true);
          setFirstName("");
          setEmailAddress("");
          setCompany("");
          setCountry("");
          setRequestType("");
          setPhone("");
          setMessage("");
          setFilter("");
          setSuscribed(true);
        },
        (error) => {
          console.log(error);
          setLoaderSend(false);
        }
      );
  }

  return (
    <>
      <Element name="#contact">
        <Container id="contact">
          <TopContainer>
            <TitleLarge
              isSecondary
              style={{
                textTransform: "uppercase",
                textShadow:
                  "0px 0px 10px rgba(56, 118, 255, 0.60), 0px 0px 4px rgba(56, 118, 255, 0.25)",
                marginBottom: "10px",
              }}
            >
              Contact US
            </TitleLarge>
            <Heading3>
              Fill out the form below and we'll get back to you as soon as
              possible!
            </Heading3>
            <BodyMedium
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-start",
                gap: "8px",
                color: "#9D9D9D",
                marginBottom: "32px",
              }}
            >
              <img src={MarkerIcon} alt="marker icon" />
              San Sebastián, Paseo de Miramón, 170, 3ª planta, 20014, España.
            </BodyMedium>
          </TopContainer>
          <FormContainer>
            <RowInputs>
              <InputContainer>
                <BodyMedium>First name</BodyMedium>
                <Input
                  placeholder="First Name"
                  value={firstName}
                  onChange={(e) => {
                    setFirstName(e.target.value);
                  }}
                />
              </InputContainer>
              <InputContainer>
                <BodyMedium>Email Address</BodyMedium>
                <Input
                  placeholder="Example@domain.com"
                  value={emailAddress}
                  onChange={(e) => {
                    setEmailAddress(e.target.value);
                  }}
                  error={!emailValidate}
                />
              </InputContainer>
              <InputContainer>
                <BodyMedium>Company</BodyMedium>
                <Input
                  placeholder="Company"
                  value={company}
                  onChange={(e) => {
                    setCompany(e.target.value);
                  }}
                />
              </InputContainer>
            </RowInputs>
            <RowInputs>
              <InputContainer>
                <BodyMedium>Phone</BodyMedium>
                <InputCombo
                  options={COUNTRIES_ALPHA_2}
                  value={phone}
                  onChange={(e) => {
                    setPhone(e.target.value);
                  }}
                  placeholder="+1 (000) 000-000"
                  acronimus={acronimus}
                  setAcronimus={setAcronimus}
                />
              </InputContainer>
              <InputContainer>
                <BodyMedium>Country</BodyMedium>
                <ComboboxWithFilter
                  label={"Select a country"}
                  options={countries}
                  value={country}
                  setValue={setCountry}
                  filterInput={filter}
                  setFilterInput={setFilter}
                />
              </InputContainer>
              <InputContainer>
                <BodyMedium>Request Type</BodyMedium>
                <Combobox
                  label={"Select"}
                  options={[
                    "Sales",
                    "Partnerships",
                    "Investors",
                    "News",
                    "Work with us",
                    "Other",
                  ]}
                  value={requestType}
                  setValue={setRequestType}
                />
              </InputContainer>
            </RowInputs>
            <RowInputs>
              <TextareaContainer>
                <BodyMedium>Message</BodyMedium>
                <TextArea
                  placeholder={"Write your message here"}
                  value={message}
                  onChange={(e) => {
                    setMessage(e.target.value);
                  }}
                />
              </TextareaContainer>
            </RowInputs>
            <RowInputs
              style={{
                justifyContent: "flex-end",
                marginBottom: "0px",
              }}
            >
              <Button
                onClick={() => {
                  handleSubmit();
                }}
                disabled={!complete}
              >
                Send
              </Button>
            </RowInputs>
          </FormContainer>
        </Container>
      </Element>
      {loaderSend && (
        <ModalContainer>
          <BackgroundModal
            className={loaderSend ? "show" : ""}
          ></BackgroundModal>
          <CardModal>
            <LoaderModal />
          </CardModal>
        </ModalContainer>
      )}
      {showModal && (
        <ModalContainer>
          <BackgroundModal
            onClick={() => setShowModal(false)}
            className={showModal ? "show" : ""}
          ></BackgroundModal>
          <CardModal>
            <CardBottomContainer>
              <img
                style={{
                  width: "100px",
                  height: "100px",
                }}
                src={SuccessIcon}
                alt="Success Icon"
              />
              <Heading2
                isSecondary
                style={{
                  textAlign: "center",
                  width: "100%",
                }}
              >
                Thank you for your message!
              </Heading2>
            </CardBottomContainer>
            <CloseModal
              src={CloseModalIcon}
              onClick={() => setShowModal(false)}
            />
          </CardModal>
        </ModalContainer>
      )}
    </>
  );
};
const TextareaContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 8px;
`;
const Container = styled.div`
  background: #000407;
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;
  width: 100%;
  padding-top: 20vh;
  padding-bottom: 32px;
`;
const TopContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  padding: 0 10%;
  width: 100%;
  max-width: 1600px;
  @media (max-width: 540px) {
    padding: 0 5%;
  }
  h3 {
    font-size: 30px;
    @media (min-width: 1500px) {
      font-size: 34px;
    }
  }
`;
const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 10%;
  width: 100%;
  max-width: 1600px;
  @media (max-width: 540px) {
    padding: 0 5%;
  }
`;
const RowInputs = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-bottom: 32px;
  @media (max-width: 540px) {
    flex-direction: column;
  }
`;
const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 30%;
  gap: 8px;
  position: relative;
  @media (max-width: 540px) {
    width: 100%;
  }
`;
const Button = styled.button<{ disabled: boolean }>`
  background: ${(props) => (props.disabled ? "#9D9D9D" : "#2e6fff")};
  border: none;
  border-radius: 4px;
  padding: 8px 32px;
  color: var(--gray-white, #fff);
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  outline: none;
  cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
  transition: all 0.3s ease-in-out;
  &:hover {
    background: ${(props) => (props.disabled ? "#9D9D9D" : "#1d4ed8")};
  }
`;
const FadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;
const ModalContainer = styled.div`
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 101;
  display: flex;
  justify-content: center;
  align-items: center;
  animation: ${FadeIn} 0.5s ease-in-out;
  top: 0;
  left: 0;
`;
const CloseModal = styled.img`
  width: 24px;
  height: 24px;
  position: absolute;
  top: 30px;
  right: 30px;
  cursor: pointer;
`;
const BackgroundModal = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  background: black;
  opacity: 0;
  z-index: -1;
  transition: all 0.3s ease-in-out;
  &.show {
    opacity: 0.5;
    z-index: 101;
  }
`;

const CardModal = styled.div`
  width: 660px;
  height: 500px;
  border-radius: 10px;
  border: 2px solid var(--primario, #3876ff);
  display: flex;
  flex-direction: column;
  z-index: 102;
  background: #000407;
  position: relative;
  @media (max-width: 768px) {
    width: 95%;
  }
`;
const CardBottomContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 40px 60px;
  border-radius: 10px;
  gap: 32px;
  background: linear-gradient(135deg, rgba(15, 22, 33, 0.3) 0%, #0f1621 100%);
  backdrop-filter: blur(5px);
  @media (max-width: 768px) {
    padding: 40px 8px;
  }
`;

export default Contact;
