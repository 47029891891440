import styled from "styled-components";
import { useRef, useLayoutEffect, useState, useContext } from "react";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { sectionContext } from "../../context/sectionSelected";

const TankMobile = () => {
  const containerRef: any = useRef();
  const tankVideoRef: any = useRef();
  const firstSceneRef: any = useRef();
  const secondtSceneRef: any = useRef();
  const firstTextRef: any = useRef();
  const secondTextRef: any = useRef();
  const thirdTextRef: any = useRef();
  const fourthTextRef: any = useRef();
  // const fifthTextRef: any = useRef();
  const [loadMetadata, setLoadMetadata] = useState<boolean>(false);
  const { setShowButton } = useContext(sectionContext);

  useLayoutEffect(() => {
    if (loadMetadata) {
      //progress video with gsap
      if (tankVideoRef.current) {
        gsap.registerPlugin(ScrollTrigger);
        ScrollTrigger.create({
          trigger: containerRef.current,
          scrub: true,
          start: "top top",
          end: "+=100%",
          id: "pinTank",
          pinnedContainer: "#tank",
          onEnter: function (self) {
            setShowButton(true);
          },
          onUpdate: function (self) {
            if (tankVideoRef.current && tankVideoRef.current.duration) {
              let scrollPos = self.progress;
              if (scrollPos === 0) {
                tankVideoRef.current.currentTime = 0;
              }
            }
            if (firstTextRef.current) {
              let scrollPos = self.progress;
              if (scrollPos === 0) {
                firstSceneRef.current.style.opacity = 1;
                firstTextRef.current.style.opacity = 1;
                secondTextRef.current.style.opacity = 0.3;
                thirdTextRef.current.style.opacity = 0.3;
                secondtSceneRef.current.style.opacity = 0;
                fourthTextRef.current.style.opacity = 1;
                // fifthTextRef.current.style.opacity = 0.3;
              } else if (scrollPos > 0 && scrollPos < 0.2) {
                fadeOutText(firstTextRef, scrollPos);
                fadeInText(secondTextRef, scrollPos);
                slideFirstScene(firstTextRef, scrollPos, 0);
              } else if (scrollPos >= 0.2 && scrollPos < 0.4) {
                fadeOutText(secondTextRef, scrollPos - 0.2);
                fadeInText(thirdTextRef, scrollPos - 0.2);
                slideFirstScene(firstTextRef, scrollPos - 0.3, 15);
              } else if (scrollPos >= 0.4 && scrollPos < 0.55) {
                fadeOutContainer(firstSceneRef, scrollPos - 0.4);
              } else if (scrollPos >= 0.55 && scrollPos < 0.7) {
                fadeInContainer(secondtSceneRef, scrollPos - 0.55);
              } else if (scrollPos >= 0.7 && scrollPos < 0.9) {
                fadeOutText(fourthTextRef, scrollPos - 0.7);
                // fadeInText(fifthTextRef, scrollPos - 0.7);
                slideFirstScene(fourthTextRef, scrollPos - 0.7, 0);
              } else if (scrollPos >= 0.9 && scrollPos < 1) {
              } else {
                firstSceneRef.current.style.opacity = 0;
                firstTextRef.current.style.opacity = 0.3;
                secondTextRef.current.style.opacity = 0.3;
                thirdTextRef.current.style.opacity = 1;
                secondtSceneRef.current.style.opacity = 1;
                fourthTextRef.current.style.opacity = 0.3;
                // fifthTextRef.current.style.opacity = 1;
              }
              if (scrollPos > 0.25) {
                firstTextRef.current.style.opacity = 0.3;
              }
              if (scrollPos < 0.55) {
                secondtSceneRef.current.style.opacity = 0;
              }
              if (scrollPos >= 0.55) {
                firstSceneRef.current.style.opacity = 0;
              }
              if (scrollPos > 0.65) {
                firstSceneRef.current.style.opacity = 0;
                firstTextRef.current.style.opacity = 0.3;
                secondTextRef.current.style.opacity = 0.3;
                thirdTextRef.current.style.opacity = 1;
                firstTextRef.current.style.marginTop = "-30px";
              }
            }
          },
        });
      }
    }
  }, [loadMetadata]); // eslint-disable-line react-hooks/exhaustive-deps

  function fadeOutContainer(containerRef: any, progress: number) {
    containerRef.current.style.opacity = 1 - progress * 7;
  }
  function fadeInContainer(containerRef: any, progress: number) {
    containerRef.current.style.opacity = progress * 7;
  }
  function fadeOutText(textRef: any, progress: number) {
    textRef.current.style.opacity = 1 - progress * 3.6;
  }
  function fadeInText(textRef: any, progress: number) {
    textRef.current.style.opacity = 0.3 + progress * 3.6;
  }
  function slideFirstScene(textRef: any, progress: number, top: number) {
    textRef.current.style.marginTop = `-${top + progress * 75}px`;
  }

  return (
    <Container ref={containerRef} id="tank">
      <ScrollContainer>
        <Video
          ref={tankVideoRef}
          preload="auto"
          webkit-playsinline="true"
          muted
          src="/tank.mp4"
          onLoadedMetadata={() => setLoadMetadata(true)}
          autoPlay
          loop
          playsInline
        />
        <FirstScene ref={firstSceneRef}>
          <TitleLarge ref={firstTextRef}>
            Unlock real-time information
            <br />
            without the necessity of extractions.
          </TitleLarge>
          <TitleLarge ref={secondTextRef}>Simplified scale up to</TitleLarge>
          <TitleLarge ref={thirdTextRef}>The volume you determine.</TitleLarge>
        </FirstScene>
        <SecondScene ref={secondtSceneRef}>
          <TitleLarge ref={fourthTextRef} style={{ textAlign: "start" }}>
            With just a click, reduce
            <br />
            experiment design.
            <br />
            Our cutting-edge tech streamlines bioprocessing for high-quality
            biotech production.
          </TitleLarge>
          {/* <TitleLarge ref={fifthTextRef} style={{ textAlign: "start" }}>
            BION is where the future of
            <br />
            biofabrication begins.
          </TitleLarge> */}
        </SecondScene>
      </ScrollContainer>
    </Container>
  );
};

const Video = styled.video`
  position: absolute;
  object-fit: cover;
  z-index: -1;
  width: 100%;
  height: 60%;
  transition: all 0.1s ease;
  left: 0;
  right: 0;
  margin: auto;
  top: 10%;
`;
const ScrollContainer = styled.div`
  position: sticky;
  top: 0;
  left: 0;
  width: 100%;
  height: 100px;
  min-height: 100vh;
  overflow: hidden;
`;
const Container = styled.div`
  background: #000407;
  display: flex;
  flex: 1;
  justify-content: start;
  flex-direction: column;
  position: relative;
  width: 100%;
  min-height: 200vh;
`;
const FirstScene = styled.div`
  position: absolute;
  width: 100%;
  bottom: 10%;
  opacity: 1;
  transition: 0.1s ease;
  left: 16px;
  right: 0;
  margin: auto;
  h5 {
    font-weight: 600;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    color: #FFF;
  }
`;
const SecondScene = styled.div`
  position: absolute;
  width: 100%;
  bottom: 10%;
  opacity: 0;
  transition: 0.1s ease;
  left: 16px;
  right: 0;
  margin: auto;
  h5 {
    font-weight: 600;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    color: #FFF;
  }
`;
const TitleLarge = styled.h5`
  font-weight: 600;
  font-size: 32px;
  line-height: 40px;
  opacity: 1;
  margin-bottom: 16px;
  transition: all 0.1s ease;
  color: #FFF;
  &:nth-of-type(2) {
    opacity: 0.3;
    transition: all 0.1s ease;
  }
  &:nth-of-type(3) {
    opacity: 0.3;
    transition: all 0.1s ease;
  }
`;
export default TankMobile;
