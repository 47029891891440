import { createContext, useState } from "react";

interface SectionContext {
  section: string;
  setSection: (newSection: any) => void;
  suscribed: boolean;
  setSuscribed: (newSuscribed: any) => void;
  showButton: boolean;
  setShowButton: (newShowButton: any) => void;
}

const sectionContext = createContext<SectionContext>({
  section: "home",
  setSection: () => {},
  suscribed: false,
  setSuscribed: () => {},
  showButton: false,
  setShowButton: () => {},
});

const SectionProvider = ({ children }: { children: React.ReactNode }) => {
  const [section, setSection] = useState("home");
  const [suscribed, setSuscribed] = useState(false);
  const [showButton, setShowButton] = useState(false);
  return (
    <sectionContext.Provider
      value={{
        section: section,
        setSection: setSection,
        suscribed: suscribed,
        setSuscribed: setSuscribed,
        showButton: showButton,
        setShowButton: setShowButton,
      }}
    >
      {children}
    </sectionContext.Provider>
  );
};

export { SectionProvider, sectionContext };
