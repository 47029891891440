import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import Toast from "../../utils/toast";
import { useCollectionData } from "react-firebase-hooks/firestore";
import { firestore, auth } from "../../utils/firebase";

export default function useUsersList() {
  const usersRef = firestore.collection("users");
  const [users, loading, error] = useCollectionData(usersRef);

  const MySwal = withReactContent(Swal);

  const deleteUser = async (uid:string) => {
    const swalResponse = await MySwal.fire({
      title: "Desactivar usuario",
      text: "Está seguro de querer desactivar a este usuario?",
      showConfirmButton: true,
      showCancelButton: true,
      showLoaderOnConfirm: true,
      backdrop: true,
      preConfirm: async () => {
        await usersRef.doc(uid).update({ active: false });
      },
      allowOutsideClick: () => !MySwal.isLoading(),
    });
    if (swalResponse.isConfirmed) {
      Toast.fire({ icon: "success", title: "Usuario desactivado con éxito" });
    }
  };

  const reactivateUser = async (uid:string) => {
    const swalResponse = await MySwal.fire({
      title: "Reactivar usuario",
      text: "Está seguro de querer reactivar a este usuario?",
      showConfirmButton: true,
      showCancelButton: true,
      showLoaderOnConfirm: true,
      backdrop: true,
      preConfirm: async () => {
        await usersRef.doc(uid).update({ active: true });
      },
      allowOutsideClick: () => !MySwal.isLoading(),
    });
    if (swalResponse.isConfirmed) {
      Toast.fire({ icon: "success", title: "Usuario reactivado con éxito" });
    }
  };

  const resetPassword = async (email:string) => {
    const swalResponse = await MySwal.fire({
      title: "Restablecer contraseña",
      text: `Se enviará un correo de restablecimiento de contraseña a la dirección ${email}. Desea continuar?`,
      showConfirmButton: true,
      showCancelButton: true,
      showLoaderOnConfirm: true,
      backdrop: true,
      preConfirm: async () => {
        await auth.sendPasswordResetEmail(email);
      },
      allowOutsideClick: () => !MySwal.isLoading(),
    });
    if (swalResponse.isConfirmed) {
      Toast.fire({
        icon: "success",
        title: "Mail de restablecimiento enviado con éxito",
      });
    }
  };
  return {
    users,
    resetPassword,
    deleteUser,
    reactivateUser,
    loading,
    error,
  };
}
