import { useEffect, useState } from "react";
import Toast from "../utils/toast";
import styled from "styled-components";

export default function ImageUploader({
  defaultSrc,
  onChange,
  cssWidth,
}: {
  defaultSrc: any;
  onChange: any;
  cssWidth?: any;
}) {
  cssWidth = cssWidth || "100%";
  const [imgSrc, setImgSrc] = useState(defaultSrc);
  useEffect(() => {
    setImgSrc(defaultSrc);
  }, [defaultSrc]);

  const onUpload = (event: any) => {
    event.preventDefault();
    const file = event.target.files[0];
    if (!file) {
      return;
    }
    const validExtensions = ["png", "svg"];
    const extension = file.name.split(".").pop();
    if (!validExtensions.includes(extension)) {
      Toast.fire({
        icon: "error",
        title: "Solo se aceptan imagenes .png y .svg",
      });
      return;
    }
    const reader = new FileReader();
    reader.onload = () => {
      setImgSrc(reader.result);
    };
    reader.readAsDataURL(file);
    onChange(file);
  };

  return (
    <Label style={{ cursor: "pointer" }}>
      {imgSrc ? (
        <img src={imgSrc} alt="" style={{ display: "block", width: cssWidth }} /> 
      ) : (
        <Container
          style={{
            width: cssWidth,
            height: "200px",
            background: "var(--dark-gray)",
            color: "#354b79",
            fontSize: "2rem",
          }}
        >
          Añadir imágen
        </Container>
      )}
      <Input
        type="file"
        accept="image/png, image/svg+xml"
        style={{ display: "none" }}
        onChange={onUpload}
      />
    </Label>
  );
}

const Label = styled.label``;
const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;
const Input = styled.input``;
