import { Element } from "react-scroll";
import styled, { keyframes } from "styled-components";
import { useLayoutEffect, useRef } from "react";
import { TitleMedium } from "../../commonComponents/Text";
import { Navigation, Pagination, Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react/swiper-react.js";
import SwiperCore from "swiper";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

export interface TeamContent {
  id: number;
  name: string;
  title: string;
  image: string;
  video: string;
  bottom?: string;
}
SwiperCore.use([Pagination, Navigation, Autoplay]);
const TeamMobile = () => {
  const mainContainer: any = useRef();
  const titleRef: any = useRef();
  const swipperRef: any = useRef();
  const teamContent: TeamContent[] = [
    {
      id: 1,
      name: "Juan Garzón",
      title: "CEO & Founder",
      image: "/team/Juan.webp",
      video: "/team/Juan.mp4",
      bottom: "-18px",
    },
    {
      id: 2,
      name: "Ruben Palomero",
      title: "CTO & Founder",
      image: "/team/Ruben.webp",
      video: "/team/Ruben.mp4",
      bottom: "-13px",
    },
    {
      id: 3,
      name: "Sandra Rodríguez",
      title: "CFO",
      image: "/team/Sandra.webp",
      video: "/team/Sandra.mp4",
      bottom: "-15px",
    },
    {
      id: 4,
      name: "Julen Cao",
      title: "Head of software",
      image: "/team/Julen.webp",
      video: "/team/Julen.mp4",
      bottom: "-0px",
    },
    {
      id: 5,
      name: "Tunca Nüzket",
      title: "Hardware Architect",
      image: "/team/Tunca.webp",
      video: "/team/Tunca.mp4",
      bottom: "-9%",
    },
  ];

  useLayoutEffect(() => {
    //progress video with gsap
    gsap.registerPlugin(ScrollTrigger);
    ScrollTrigger.create({
      trigger: mainContainer.current,
      scrub: true,
      start: "top top",
      end: "+=100%",
      id: "pinTeam",
      pinnedContainer: "#teams",
      onUpdate: function (self) {
        if (mainContainer.current) {
          let scrollPos = self.progress;
          if (scrollPos === 0) {
            titleRef.current.style.transform = `translateY(0vh)`;
            titleRef.current.style.opacity = 0;
            swipperRef.current.style.transform = `translateY(0vh)`;
            swipperRef.current.style.opacity = 0;
          } else if (scrollPos > 0 && scrollPos < 0.5) {
            slideInTitle(titleRef, scrollPos);
          } else if (scrollPos >= 0.5 && scrollPos < 0.6) {
          } else if (scrollPos >= 0.6 && scrollPos < 1) {
            slideInSwipper(swipperRef, scrollPos - 0.6);
          } else {
            titleRef.current.style.transform = `translateY(-80vh)`;
            titleRef.current.style.opacity = 1;
            swipperRef.current.style.transform = `translateY(-67.5vh)`;
            swipperRef.current.style.opacity = 1;
          }
          if (scrollPos > 0.5) {
            titleRef.current.style.transform = `translateY(-80vh)`;
            titleRef.current.style.opacity = 1;
          }
          if (scrollPos >= 1) {
            swipperRef.current.style.transform = `translateY(-67.5vh)`;
            swipperRef.current.style.opacity = 1;
          }
          if (scrollPos < 0.6) {
            swipperRef.current.style.transform = `translateY(0vh)`;
            swipperRef.current.style.opacity = 0;
          }
        }
      },
    });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  function slideInTitle(container: any, progress: number) {
    container.current.style.transform = `translateY(-${progress * 160}vh)`;
    container.current.style.opacity = progress * 2.1;
  }
  function slideInSwipper(container: any, progress: number) {
    container.current.style.transform = `translateY(-${progress * 161}vh)`;
    container.current.style.opacity = progress * 2.6;
  }

  return (
    <Element name="#team">
      <Container ref={mainContainer} id="team">
        <ScrollContainer animated={false}>
          <EllipseContainer>
            <Ellipse />
          </EllipseContainer>
          <Shadow />
          <TitleContainer ref={titleRef}>
            <TitleMedium
              style={{
                textTransform: "uppercase",
                textShadow:
                  "0px 0px 25px rgba(56, 118, 255, 0.50), 0px 0px 4px rgba(56, 118, 255, 0.25)",
                textAlign: "center",
              }}
              isSecondary
            >
              Our Team is our strength
              {/* <br /> Our team has extensive experience in bioprocessing and
              fermentation, combined with a deep understanding of the latest
              software and hardware developments. We understand the challenges
              faced by the bioprocessing industry and use this to guide the
              development of a truly innovative solution. */}
            </TitleMedium>
          </TitleContainer>
          <SwipperContainer ref={swipperRef}>
            <SwiperWrapper
              autoplay={{
                delay: 0,
                disableOnInteraction: false,
                pauseOnMouseEnter: true,
              }}
              slidesPerView={1}
              className="mySwiper2"
              speed={2500}
              freeMode={true}
              loopedSlides={7}
              loop={true}
            >
              {teamContent.map((item: TeamContent, index) => (
                <SwiperSlide style={{ color: "white" }} key={index}>
                  <Card>
                    <CardImageContainer>
                      <CardImageStyled
                        style={{
                          bottom: item.bottom,
                        }}
                        src={item.image}
                        alt={item.name}
                      />
                    </CardImageContainer>
                    <CardVideoContainer>
                      <CardVideoStyled
                        webkit-playsinline="true"
                        playsInline
                        autoPlay
                        loop
                        muted
                        src={item.video}
                      />
                      <GradientBackground />
                      <NameCard>{item.name}</NameCard>
                      <TitleCard>{item.title}</TitleCard>
                    </CardVideoContainer>
                  </Card>
                </SwiperSlide>
              ))}
            </SwiperWrapper>
          </SwipperContainer>
        </ScrollContainer>
      </Container>
    </Element>
  );
};
const MoveEllipse = keyframes`
  0% {
    left: -26%;
    top: -58%;
  }
  12.5% {
    left: 70%;
    top: -35%;
  }
  25% {
    left: -26%;
    top: -15%;
  }
  37.5% {
    left: 70%;
    top: 0%;
  }
  50% {
    left: -26%;
    top: 15%;
  }
  62.5% {
    left: 70%;
    top: 0%;
  }
  75% {
    left: -26%;
    top: -15%;
  }
  87.5% {
    left: 70%;
    top: -35%;
  }
  100% {
    left: -26%;
    top: -58%;
  }
`;

const NameCard = styled.p`
  color: #fff;
  font-size: 24px;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  line-height: 40px;
  bottom: 67px;
  position: absolute;
  padding-left: 40px;
  z-index: 7;
`;
const TitleCard = styled.p`
  color: #fff;
  font-size: 16px;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  bottom: 46px;
  position: absolute;
  padding-left: 40px;
  z-index: 7;
`;
const ScrollContainer = styled.div<{ animated: boolean }>`
  position: sticky;
  top: 0;
  left: 0;
  width: 100%;
  height: 100px;
  min-height: 100vh;
  overflow: hidden;
`;
const Container = styled.div`
  background: #000407;
  display: flex;
  flex: 1;
  justify-content: start;
  align-items: center;
  flex-direction: column;
  position: relative;
  width: 100%;
  height: 200vh;
`;
const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: auto;
  position: relative;
  justify-content: center;
  padding-left: 0vw;
  top: 100vh;
  position: absolute;
  opacity: 0;
  transition: all 0.1s ease;
`;
const SwipperContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  width: 100%;
  height: 60vh;
  position: relative;
  justify-content: flex-end;
  padding-left: 0;
  top: 100vh;
  position: absolute;
  opacity: 0;
  transition: all 0.1s ease;
`;
const CardImageContainer = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  background: #000202;
`;
const CardVideoContainer = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  bottom: -22px;
  opacity: 0;
  transition: all 0.3s ease-in-out;
  &:hover {
    opacity: 1;
  }
`;
const CardVideoStyled = styled.video`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;
const GradientBackground = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  border-radius: 4px;
  opacity: 0.400000011920929;
  background: linear-gradient(0deg, #080c10 12.5%, rgba(8, 12, 16, 0) 100%);
  z-index: 5;
  top: 0;
  left: 0;
`;
const SwiperWrapper = styled(Swiper)`
  width: calc(90% - 64px);
  padding: 0 32px;
  width: 100%;
  margin-top: 32px;
  height: 55vh;
  .swiper-wrapper {
    transition-timing-function: cubic-bezier(0.06, 0.15, 1, 0.86);
    margin-left: 6vw;
  }
  &::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    background: linear-gradient(
      90deg,
      #080c1070 0%,
      rgba(8, 12, 16, 0) 20%,
      rgba(8, 12, 16, 0) 80%,
      #080c1070 100%
    );
    z-index: 5;
    top: 0;
    left: 0;
    pointer-events: none;
  }
  @media (max-width: 480px) {
    margin: 0;
    padding: 0px;
    width: calc(100% - 32px);
  }
  transition-timing-function: cubic-bezier(0.06, 0.15, 1, 0.86);
`;
const Card = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 90vw;
  height: 100%;
  position: relative;
  border-radius: 4px;
  z-index: 10;
  transition: all 0.3s ease-in-out;
  overflow: hidden;
  border-radius: 4px;
`;
const CardImageStyled = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: relative;
`;
const EllipseContainer = styled.div`
  position: absolute;
  width: 60vw;
  height: 60vw;
  left: -26%;
  top: -58%;
  transition: all 0.1s ease;
  animation: ${MoveEllipse} 30s infinite ease-in-out;
`;
const Ellipse = styled.div`
  background: radial-gradient(
    50% 50% at 50% 50%,
    rgba(73, 149, 182, 0.29) 0%,
    rgba(108, 201, 242, 0) 100%
  );
  filter: blur(24.733078002929688px);
  position: absolute;
  width: 60vw;
  height: 60vw;
`;
const Shadow = styled.div`
  position: absolute;
  width: 100%;
  height: 100vh;
  background: linear-gradient(
    180deg,
    #000407 00%,
    rgb(0 0 0 / 0%) 50%,
    #000407 100%
  );
`;
export default TeamMobile;
