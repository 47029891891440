import { getAuth, User } from "firebase/auth";
import { useEffect, useState } from "react";
import Loader from "../commonComponents/Loader";
import LoginForm from "./../admin/screens/LoginForm";

export default function PrivateRoute({ children }: any) {
  const [user, setUser] = useState<User | null | undefined>();
  const [loading, setLoading] = useState<boolean>(true);
  const auth = getAuth();
  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async (user: any) => {
      if (user) {
        setUser(user);
      }
      setLoading(false);
    });

    return () => {
      unsubscribe();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (user) {
    return children;
  } else if (user === undefined && !loading) {
    return <LoginForm />;
  } else {
    return <Loader />;
  }
}
