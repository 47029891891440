import { useLayoutEffect, useContext, useState } from "react";

import About from "./components/About";
import Product from "./components/Product";
import Header from "../components/Header";
import Footer from "../components/Footer";
import Microchip from "./components/Microchip";
import Contact from "./components/Contact";
import Tank from "./components/Tank";
import Concepts from "./components/Concepts";
import ProductDescription from "./components/ProductDescription";
import Team from "./components/Team";
import Verticals from "./components/Verticals";
import PressCards from "./components/PressCards";
import { sectionContext } from "../context/sectionSelected";
import useWindowSize from "../hooks/useWindowSize";
import Support from "./components/Support";
import HeaderResponsive from "../components/HeaderResponsive";
import FooterResponsive from "../components/FooterResponsive";
import AboutMobile from "./components/AboutMobile";
import TankMobile from "./components/TankMobile";
import MicrochipMobile from "./components/MicrochipMobile";
import ConceptsMobile from "./components/ConceptsMobile";
import ProductDescriptionMobile from "./components/ProductDescriptionMobile";
import VerticalsMobile from "./components/VerticalsMobile";
import TeamMobile from "./components/TeamMobile";
import SupportMobile from "./components/SupportMobile";
import PressCardsMobile from "./components/PressCardsMobile";
import ContactMobile from "./components/ContactMobile";
import ProductMobile from "./components/ProductMobile";
import styled, { keyframes } from "styled-components";
import Pretank from "./components/Pretank";
import Newsletter from "./components/Newsletter";
import NewsletterMobile from "./components/NewsletterMobile";
import GetInTouchButton from "../components/GetInTouchButton";
import NewsletterModal from "./components/NewsletterModal";
import NewsletterModalMobile from "./components/NewsletterModalMobile";
import GetInTouchButtonMobile from "./../components/GetInTouchButtonMobile";
import CookieConsent from "react-cookie-consent";
const Home = () => {
  const { section, setSection } = useContext(sectionContext);
  const [, setLoader] = useState<boolean>(false);

  useLayoutEffect(() => {
    if (
      section === "press" ||
      section === "preVerticals" ||
      section === "verticals" ||
      section === "product" ||
      section === "team" ||
      section === "support" ||
      section === "contact"
    ) {
      setLoader(true);
      setTimeout(() => {
        if (section === "press") {
          const nextElementPosition =
            document.getElementById("newsletter")?.offsetTop;
          const goToBottom = document.getElementById("footer");
          goToBottom?.scrollIntoView();
          if (nextElementPosition) {
            setTimeout(() => {
              window.scrollTo({
                top: nextElementPosition - window.innerHeight,
              });
            }, 100);
            setTimeout(() => {
              window.scrollTo({
                top: nextElementPosition - window.innerHeight,
              });
            }, 150);
            setTimeout(() => {
              window.scrollTo({
                top: nextElementPosition - window.innerHeight,
              });
            }, 200);
            setTimeout(() => {
              window.scrollTo({
                top: nextElementPosition - window.innerHeight,
              });
            }, 300);
            setTimeout(() => {
              window.scrollTo({
                top: nextElementPosition - window.innerHeight,
              });
            }, 500);
            setTimeout(() => {
              window.scrollTo({
                top: nextElementPosition - window.innerHeight,
              });
            }, 600);
            setTimeout(() => {
              window.scrollTo({
                top: nextElementPosition - window.innerHeight,
              });
            }, 625);
            setTimeout(() => {
              window.scrollTo({
                top: nextElementPosition - window.innerHeight,
              });
            }, 675);
            setTimeout(() => {
              window.scrollTo({
                top: nextElementPosition - window.innerHeight,
              });
              setLoader(false);
            }, 700);
            setTimeout(() => {
              window.scrollTo({
                top: nextElementPosition - window.innerHeight,
              });
              setLoader(false);
            }, 750);
            setTimeout(() => {
              window.scrollTo({
                top: nextElementPosition - window.innerHeight,
              });
              setLoader(false);
            }, 800);
            setTimeout(() => {
              window.scrollTo({
                top: nextElementPosition - window.innerHeight,
              });
              setLoader(false);
            }, 900);
          }
        } else if (section === "preVerticals") {
          const nextElementPosition =
            document.getElementById("preVerticals")?.offsetTop;
          const goToBottom = document.getElementById("footer");
          goToBottom?.scrollIntoView();
          if (nextElementPosition) {
            setTimeout(() => {
              window.scrollTo({
                top: nextElementPosition + window.innerHeight,
              });
            }, 100);
            setTimeout(() => {
              window.scrollTo({
                top: nextElementPosition + window.innerHeight,
              });
            }, 150);
            setTimeout(() => {
              window.scrollTo({
                top: nextElementPosition + window.innerHeight,
              });
            }, 200);
            setTimeout(() => {
              window.scrollTo({
                top: nextElementPosition + window.innerHeight,
              });
            }, 300);
            setTimeout(() => {
              window.scrollTo({
                top: nextElementPosition + window.innerHeight,
              });
            }, 500);
            setTimeout(() => {
              window.scrollTo({
                top: nextElementPosition + window.innerHeight,
              });
            }, 600);
            setTimeout(() => {
              window.scrollTo({
                top: nextElementPosition + window.innerHeight,
              });
            }, 625);
            setTimeout(() => {
              window.scrollTo({
                top: nextElementPosition + window.innerHeight,
              });
            }, 675);
            setTimeout(() => {
              window.scrollTo({
                top: nextElementPosition + window.innerHeight,
              });
              setLoader(false);
            }, 700);
            setTimeout(() => {
              window.scrollTo({
                top: nextElementPosition + window.innerHeight,
              });
              setLoader(false);
            }, 750);
            setTimeout(() => {
              window.scrollTo({
                top: nextElementPosition + window.innerHeight,
              });
              setLoader(false);
            }, 800);
            setTimeout(() => {
              window.scrollTo({
                top: nextElementPosition + window.innerHeight,
              });
              setLoader(false);
            }, 900);
          }
        } else if (section === "verticals") {
          const nextElementPosition =
            document.getElementById("verticals")?.offsetTop;
          const goToBottom = document.getElementById("footer");
          goToBottom?.scrollIntoView();
          if (nextElementPosition) {
            setTimeout(() => {
              window.scrollTo({
                top: nextElementPosition,
              });
            }, 100);
            setTimeout(() => {
              window.scrollTo({
                top: nextElementPosition,
              });
            }, 150);
            setTimeout(() => {
              window.scrollTo({
                top: nextElementPosition,
              });
            }, 200);
            setTimeout(() => {
              window.scrollTo({
                top: nextElementPosition,
              });
            }, 300);
            setTimeout(() => {
              window.scrollTo({
                top: nextElementPosition,
              });
            }, 500);
            setTimeout(() => {
              window.scrollTo({
                top: nextElementPosition,
              });
            }, 600);
            setTimeout(() => {
              window.scrollTo({
                top: nextElementPosition,
              });
            }, 625);
            setTimeout(() => {
              window.scrollTo({
                top: nextElementPosition,
              });
            }, 675);
            setTimeout(() => {
              window.scrollTo({
                top: nextElementPosition,
              });
              setLoader(false);
            }, 700);
            setTimeout(() => {
              window.scrollTo({
                top: nextElementPosition,
              });
              setLoader(false);
            }, 750);
            setTimeout(() => {
              window.scrollTo({
                top: nextElementPosition,
              });
              setLoader(false);
            }, 800);
            setTimeout(() => {
              window.scrollTo({
                top: nextElementPosition,
              });
              setLoader(false);
            }, 900);
          }
        } else if (section === "product") {
          const nextElementPosition =
            document.getElementById("product")?.offsetTop;
          if (nextElementPosition) {
            setTimeout(() => {
              window.scrollTo({
                top: nextElementPosition + 500,
              });
            }, 100);
            setTimeout(() => {
              window.scrollTo({
                top: nextElementPosition + 500,
              });
            }, 150);
            setTimeout(() => {
              window.scrollTo({
                top: nextElementPosition + 500,
              });
            }, 200);
            setTimeout(() => {
              window.scrollTo({
                top: nextElementPosition + 500,
              });
            }, 300);
            setTimeout(() => {
              window.scrollTo({
                top: nextElementPosition + 500,
              });
            }, 500);
            setTimeout(() => {
              window.scrollTo({
                top: nextElementPosition + 500,
              });
            }, 600);
            setTimeout(() => {
              window.scrollTo({
                top: nextElementPosition + 500,
              });
            }, 625);
            setTimeout(() => {
              window.scrollTo({
                top: nextElementPosition + 500,
              });
            }, 675);
            setTimeout(() => {
              window.scrollTo({
                top: nextElementPosition + 500,
              });
              setLoader(false);
            }, 700);
            setTimeout(() => {
              window.scrollTo({
                top: nextElementPosition + 500,
              });
              setLoader(false);
            }, 750);
            setTimeout(() => {
              window.scrollTo({
                top: nextElementPosition + 500,
              });
              setLoader(false);
            }, 800);
            setTimeout(() => {
              window.scrollTo({
                top: nextElementPosition + 500,
              });
              setLoader(false);
            }, 900);
          }
        } else if (section === "team") {
          const nextElementPosition =
            document.getElementById("support")?.offsetTop;
          const goToBottom = document.getElementById("footer");
          goToBottom?.scrollIntoView();
          if (nextElementPosition) {
            setTimeout(() => {
              window.scrollTo({
                top: nextElementPosition - window.innerHeight,
              });
            }, 100);
            setTimeout(() => {
              window.scrollTo({
                top: nextElementPosition - window.innerHeight,
              });
            }, 150);
            setTimeout(() => {
              window.scrollTo({
                top: nextElementPosition - window.innerHeight,
              });
            }, 200);
            setTimeout(() => {
              window.scrollTo({
                top: nextElementPosition - window.innerHeight,
              });
            }, 300);
            setTimeout(() => {
              window.scrollTo({
                top: nextElementPosition - window.innerHeight,
              });
            }, 500);
            setTimeout(() => {
              window.scrollTo({
                top: nextElementPosition - window.innerHeight,
              });
            }, 600);
            setTimeout(() => {
              window.scrollTo({
                top: nextElementPosition - window.innerHeight,
              });
            }, 625);
            setTimeout(() => {
              window.scrollTo({
                top: nextElementPosition - window.innerHeight,
              });
            }, 675);
            setTimeout(() => {
              window.scrollTo({
                top: nextElementPosition - window.innerHeight,
              });
              setLoader(false);
            }, 700);
            setTimeout(() => {
              window.scrollTo({
                top: nextElementPosition - window.innerHeight,
              });
              setLoader(false);
            }, 750);
            setTimeout(() => {
              window.scrollTo({
                top: nextElementPosition - window.innerHeight,
              });
              setLoader(false);
            }, 800);
            setTimeout(() => {
              window.scrollTo({
                top: nextElementPosition - window.innerHeight,
              });
              setLoader(false);
            }, 900);
          }
        } else if (section === "support") {
          const nextElementPosition =
            document.getElementById("press")?.offsetTop;
          const goToBottom = document.getElementById("footer");
          goToBottom?.scrollIntoView();
          if (nextElementPosition) {
            setTimeout(() => {
              window.scrollTo({
                top: nextElementPosition - window.innerHeight,
              });
            }, 100);
            setTimeout(() => {
              window.scrollTo({
                top: nextElementPosition - window.innerHeight,
              });
            }, 150);
            setTimeout(() => {
              window.scrollTo({
                top: nextElementPosition - window.innerHeight,
              });
            }, 200);
            setTimeout(() => {
              window.scrollTo({
                top: nextElementPosition - window.innerHeight,
              });
            }, 300);
            setTimeout(() => {
              window.scrollTo({
                top: nextElementPosition - window.innerHeight,
              });
            }, 500);
            setTimeout(() => {
              window.scrollTo({
                top: nextElementPosition - window.innerHeight,
              });
            }, 600);
            setTimeout(() => {
              window.scrollTo({
                top: nextElementPosition - window.innerHeight,
              });
            }, 625);
            setTimeout(() => {
              window.scrollTo({
                top: nextElementPosition - window.innerHeight,
              });
            }, 675);
            setTimeout(() => {
              window.scrollTo({
                top: nextElementPosition - window.innerHeight,
              });
              setLoader(false);
            }, 700);
            setTimeout(() => {
              window.scrollTo({
                top: nextElementPosition - window.innerHeight,
              });
              setLoader(false);
            }, 900);
          }
        } else if (section === "contact") {
          const nextElementPosition =
            document.getElementById("footer")?.offsetTop;
          const goToBottom = document.getElementById("footer");
          goToBottom?.scrollIntoView();
          if (nextElementPosition) {
            setTimeout(() => {
              window.scrollTo({
                top: nextElementPosition - window.innerHeight,
              });
            }, 100);
            setTimeout(() => {
              window.scrollTo({
                top: nextElementPosition - window.innerHeight,
              });
            }, 150);
            setTimeout(() => {
              window.scrollTo({
                top: nextElementPosition - window.innerHeight,
              });
            }, 200);
            setTimeout(() => {
              window.scrollTo({
                top: nextElementPosition - window.innerHeight,
              });
            }, 300);
            setTimeout(() => {
              window.scrollTo({
                top: nextElementPosition - window.innerHeight,
              });
            }, 500);
            setTimeout(() => {
              window.scrollTo({
                top: nextElementPosition - window.innerHeight,
              });
            }, 600);
            setTimeout(() => {
              window.scrollTo({
                top: nextElementPosition - window.innerHeight,
              });
            }, 625);
            setTimeout(() => {
              window.scrollTo({
                top: nextElementPosition - window.innerHeight,
              });
            }, 675);
            setTimeout(() => {
              window.scrollTo({
                top: nextElementPosition - window.innerHeight,
              });
              setLoader(false);
            }, 700);
            setTimeout(() => {
              window.scrollTo({
                top: nextElementPosition - window.innerHeight,
              });
              setLoader(false);
            }, 900);
          }
        }
        setTimeout(() => {
          setSection("home");
        }, 4000);
      }, 500);
    }
  }, [section]); // eslint-disable-line react-hooks/exhaustive-deps
  const { width } = useWindowSize();
  return (
    <>
      {width > 768 && (
        <>
          <Header />
          {/* {loader && <Loader />} */}
          <MiddleContainer
            fade={
              section === "press" ||
              section === "preVerticals" ||
              section === "verticals" ||
              section === "product" ||
              section === "team" ||
              section === "support" ||
              section === "contact"
            }
          >
            <About />
            <Product />
            <Pretank />
            <Tank />
            <Microchip />
            <Concepts />
            <ProductDescription />
            {/* <Countdown /> */}
            <Verticals />
            {/* <WorkWithUs /> */}
            <Team />
            <Support />
            <PressCards />
            <Newsletter />
            <Contact />
            <Footer />
          </MiddleContainer>
          <NewsletterModal />
          <GetInTouchButton />
          <CookieConsent
            location="bottom"
            buttonText="I understand"
            cookieName="myAwesomeCookieName2"
            style={{
              background:
                "linear-gradient(rgb(0 0 0 / 17%) 10%, rgb(46 111 255 / 24%))9%",
              zIndex: 300,
            }}
            buttonStyle={{
              color: "white",
              fontSize: "13px",
              padding: "6px 21px",
              backgroundColor: "#2e6fff",
              fontWeight: 500,
              lineHeight: "24px",
              borderRadius: "4px",
            }}
            expires={150}
          >
            This website uses cookies to enhance the user experience.
          </CookieConsent>
        </>
      )}
      {width && width < 480 && (
        <>
          <HeaderResponsive />
          <MiddleContainer
            fade={
              section === "press" ||
              section === "preVerticals" ||
              section === "verticals" ||
              section === "product" ||
              section === "team" ||
              section === "support" ||
              section === "contact"
            }
          >
            <AboutMobile />
            <ProductMobile />
            <TankMobile />
            <MicrochipMobile />
            <ConceptsMobile />
            <ProductDescriptionMobile />
            <VerticalsMobile />
            {/* <WorkWithUsMobile /> */}
            <TeamMobile />
            <SupportMobile />
            <PressCardsMobile />
            <NewsletterMobile />
            <ContactMobile />
            <FooterResponsive />
          </MiddleContainer>
          <GetInTouchButtonMobile />
          <CookieConsent
            location="bottom"
            buttonText="I understand"
            cookieName="myAwesomeCookieName2"
            style={{
              background:
                "linear-gradient(rgb(0 0 0 / 17%) 10%, rgb(46 111 255 / 24%))9%",
              zIndex: 300,
            }}
            buttonStyle={{
              color: "white",
              fontSize: "13px",
              padding: "6px 21px",
              backgroundColor: "#2e6fff",
              fontWeight: 500,
              lineHeight: "24px",
              borderRadius: "4px",
            }}
            expires={150}
          >
            This website uses cookies to enhance the user experience.
          </CookieConsent>
          <NewsletterModalMobile />
        </>
      )}
    </>
  );
};

export default Home;

const FadeIn = keyframes`
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;
const MiddleContainer = styled.div<{ fade: boolean }>`
  animation: ${(props) => (props.fade ? FadeIn : "none")} 3s ease-in-out;
`;
