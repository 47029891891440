import React, { createContext } from 'react'
import { useLocalStorage } from '../../hooks/useLocalStorage'

export const ThemeContext = createContext()
ThemeContext.displayName = 'ThemeContext'

export const ThemeProvider = (props) => {
  const [theme, setTheme] = useLocalStorage('@theme', 'light')
  const value = [theme, setTheme]

  return (
    <ThemeContext.Provider value={value} {...props}>
      {props.children}
    </ThemeContext.Provider>
  )
}

export const useTheme = () => {
  const context = React.useContext(ThemeContext)

  if (!context) {
    throw new Error('useTheme must be used within a ThemeProvider')
  }

  return context
}
