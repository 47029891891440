import { useDocumentDataOnce } from "react-firebase-hooks/firestore";
import { firestore } from "../../utils/firebase";
import firebase from "firebase/compat/app";
import Toast from "../../utils/toast";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { useNavigate, useParams, } from "react-router-dom";
import { useEffect, useState } from "react";

interface Job {
  position: string;
  description: string;
  area?: string;
  uid?: string;
  updated_at: any;
  created_at?: any;
  location?: string;
}

export default function useJobsForm() {
  const navigate = useNavigate();
  let { uid } = useParams();
  const add = uid === "add";
  const pressRef = firestore.doc("jobs/" + uid);
  const [loadedPress] = useDocumentDataOnce(pressRef);
  const [content, setContent] = useState("");

  const MySwal = withReactContent(Swal);

  useEffect(() => {
    if (loadedPress) {
      setContent(loadedPress.description);
    }
  }, [loadedPress]);

  const submit = async (event: any) => {
    event.preventDefault();
    MySwal.fire({
      title: "Guardando",
      showCancelButton: false,
      allowOutsideClick: false,
    });
    MySwal.showLoading();
    // destructuring form fields into variables. Order matters
    let [
      { value: position },
      { value: location },
    ] = event.target;

    if (!position || !location || !content) {
      Toast.fire({
        icon: "error",
        text: "Por favor complete todos los campos",
      });
      setTimeout(() => {
        MySwal.close();
      }, 2000);
      return;
    }

    const currentTime = firebase.firestore.FieldValue.serverTimestamp();

    const job: Job = {
      position,
      location,
      description: content,
      updated_at: currentTime,
    };

    const collectionRef = firestore.collection("jobs");
    const promises = [];
    try {
      if (add) {
        job.created_at = currentTime;
        const jobSaved = await collectionRef.add(job)
        await jobSaved.update({ uid: jobSaved.id });
      } else {
        promises.push(pressRef.update(job));
      }
      await Promise.all(promises);
      MySwal.close();
      Toast.fire({ icon: "success", title: "Posición guardada correctamente" });
      navigate("/admin/jobs");
    } catch (error) {
      MySwal.close();
      Toast.fire({
        icon: "error",
        title: "Ocurrió un error al guardar la posición",
      });
      console.log("Error", error);
    }
  };

  return {
    add,
    submit,
    loadedPress,
    content,
    setContent
  }
}
