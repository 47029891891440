import Page from "../../commonComponents/Page";
import { Link } from "react-router-dom";
import styled from "styled-components";
import useUsersList from "./../hooks/useUsersList";
import LockResetIcon from "@mui/icons-material/LockReset";
import EditIcon from "@mui/icons-material/Edit";
import PersonOffIcon from "@mui/icons-material/PersonOff";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";

export default function UsersList() {
  const { users, resetPassword, deleteUser, reactivateUser, loading, error } =
    useUsersList();

  return (
    <Page title="Users" icon="users" addLink="users/add">
      <Table>
        <Thead>
          <Tr>
            <Th>Name</Th>
            <Th>E-mail</Th>
            <Th>Active</Th>
            <Th>Actions</Th>
          </Tr>
        </Thead>
        <Tbody>
          {users?.length &&
            users.map((user) => (
              <Tr key={user.uid}>
                <Td>{user.name}</Td>
                <Td>{user.email}</Td>
                <Td>
                  <IconContainer>
                    <Span>
                      {user.active ? (
                        <CheckIcon
                          style={{
                            fill: "#354b79",
                          }}
                        />
                      ) : (
                        <CloseIcon
                          style={{
                            fill: "#354b79",
                          }}
                        />
                      )}
                    </Span>
                  </IconContainer>
                </Td>
                <Td>
                  <IconContainer style={{ gap: ".5rem" }}>
                    <Span
                      onClick={() => {
                        resetPassword(user.email);
                      }}
                    >
                      <LockResetIcon
                        style={{
                          fill: "#354b79",
                        }}
                      />
                    </Span>
                    <Link to={"/admin/users/" + user.uid}>
                      <Span>
                        <EditIcon
                          style={{
                            fill: "#354b79",
                          }}
                        />
                      </Span>
                    </Link>
                    {user.active ? (
                      <Span
                        onClick={() => {
                          deleteUser(user.uid);
                        }}
                      >
                        <PersonOffIcon />
                      </Span>
                    ) : (
                      <Span
                        onClick={() => {
                          reactivateUser(user.uid);
                        }}
                      >
                        <PersonAddIcon
                          style={{
                            fill: "#354b79",
                          }}
                        />
                      </Span>
                    )}
                  </IconContainer>
                </Td>
              </Tr>
            ))}
          {!loading && !users?.length && (
            <Tr>
              <Td>No users found</Td>
            </Tr>
          )}
          {loading && (
            <Tr>
              <Td>Loading users...</Td>
            </Tr>
          )}
          {error && (
            <Tr>
              <Td>An error occurred while loading users</Td>
            </Tr>
          )}
        </Tbody>
      </Table>
    </Page>
  );
}

const Table = styled.table`
  width: 100%;
  color: #354b79;
`;
const Tr = styled.tr`
  color: #354b79;
`;
const Th = styled.th`
  padding: 0.5rem;
  color: #354b79;
`;
const Td = styled.td`
  padding: 0.5rem;
  color: #354b79;
`;
const Thead = styled.thead``;
const Tbody = styled.tbody`
  & ${Tr}:nth-of-type(even) {
    background-color: white;
  }
  & ${Tr}:nth-of-type(odd) {
    background-color: rgba(0, 0, 0, 0.05);
  }
`;
const IconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;
const Span = styled.span`
  color: #354b79;
  svg {
    fill: rgb(53, 75, 121);
  }
  cursor: pointer;
`;
