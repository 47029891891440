import styled from "styled-components";
import { useRef, useLayoutEffect } from "react";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { TitleLarge } from "../../commonComponents/Text";

const ProductDescriptionMobile = () => {
  const mainContainer: any = useRef();
  const firstSceneRef: any = useRef();
  const secondSceneRef: any = useRef();
  const firstTextRef: any = useRef();
  const secondTextRef: any = useRef();
  const thirdTextRef: any = useRef();
  const fourthTextRef: any = useRef();
  const titleRef: any = useRef();
  const topContainerRef: any = useRef();
  useLayoutEffect(() => {
    //progress video with gsap
    gsap.registerPlugin(ScrollTrigger);
    ScrollTrigger.create({
      trigger: mainContainer.current,
      scrub: true,
      start: "top top",
      end: "+=170%",
      id: "pinDescription",
      pinnedContainer: "#description",
      onUpdate: function (self) {
        if (firstTextRef.current) {
          let scrollPos = self.progress;
          if (scrollPos === 0) {
            firstTextRef.current.style.opacity = 1;
            secondTextRef.current.style.opacity = 0.3;
            topContainerRef.current.style.opacity = 1;
          } else if (scrollPos > 0 && scrollPos < 0.2) {
            fadeOutText(firstTextRef, scrollPos);
            fadeInText(secondTextRef, scrollPos);
            slideFirstScene(firstTextRef, scrollPos, 0);
            sliderTopContainer(firstSceneRef, scrollPos);
          } else if (scrollPos >= 0.2 && scrollPos < 0.3) {
          } else if (scrollPos >= 0.3 && scrollPos < 0.4) {
            slideFirstScene(firstTextRef, scrollPos - 0.3, 15);
            fadeOutTopContainer(firstSceneRef, scrollPos - 0.3);
          } else if (scrollPos >= 0.4 && scrollPos < 0.5) {
            slideFirstScene(firstTextRef, scrollPos - 0.3, 15);
            fadeOutTopContainer(firstSceneRef, scrollPos - 0.3);
            fadeInTopContainer(secondSceneRef, scrollPos - 0.4);
          } else if (scrollPos >= 0.5 && scrollPos < 0.6) {
            fadeInTopContainer(secondSceneRef, scrollPos - 0.4);
          } else if (scrollPos >= 0.6 && scrollPos < 0.8) {
            fadeOutText(thirdTextRef, scrollPos - 0.6);
            fadeInText(fourthTextRef, scrollPos - 0.6);
            sliderTopContainer(secondSceneRef, scrollPos - 0.6);
          } else if (scrollPos >= 0.8 && scrollPos < 9) {
            fadeOutTop(topContainerRef, scrollPos - 0.8);
            fadeInTitle(titleRef, scrollPos - 0.8);
          } else if (scrollPos >= 0.9 && scrollPos < 1) {
            fadeInTitle(titleRef, scrollPos - 0.8);
          } else {
            topContainerRef.current.style.opacity = 0;
            firstSceneRef.current.style.opacity = 0.483512;
            secondSceneRef.current.style.opacity = 1;
            firstTextRef.current.style.opacity = 0.3;
            secondTextRef.current.style.opacity = 1;
            thirdTextRef.current.style.opacity = 0.3;
            fourthTextRef.current.style.opacity = 1;
            // titleRef.current.style.opacity = 0;
            // titleRef.current.style.top = "40vh";
          }
          if (scrollPos >= 0.5) {
            firstSceneRef.current.style.top = "40vh";
            firstSceneRef.current.style.opacity = 0;
          }
          if (scrollPos < 0.4) {
            secondSceneRef.current.style.top = "40vh";
            secondSceneRef.current.style.opacity = 0;
          }
          if (scrollPos >= 0.6) {
            secondSceneRef.current.style.top = "40vh";
          }
          if (scrollPos < 0.8) {
            titleRef.current.style.opacity = 0;
            titleRef.current.style.top = "100vh";
          }
        }
      },
    });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  function fadeOutText(textRef: any, progress: number) {
    textRef.current.style.opacity = 1 - progress * 3.6;
  }
  function fadeInText(textRef: any, progress: number) {
    textRef.current.style.opacity = 0.3 + progress * 3.6;
  }
  function slideFirstScene(textRef: any, progress: number, top: number) {
    textRef.current.style.marginTop = `-${top + progress * 75}px`;
  }
  function fadeOutTopContainer(containerRef: any, progress: number) {
    containerRef.current.style.opacity = 1 - progress * 2.6;
  }
  function fadeInTopContainer(containerRef: any, progress: number) {
    containerRef.current.style.opacity = progress * 5.2;
  }
  function sliderTopContainer(containerRef: any, progress: number) {
    containerRef.current.style.marginTop = `${progress * -50}px`;
  }
  function fadeInTitle(titleRef: any, progress: number) {
    titleRef.current.style.opacity = progress * 10;
    titleRef.current.style.top = `${100 - progress * 300}vh`;
  }
  function fadeOutTop(containerRef: any, progress: number) {
    containerRef.current.style.opacity = 1 - progress * 10;
  }

  return (
    <Container ref={mainContainer} id="description">
      <ScrollContainer>
        <TopContainer ref={topContainerRef}>
          <Video
            preload="auto"
            webkit-playsinline="true"
            muted
            src="/background-2.mp4"
            autoPlay
            loop
            playsInline
          />
          <FirstContainer ref={firstSceneRef}>
            <TitleLarge
              isSecondary
              style={{
                textTransform: "uppercase",
                textShadow:
                  "0px 0px 10px rgba(56, 118, 255, 0.60), 0px 0px 4px rgba(56, 118, 255, 0.25)",
                marginBottom: "10px",
              }}
            >
              EMBRACE THE POWER
            </TitleLarge>
            <TitleLarge>
              <span ref={firstTextRef} style={{ opacity: 1 }}>
                Control of every aspect,
                <br /> with just a compact
                <br /> bioreactor and power supply.
              </span>
            </TitleLarge>
            <TitleLarge>
              <span ref={secondTextRef} style={{ opacity: 0.3 }}>
                No more probes, pumps,
                <br /> gas inlets or even autoclaving.
              </span>
            </TitleLarge>
          </FirstContainer>
          <SecondContainer ref={secondSceneRef} style={{ textAlign: "end" }}>
            <TitleLarge
              isSecondary
              style={{
                textTransform: "uppercase",
                textShadow:
                  "0px 0px 10px rgba(56, 118, 255, 0.60), 0px 0px 4px rgba(56, 118, 255, 0.25)",
                marginBottom: "10px",
              }}
            >
              Let us handle the complexity
            </TitleLarge>
            <TitleLarge>
              <span ref={thirdTextRef} style={{ opacity: 1 }}>
                Easy to use with little training, BION is <br />
                continuously learning.
              </span>
            </TitleLarge>
            <TitleLarge>
              <span ref={fourthTextRef} style={{ opacity: 0.3 }}>
                to improve performance.
              </span>
            </TitleLarge>
          </SecondContainer>
        </TopContainer>
        <TitleContainer ref={titleRef}>
          {/* <TitleMedium
            isSecondary
            style={{
              textShadow:
                "0px 0px 15px rgba(56, 118, 255, 0.80), 0px 0px 4px rgba(44, 96, 214, 0.55)",
              textAlign: "center",
              marginBottom: "-700px",
            }}
          >
            Unlocking possibilities through advanced bioreactor technology.
          </TitleMedium> */}
        </TitleContainer>
      </ScrollContainer>
    </Container>
  );
};
const TopContainer = styled.div`
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  min-height: 100vh;
  overflow: hidden;
  position: absolute;
  z-index: 1;
  opacity: 1;
`;
const Video = styled.video`
  position: absolute;
  object-fit: fill;
  z-index: 0;
  top: -9vh;
  left: -118vw;
  width: 100%;
  height: 100vh;
  right: 0;
  margin: auto;
  transform: scale(1.8);
`;
const ScrollContainer = styled.div`
  position: sticky;
  top: 0;
  left: 0;
  width: 100%;
  height: 100px;
  min-height: 100vh;
  overflow: hidden;
`;
const Container = styled.div`
  background: #000307;
  display: flex;
  flex: 1;
  justify-content: start;
  flex-direction: column;
  position: relative;
  width: 100%;
  min-height: 270vh;
  transition: all 0.3s ease-in-out;
`;
const FirstContainer = styled.div`
  position: absolute;
  width: 260px;
  top: 50vh;
  opacity: 1;
  left: 32px;
  right: 0;
  transition: 0.1s all ease;
  margin-top: 0px;
  h5 {
    transition: 0.1s all ease;
  }
`;
const SecondContainer = styled.div`
  position: absolute;
  width: 220px;
  top: 50vh;
  opacity: 0;
  left: 32px;
  transition: 0.1s all ease;
  margin-top: 0px;
  right: 0;
  h5 {
    transition: 0.1s all ease;
  }
`;
const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 100vh;
  width: 100%;
  width: 100%;
  padding: 0 32px;
  transition: 0.1s all ease;
  z-index: 2;
  @media (max-width: 540px) {
    padding: 0 5%;
  }
  h2 {
    text-align: center;
  }
`;
export default ProductDescriptionMobile;
