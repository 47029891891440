import styled from "styled-components";
import logo from "../assets/images/logoHeader.svg";
import { sectionContext } from "../context/sectionSelected";
import { useContext } from "react";
import { useNavigate, useLocation } from "react-router-dom";

export default function Header({
  background = false,
}: {
  background?: boolean;
}) {
  const { setSection } = useContext(sectionContext);
  const navigate = useNavigate();
  const location = useLocation();
  function scrollToSection(nextSection: string) {
    if (nextSection === "home") {
      setTimeout(() => {
        window.scrollTo({
          top: 0,
        });
      }, 100);
      setTimeout(() => {
        window.scrollTo({
          top: 0,
        });
      }, 125);
      setTimeout(() => {
        window.scrollTo({
          top: 0,
        });
      }, 150);
      setTimeout(() => {
        window.scrollTo({
          top: 0,
        });
      }, 175);
      setTimeout(() => {
        window.scrollTo({
          top: 0,
        });
      }, 200);
      setTimeout(() => {
        window.scrollTo({
          top: 0,
        });
      }, 300);
      setTimeout(() => {
        window.scrollTo({
          top: 0,
        });
      }, 350);
      setTimeout(() => {
        window.scrollTo({
          top: 0,
        });
      }, 375);
      setTimeout(() => {
        window.scrollTo({
          top: 0,
        });
      }, 400);
      setTimeout(() => {
        window.scrollTo({
          top: 0,
        });
      }, 425);
      return;
    } else if (nextSection === "preVerticals") {
      const prevElementPosition =
        document.getElementById(nextSection)?.offsetTop;
      if (prevElementPosition) {
        setTimeout(() => {
          window.scrollTo({
            top: prevElementPosition + window.innerHeight,
          });
        }, 100);
        setTimeout(() => {
          window.scrollTo({
            top: prevElementPosition + window.innerHeight,
          });
        }, 125);
        setTimeout(() => {
          window.scrollTo({
            top: prevElementPosition + window.innerHeight,
          });
        }, 150);
        setTimeout(() => {
          window.scrollTo({
            top: prevElementPosition + window.innerHeight,
          });
        }, 175);
        setTimeout(() => {
          window.scrollTo({
            top: prevElementPosition + window.innerHeight,
          });
        }, 100);
        setTimeout(() => {
          window.scrollTo({
            top: prevElementPosition + window.innerHeight,
          });
        }, 200);
        setTimeout(() => {
          window.scrollTo({
            top: prevElementPosition + window.innerHeight,
          });
        }, 300);
        setTimeout(() => {
          window.scrollTo({
            top: prevElementPosition + window.innerHeight,
          });
        }, 400);
      }
      return;
    } else if (nextSection === "footer") {
      const prevElementPosition = document.getElementById("press")?.offsetTop;
      if (prevElementPosition) {
        setTimeout(() => {
          window.scrollTo({
            top: prevElementPosition + window.innerHeight + window.innerHeight,
          });
        }, 100);
        setTimeout(() => {
          window.scrollTo({
            top: prevElementPosition + window.innerHeight + window.innerHeight,
          });
        }, 125);
        setTimeout(() => {
          window.scrollTo({
            top: prevElementPosition + window.innerHeight + window.innerHeight,
          });
        }, 150);
        setTimeout(() => {
          window.scrollTo({
            top: prevElementPosition + window.innerHeight + window.innerHeight,
          });
        }, 175);
        setTimeout(() => {
          window.scrollTo({
            top: prevElementPosition + window.innerHeight + window.innerHeight,
          });
        }, 200);
        setTimeout(() => {
          window.scrollTo({
            top: prevElementPosition + window.innerHeight + window.innerHeight,
          });
        }, 300);
        setTimeout(() => {
          window.scrollTo({
            top: prevElementPosition + window.innerHeight + window.innerHeight,
          });
        }, 400);
      }
      return;
    } else if (nextSection === "product") {
      const prevElementPosition = document.getElementById("product")?.offsetTop;
      if (prevElementPosition) {
        setTimeout(() => {
          window.scrollTo({
            top: prevElementPosition + 500,
          });
        }, 100);
        setTimeout(() => {
          window.scrollTo({
            top: prevElementPosition + 500,
          });
        }, 125);
        setTimeout(() => {
          window.scrollTo({
            top: prevElementPosition + 500,
          });
        }, 150);
        setTimeout(() => {
          window.scrollTo({
            top: prevElementPosition + 500,
          });
        }, 175);
        setTimeout(() => {
          window.scrollTo({
            top: prevElementPosition + 500,
          });
        }, 200);
        setTimeout(() => {
          window.scrollTo({
            top: prevElementPosition + 500,
          });
        }, 300);
        setTimeout(() => {
          window.scrollTo({
            top: prevElementPosition + 500,
          });
        }, 400);
      }
      return;
    }

    const nextElementPosition = document.getElementById(nextSection)?.offsetTop;
    if (nextElementPosition) {
      setTimeout(() => {
        window.scrollTo({
          top: nextElementPosition - window.innerHeight,
        });
      }, 100);
      setTimeout(() => {
        window.scrollTo({
          top: nextElementPosition - window.innerHeight,
        });
      }, 125);
      setTimeout(() => {
        window.scrollTo({
          top: nextElementPosition - window.innerHeight,
        });
      }, 150);
      setTimeout(() => {
        window.scrollTo({
          top: nextElementPosition - window.innerHeight,
        });
      }, 175);
      setTimeout(() => {
        window.scrollTo({
          top: nextElementPosition - window.innerHeight,
        });
      }, 200);
      setTimeout(() => {
        window.scrollTo({
          top: nextElementPosition - window.innerHeight,
        });
      }, 300);
      setTimeout(() => {
        window.scrollTo({
          top: nextElementPosition - window.innerHeight,
        });
      }, 400);
    }
  }
  return (
    <Container background={background}>
      <LogoContainer>
        <Link
          onClick={() => {
            if (location.pathname !== "/") {
              navigate("/");
              setTimeout(() => {
                scrollToSection("home");
              }, 100);
            } else {
              setSection("home");
              scrollToSection("home");
            }
          }}
        >
          <img src={logo} alt="logo" />
        </Link>
      </LogoContainer>
      <LinksContainer>
        <Link
          onClick={() => {
            if (location.pathname !== "/") {
              navigate("/");
              setTimeout(() => {
                scrollToSection("product");
              }, 100);
            } else {
              scrollToSection("product");
            }
          }}
        >
          About
        </Link>

        <Link
          onClick={() => {
            if (location.pathname !== "/") {
              navigate("/");
              setTimeout(() => {
                setSection("verticals");
              }, 100);
            } else {
              setSection("verticals");
            }
          }}
        >
          Industries
        </Link>
        <Link
          onClick={() => {
            if (location.pathname !== "/") {
              navigate("/");
              setTimeout(() => {
                setSection("team");
              }, 100);
            } else {
              setSection("team");
            }
          }}
        >
          Team
        </Link>
        <Link
          onClick={() => {
            if (location.pathname !== "/") {
              navigate("/");
              setTimeout(() => {
                setSection("support");
              }, 100);
            } else {
              setSection("support");
            }
          }}
        >
          Support & Partners
        </Link>
        <Link
          onClick={() => {
            if (location.pathname !== "/") {
              navigate("/");
              setTimeout(() => {
                setSection("press");
              }, 100);
            } else {
              setSection("press");
            }
          }}
        >
          News
        </Link>
        <Link
          onClick={() => {
            if (location.pathname !== "/") {
              navigate("/");
              setTimeout(() => {
                setSection("contact");
              }, 100);
            } else {
              setSection("contact");
            }
          }}
        >
          Contact
        </Link>
        <Link
          className="btn"
          onClick={() => {
            navigate("/joinus");
          }}
        >
          Careers
        </Link>
      </LinksContainer>
    </Container>
  );
}
const Link = styled.a`
  cursor: pointer;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  &.btn {
    padding: 6px 21px;
    background-color: #2e6fff;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    border-radius: 4px;
    @media (min-width: 1500px) {
      font-size: 20px;
      padding: 10px 21px;
    }
    @media (min-width: 1900px) {
      font-size: 24px;
      padding: 14px 21px;
    }
  }
  &.btn:hover {
    color: white;
  }
`;
const Container = styled.div<{ background: boolean }>`
  transition: all 0.3s;
  padding: ${(props) => (props.background ? "32px 66px" : "32px 66px")};
  display: flex;
  justify-content: space-between;
  align-items: center;
  top: 0;
  z-index: 100;
  position: fixed;
  width: 100%;
  overflow: hidden;
  background: ${(props) => (props.background ? "black" : "transparent")};
`;
const LogoContainer = styled.div`
  img {
    width: 180px;
    height: auto;
    @media (min-width: 1500px) {
      width: 200px;
    }
    @media (min-width: 1900px) {
      width: 240px;
    }
  }
`;
const LinksContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 40px;
  align-items: center;
  a {
    font-size: 1rem;
    font-weight: 500;
    color: white;
    text-decoration: none;
    &:hover {
      color: #2e6fff;
    }
    @media (min-width: 1500px) {
      font-size: 20px;
    }
    @media (min-width: 1900px) {
      font-size: 26px;
    }
  }
`;
