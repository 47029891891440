import styled from "styled-components";
import { useRef, useLayoutEffect, useState,useContext } from "react";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { BodyLarge, TitleLarge } from "../../commonComponents/Text";
import { sectionContext } from "../../context/sectionSelected";

const Microchip = () => {
  const chipContainer: any = useRef();
  const videoRef: any = useRef();
  const firstContainer: any = useRef();
  const secondContainer: any = useRef();
  const thirdContainer: any = useRef();
  const firstText: any = useRef();
  const thirdText: any = useRef();
  const fourthText: any = useRef();
  const [loadMetadata, setLoadMetadata] = useState<boolean>(false);
  const { setShowButton } = useContext(sectionContext);
  useLayoutEffect(() => {
    if (loadMetadata) {
      //progress video with gsap
      if (videoRef.current) {
        gsap.registerPlugin(ScrollTrigger);
        ScrollTrigger.create({
          trigger: chipContainer.current,
          scrub: true,
          start: "top top",
          end: "+=200%",
          id: "pinChip",
          pinnedContainer: "#microchip",
          onLeave: function (self) {
            const productContainer = document.getElementById("concepts2");
            if (productContainer) {
              productContainer.scrollIntoView();
            }
          },
          onEnter: function (self) {
            setShowButton(true);
          },
          onUpdate: function (self) {
            if (videoRef.current && videoRef.current.duration) {
              let scrollPos = self.progress;
              let videoDuration = videoRef.current.duration;
              let videoCurrentTime = videoDuration * scrollPos;

              if (videoCurrentTime) {
                videoRef.current.currentTime = videoCurrentTime;
              }
            }
            if (firstContainer.current) {
              let scrollPos = self.progress;
              if (scrollPos === 0) {
                firstContainer.current.style.opacity = 1;
                secondContainer.current.style.top = "100%";
                secondContainer.current.style.opacity = 1;
                thirdContainer.current.style.top = "100%";
                thirdContainer.current.style.opacity = 1;
                videoRef.current.style.opacity = 1;
              } else if (scrollPos > 0 && scrollPos < 0.15) {
                slideFirstText(firstContainer, scrollPos * 45);
                secondContainer.current.style.opacity = 0;
                thirdContainer.current.style.opacity = 0;
              } else if (scrollPos >= 0.15 && scrollPos < 0.25) {
                secondContainer.current.style.opacity = 1;
                firstText.current.style.opacity = 1;
                // secondText.current.style.opacity = 0.3;
                slideFirstText(firstContainer, scrollPos * 45);
                slideSecondText(secondContainer, scrollPos - 0.15);
              } else if (scrollPos >= 0.25 && scrollPos < 0.35) {
                secondContainer.current.style.top = "25%";
              } else if (scrollPos >= 0.35 && scrollPos < 0.45) {
                fadeOutText(firstText, scrollPos - 0.35);
                // fadeInText(secondText, scrollPos - 0.35);
                thirdContainer.current.style.opacity = 0;
              } else if (scrollPos >= 0.45 && scrollPos < 0.5) {
              } else if (scrollPos >= 0.5 && scrollPos < 0.55) {
                fadeOutContainer(secondContainer, scrollPos - 0.5);
                slideSecondText(thirdContainer, scrollPos - 0.5);
                thirdContainer.current.style.opacity = 1;
                thirdText.current.style.opacity = 1;
                fourthText.current.style.opacity = 0.3;
              } else if (scrollPos >= 0.55 && scrollPos < 0.6) {
                slideSecondText(thirdContainer, scrollPos - 0.5);
                thirdContainer.current.style.opacity = 1;
                thirdText.current.style.opacity = 1;
                fourthText.current.style.opacity = 0.3;
                // fifthText.current.style.opacity = 0.3;
              } else if (scrollPos >= 0.6 && scrollPos < 0.7) {
                thirdContainer.current.style.top = "25%";
              } else if (scrollPos >= 0.7 && scrollPos < 0.8) {
                fadeOutText(thirdText, scrollPos - 0.7);
                fadeInText(fourthText, scrollPos - 0.7);
              } else if (scrollPos >= 0.8 && scrollPos < 0.9) {
                fadeOutText(fourthText, scrollPos - 0.8);
                // fadeInText(fifthText, scrollPos - 0.8);
              } else if (scrollPos >= 0.9 && scrollPos < 1) {
                fadeOutContainer(thirdContainer, scrollPos - 0.9);
                fadeOutVideo(videoRef, scrollPos - 0.9);
              } else {
                firstContainer.current.style.opacity = 0;
                secondContainer.current.style.top = "25%";
                secondContainer.current.style.opacity = 0;
                thirdContainer.current.style.top = "25%";
                thirdContainer.current.style.opacity = 0;
              }
              if (scrollPos >= 0.25) {
                firstContainer.current.style.opacity = 0;
              }
              if (scrollPos < 0.15) {
                secondContainer.current.style.top = "100%";
                secondContainer.current.style.opacity = 1;
              }
              if (scrollPos >= 0.25 && scrollPos < 0.5) {
                secondContainer.current.style.top = "25%";
                secondContainer.current.style.opacity = 1;
              }
              if (scrollPos >= 0.55) {
                secondContainer.current.style.top = "25%";
                secondContainer.current.style.opacity = 0;
              }
              if (scrollPos < 0.5) {
                thirdContainer.current.style.top = "100%";
                thirdContainer.current.style.opacity = 1;
              }
              if (scrollPos >= 0.6 && scrollPos < 0.85) {
                thirdContainer.current.style.top = "25%";
                thirdContainer.current.style.opacity = 1;
              }
              if (scrollPos < 0.85) {
                videoRef.current.style.opacity = 1;
              }
            }
          },
        });
      }
    }
  }, [loadMetadata]); // eslint-disable-line react-hooks/exhaustive-deps

  function slideFirstText(firstText: any, progress: number) {
    firstText.current.style.opacity = 1 - 0.1 * progress;
  }

  function slideSecondText(secondContainer: any, progress: number) {
    secondContainer.current.style.top = `${100 - progress * 750}%`;
  }
  function fadeOutText(firstText: any, progress: number) {
    firstText.current.style.opacity = 1 - progress * 7.1;
  }

  function fadeInText(secondText: any, progress: number) {
    secondText.current.style.opacity = 0.3 + progress * 7.1;
  }
  function fadeOutVideo(videoRef: any, progress: number) {
    videoRef.current.style.opacity = 1 - progress * 10;
  }
  function fadeOutContainer(firstContainer: any, progress: number) {
    firstContainer.current.style.opacity = 1 - progress * 20.5;
  }

  return (
    <>
      <Container ref={chipContainer} id="microchip">
        <ScrollContainer>
          <ShadowContainer />
          <VideoContainer>
            <Video
              ref={videoRef}
              preload="auto"
              webkit-playsinline="true"
              muted
              src="/chip.mp4"
              onLoadedMetadata={() => setLoadMetadata(true)}
            />
            <Shadow />
          </VideoContainer>
          <FirstContainer ref={firstContainer}>
            <TitleLarge>
              Accelerate your bioprocesses
              <br /> with our intelligent bioreactor, integrating cutting-edge
              hardware and software for AI-driven process optimization.
            </TitleLarge>
          </FirstContainer>
          <SecondContainer
            ref={secondContainer}
            style={{
              marginTop: "100px",
            }}
          >
            <TitleLarge
              isSecondary
              style={{
                textTransform: "uppercase",
                textShadow:
                  "0px 0px 10px rgba(56, 118, 255, 0.60), 0px 0px 4px rgba(56, 118, 255, 0.25)",
                marginBottom: "16px",
              }}
            >
              Hardware
            </TitleLarge>
            <BodyLarge>
              <span ref={firstText}>
                We create robust and reliable bioreactor
                <br /> hardware with state-of-the-art tech
                <br /> offering scalability for your specific needs
                <br /> through biotech and hardware expertise.
              </span>{" "}
            </BodyLarge>
          </SecondContainer>
          <SecondContainer
            ref={thirdContainer}
            style={{
              marginTop: "100px",
            }}
          >
            <TitleLarge
              isSecondary
              style={{
                textTransform: "uppercase",
                textShadow:
                  "0px 0px 10px rgba(56, 118, 255, 0.60), 0px 0px 4px rgba(56, 118, 255, 0.25)",
                marginBottom: "16px",
              }}
            >
              Software
            </TitleLarge>
            <BodyLarge>
              <span ref={thirdText}>
                Our operating system enables precise control
                <br /> and data analysis.
              </span>{" "}
              <span ref={fourthText}>
                Streamline bioreactor
                <br /> data management, and analysis with
                <br /> our intuitive software, featuring secure data handling
              </span>{" "}
            </BodyLarge>
          </SecondContainer>
        </ScrollContainer>
      </Container>
    </>
  );
};
const ScrollContainer = styled.div`
  position: sticky;
  top: 0;
  left: 0;
  width: 100%;
  height: 100px;
  min-height: 100vh;
`;
const Container = styled.div`
  display: flex;
  flex: 1;
  justify-content: start;
  flex-direction: column;
  position: relative;
  width: 100%;
  min-height: 300vh;
  background: #000407;
`;
const Shadow = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  box-shadow: inset -10px 0px 80px 66px #000407;
`;
const ShadowContainer = styled.div`
  position: absolute;
  width: 100%;
  height: 100vh;
  background: linear-gradient(
    180deg,
    #ffffff00 0%,
    #ffffff00 60%,
    #000307c7 87%,
    #000307 100%
  );
`;
const VideoContainer = styled.div`
  position: absolute;
  width: 40vw;
  height: 100%;
  max-width: 50%;
  max-height: 50%;
  overflow: hidden;
  z-index: -1;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  transform: translateX(15vw) translateY(8vh);
`;

const Video = styled.video`
  position: absolute;
  object-fit: cover;
  z-index: -1;
  width: 100%;
  height: auto;
  margin: auto;
  border-radius: 0 40% 40% 0;
`;

const SecondContainer = styled.div`
  position: absolute;
  width: 594px;
  top: 100%;
  opacity: 1;
  transition: all 0.1s ease;
  left: 0;
  right: 0;
  margin: auto;
  transform: translateX(-18vw);
  h5,
  p {
    transition: all 0.1s ease;
  }
  @media (min-width: 1500px) {
    width: 740px;
  }
  @media (min-width: 1900px) {
    width: 880px;
  }
`;

const FirstContainer = styled.div`
  position: absolute;
  width: 531px;
  height: 200px;
  line-height: 2.5rem;
  opacity: 1;
  -webkit-transition: all 0.1s ease;
  transition: all 0.1s ease;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  transform: translateX(-20vw) translateY(-10vh);
  h5,
  p {
    transition: all 0.1s ease;
  }
  @media (min-width: 1500px) {
    width: 560px;
  }
  @media (min-width: 1900px) {
    width: 800px;
  }
`;

export default Microchip;
