import { useCollectionData } from "react-firebase-hooks/firestore";
import { firestore } from "../../utils/firebase";
import Toast from "../../utils/toast";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

export default function usePressList() {
  const pressRef = firestore.collection("press");
  const [press, loading, error] = useCollectionData(pressRef);
  const MySwal = withReactContent(Swal);

  async function handleDelete(uid: string) {
    const swalResponse = await MySwal.fire({
      title: "Eliminar registro",
      text: "Está seguro de querer eliminar este registro?",
      showConfirmButton: true,
      showCancelButton: true,
      showLoaderOnConfirm: true,
      backdrop: true,
      preConfirm: async () => {
        await pressRef.doc(uid).delete();
      },
      allowOutsideClick: () => !MySwal.isLoading(),
    });
    if (swalResponse.isConfirmed) {
      Toast.fire({ icon: "success", title: "Registro eliminado con éxito" });
    }
  }

  return {
    press,
    loading,
    error,
    handleDelete
  };
}
