import { useDocumentDataOnce } from "react-firebase-hooks/firestore";
import { firestore, storageRef, } from "../../utils/firebase";
import firebase from "firebase/compat/app";
import Toast from "../../utils/toast";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { useNavigate, useParams } from "react-router-dom";
import { useState } from "react";

interface SupportOrPartnership {
  name: string;
  type: string;
  imgURl?: string;
  uid?: string;
  updated_at: any;
  created_at?: any;
}

export default function useSupportForm() {
  const navigate = useNavigate();
  let { uid } = useParams();
  const add = uid === "add";
  const supportRef = firestore.doc("support/" + uid);
  const [loadedPress] = useDocumentDataOnce(supportRef);
  const [newImage, setNewImage] = useState<any>();
  const onImageUpload = (file: any) => {
    setNewImage(file);
  };
  const MySwal = withReactContent(Swal);


  const submit = async (event: any) => {
    event.preventDefault();
    MySwal.fire({
      title: "Guardando",
      showCancelButton: false,
      allowOutsideClick: false,
    });
    MySwal.showLoading();
    // destructuring form fields into variables. Order matters
    let [
      { value: name },
      { value: type },
    ] = event.target;

    if (!name || !type || !newImage) {
      Toast.fire({
        icon: "error",
        text: "Por favor complete todos los campos y carga una imagen",
      });
      setTimeout(() => {
        MySwal.close();
      }, 2000);
      return;
    }

    const currentTime = firebase.firestore.FieldValue.serverTimestamp();

    const suppOrPart: SupportOrPartnership = {
      name,
      type,
      updated_at: currentTime,
    };

    const collectionRef = firestore.collection("support");
    const promises = [];

    try {
      let newId;
      if (add) {
        suppOrPart.created_at = currentTime;
        const supportSaved = await collectionRef.add(suppOrPart)
        newId = supportSaved.id;
        await supportSaved.update({ uid: supportSaved.id });
        if (newImage) {
          const extension = newImage.name.split(".").pop();
          const imageName = newId ? newId + "." + extension : uid + "." + extension;
          const imageRef = storageRef.child("support/" + imageName);
          await imageRef.delete().catch(() => { }); // deletes prev image if exists
          await imageRef.put(newImage); // upload new image
          const newImageUrl = await imageRef.getDownloadURL();
          await supportSaved.update({ imageURL: newImageUrl });
        }
      } else {
        promises.push(supportRef.update(suppOrPart));
        await Promise.all(promises);
        if (newImage) {
          const extension = newImage.name.split(".").pop();
          const imageName = newId ? newId + "." + extension : uid + "." + extension;
          const imageRef = storageRef.child("support/" + imageName);
          await imageRef.delete().catch(() => { }); // deletes prev image if exists
          await imageRef.put(newImage); // upload new image
          const newImageUrl = await imageRef.getDownloadURL();
          await supportRef.update({ imageURL: newImageUrl });
        }
      }

      MySwal.close();
      Toast.fire({ icon: "success", title: "Support or Partnership guardado correctamente" });
      navigate("/admin/supports-partners");
    } catch (error) {
      MySwal.close();
      Toast.fire({
        icon: "error",
        title: `${error}`,
      });
      console.log("Error", error);
    }
  };

  return {
    add,
    submit,
    loadedPress,
    onImageUpload
  }
}
