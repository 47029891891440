import { useLayoutEffect, useContext, useState } from "react";
import Header from "../components/Header";
import { sectionContext } from "../context/sectionSelected";
import styled, { keyframes } from "styled-components";
import Chevron from "../assets/images/chevron.svg";
import CloseModalIcon from "../assets/images/closeModalIcon.svg";
import LinkIcon from "../assets/images/linkIcon.svg";
import {
  BodyLarge,
  BodyMedium,
  Heading2,
  TitleMedium,
  TitleSmall,
} from "../commonComponents/Text";
import useWindowSize from "../hooks/useWindowSize";
import HeaderResponsive from "../components/HeaderResponsive";
import FooterResponsive from "../components/FooterResponsive";
import Footer from "../components/Footer";
import BackIcon from "../assets/images/backIcon.svg";
import { useNavigate } from "react-router-dom";
import { useCollectionData } from "react-firebase-hooks/firestore";
import { firestore } from "../utils/firebase";
export interface PressArticle {
  id: number;
  date: string;
  title: string;
  description?: string;
  link?: string;
  content?: string;
}

const Press = () => {
  const { width } = useWindowSize();
  const { section, setSection } = useContext(sectionContext);
  const [cardSelected, setCardSelected] = useState<number>(0);
  const [fade, setFade] = useState<boolean>(false);
  const navigate = useNavigate();
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, [section]);
  const pressFierstoreRef = firestore.collection("press").orderBy("date", "desc");;
  const [pressCards, ,] = useCollectionData(pressFierstoreRef);

  return (
    <>
      {width > 768 && (
        <>
          <Header />
          <MiddleContainer fade={fade}>
            <TitleContainer>
              <BackIconImg
                src={BackIcon}
                onClick={() => {
                  setSection("press");
                  setFade(true);
                  setTimeout(() => {
                    navigate("/");
                  }, 500);
                }}
              />
              <Heading2
                isSecondary
                style={{
                  textTransform: "uppercase",
                  textShadow:
                    "0px 0px 10px rgba(56, 118, 255, 0.60), 0px 0px 4px rgba(56, 118, 255, 0.25)",
                }}
              >
                News
              </Heading2>
            </TitleContainer>
            <CenterContainer>
              <CardsContainer>
                {pressCards &&
                  pressCards.map((pressArticle, index) => (
                    <Card>
                      <TitleMedium
                        style={{
                          textTransform: "uppercase",
                          textAlign: "start",
                          fontSize: "18px",
                          width: "100%",
                        }}
                        isSecondary
                      >
                        {pressArticle.title.length > 80
                          ? pressArticle.title.slice(0, 80) + "..."
                          : pressArticle.title}
                      </TitleMedium>
                      <TitleSmall
                        style={{
                          textTransform: "uppercase",
                          textAlign: "start",
                          width: "100%",
                          fontSize: "16px",
                          lineHeight: "20px",
                        }}
                      >
                        {pressArticle.date}
                      </TitleSmall>
                      {pressArticle.description && (
                        <BodyLarge
                          style={{
                            textAlign: "start",
                            width: "100%",
                            marginTop: "10px",
                          }}
                        >
                          {pressArticle.description.length < 40
                            ? pressArticle.description
                            : pressArticle.description.slice(0, 40) + "..."}
                        </BodyLarge>
                      )}
                      <BottomContainer>
                        {pressArticle.content ? (
                          <ReadMoreText
                            onClick={() => setCardSelected(index + 1)}
                          >
                            <p>Read more</p>
                            <img src={Chevron} alt="chevron" />
                          </ReadMoreText>
                        ) : (
                          <ReadMoreText
                            href={pressArticle.link}
                            target="_blank"
                          >
                            <p>Read more</p>
                            <img src={Chevron} alt="chevron" />
                          </ReadMoreText>
                        )}
                      </BottomContainer>
                    </Card>
                  ))}
              </CardsContainer>
            </CenterContainer>
            {cardSelected !== 0 && (
              <ModalContainer>
                <BackgroundModal
                  onClick={() => setCardSelected(0)}
                  className={cardSelected !== 0 ? "show" : ""}
                ></BackgroundModal>
                <CardModal>
                  <CardBottomContainer>
                    <div>
                      <Heading2
                        isSecondary
                        style={{
                          textTransform: "uppercase",
                          textAlign: "start",
                          width: "100%",
                        }}
                      >
                        {pressCards && pressCards[cardSelected - 1].title}{" "}
                      </Heading2>
                      <BodyMedium
                        style={{
                          textAlign: "start",
                          width: "100%",
                          textTransform: "uppercase",
                        }}
                      >
                        {pressCards && pressCards[cardSelected - 1].date}
                      </BodyMedium>
                    </div>

                    <HtmlContainer
                      dangerouslySetInnerHTML={
                        pressCards && {
                          __html: pressCards[cardSelected - 1].content || "",
                        }
                      }
                    ></HtmlContainer>
                    {pressCards && pressCards[cardSelected - 1].link && (
                      <LinkContainer>
                        <a
                          href={pressCards && pressCards[cardSelected - 1].link}
                          target="_blank"
                          rel="noreferrer"
                        >
                          <BodyMedium isSecondary>View note</BodyMedium>
                          <img src={LinkIcon} alt="Link Icon" />
                        </a>
                      </LinkContainer>
                    )}
                  </CardBottomContainer>
                  <CloseModal
                    src={CloseModalIcon}
                    onClick={() => setCardSelected(0)}
                  />
                </CardModal>
              </ModalContainer>
            )}
          </MiddleContainer>
          <Footer />
        </>
      )}
      {width < 480 && (
        <>
          <HeaderResponsive />
          <TitleContainer>
            <BackIconImg
              src={BackIcon}
              onClick={() => {
                setSection("press");
                setFade(true);
                setTimeout(() => {
                  navigate("/");
                }, 500);
              }}
            />
            <TitleMedium
              isSecondary
              style={{
                textTransform: "uppercase",
                textShadow:
                  "0px 0px 10px rgba(56, 118, 255, 0.60), 0px 0px 4px rgba(56, 118, 255, 0.25)",
              }}
            >
              News
            </TitleMedium>
          </TitleContainer>
          <CenterContainer>
            <CardsContainer>
              {pressCards &&
                pressCards.map((pressArticle, index) => (
                  <Card>
                    <TitleMedium
                      style={{
                        textTransform: "uppercase",
                        textAlign: "start",
                        fontSize: "18px",
                        width: "100%",
                      }}
                      isSecondary
                    >
                      {pressArticle.title.length > 80
                        ? pressArticle.title.slice(0, 80) + "..."
                        : pressArticle.title}
                    </TitleMedium>
                    <TitleSmall
                      style={{
                        textTransform: "uppercase",
                        textAlign: "start",
                        width: "100%",
                        lineHeight: "20px",
                        fontSize: "16px",
                      }}
                    >
                      {pressArticle.date}
                    </TitleSmall>
                    {pressArticle.description && (
                      <BodyLarge
                        style={{
                          textAlign: "start",
                          width: "100%",
                          marginTop: "10px",
                        }}
                      >
                        {pressArticle.description.length < 40
                          ? pressArticle.description
                          : pressArticle.description.slice(0, 40) + "..."}
                      </BodyLarge>
                    )}
                    <BottomContainer>
                      {pressArticle.content ? (
                        <ReadMoreText
                          onClick={() => setCardSelected(index + 1)}
                        >
                          <p>Read more</p>
                          <img src={Chevron} alt="chevron" />
                        </ReadMoreText>
                      ) : (
                        <ReadMoreText href={pressArticle.link} target="_blank">
                          <p>Read more</p>
                          <img src={Chevron} alt="chevron" />
                        </ReadMoreText>
                      )}
                    </BottomContainer>
                  </Card>
                ))}
            </CardsContainer>
          </CenterContainer>
          {cardSelected !== 0 && (
            <ModalContainer>
              <BackgroundModal
                onClick={() => setCardSelected(0)}
                className={cardSelected !== 0 ? "show" : ""}
              ></BackgroundModal>
              <CardModal>
                <CardBottomContainer>
                  <div>
                    <TitleMedium
                      isSecondary
                      style={{
                        textTransform: "uppercase",
                        textAlign: "start",
                        width: "100%",
                      }}
                    >
                      {pressCards && pressCards[cardSelected - 1].title}{" "}
                    </TitleMedium>
                    <BodyMedium
                      style={{
                        textAlign: "start",
                        width: "100%",
                        textTransform: "uppercase",
                      }}
                    >
                      {pressCards && pressCards[cardSelected - 1].date}
                    </BodyMedium>
                  </div>

                  <HtmlContainer
                    dangerouslySetInnerHTML={
                      pressCards && {
                        __html: pressCards[cardSelected - 1].content || "",
                      }
                    }
                  ></HtmlContainer>
                  {pressCards && pressCards[cardSelected - 1].link && (
                    <LinkContainer>
                      <a
                        href={pressCards && pressCards[cardSelected - 1].link}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <BodyMedium
                          style={{
                            fontSize: "16px",
                            lineHeight: "24px",
                          }}
                          isSecondary
                        >
                          View note
                        </BodyMedium>
                        <img
                          style={{
                            width: "16px",
                            height: "16px",
                          }}
                          src={LinkIcon}
                          alt="Link Icon"
                        />
                      </a>
                    </LinkContainer>
                  )}
                </CardBottomContainer>
                <CloseModal
                  src={CloseModalIcon}
                  onClick={() => setCardSelected(0)}
                />
              </CardModal>
            </ModalContainer>
          )}
          <FooterResponsive />
        </>
      )}
    </>
  );
};
const FadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;
const FadeOut = keyframes`
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
`;
const MiddleContainer = styled.div<{ fade: boolean }>`
  animation: ${(props) => (props.fade ? FadeOut : FadeIn)} 0.5s ease-in-out;
`;
const TitleContainer = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: center;
  position: relative;
  top: 0;
  left: 0;
  right: 0;
  margin: auto;
  z-index: 1;
  height: 25vh;
  opacity: 1;
  transition: all 0.1s ease;
  animation: ${FadeIn} 0.5s ease-in-out;
  @media (max-width: 500px) {
    img {
      width: 20px;
      height: 20px;
      position: absolute;
      left: 10vh;
      bottom: 10px;
    }
  }
`;
const BackIconImg = styled.img`
  width: 24px;
  height: 24px;
  position: absolute;
  left: 10vh;
  bottom: 12px;
  margin: auto;
  transform: rotate(180deg);
  cursor: pointer;
`;
const CenterContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 100%;
  height: 100%;
  animation: ${FadeIn} 0.5s ease-in-out;
  margin-bottom: 32px;
  min-height: 80vh;
`;
const CardsContainer = styled.div`
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fit, 360px);
  grid-template-rows: auto;
  align-items: center;
  justify-content: center;
  place-items: center;
  gap: 32px;
  flex-wrap: wrap;
  margin-top: 32px;
  max-width: 1200px;
`;
const LinkContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  a {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 20px;
    cursor: pointer;
    text-decoration: none;
    p {
      color: var(--primario, #3876ff);
      font-family: Montserrat;
      font-size: 24px;
      font-style: normal;
      font-weight: 600;
      line-height: 40px;
    }
  }
`;
const ModalContainer = styled.div`
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 101;
  display: flex;
  justify-content: center;
  align-items: center;
  animation: ${FadeIn} 0.5s ease-in-out;
  top: 0;
  left: 0;
`;
const CloseModal = styled.img`
  width: 24px;
  height: 24px;
  position: absolute;
  top: 30px;
  right: 30px;
  cursor: pointer;
`;
const BackgroundModal = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  background: black;
  opacity: 0;
  z-index: -1;
  transition: all 0.3s ease-in-out;
  &.show {
    opacity: 0.5;
    z-index: 101;
  }
`;
const CardModal = styled.div`
  width: 95vw;
  height: 500px;
  border-radius: 10px;
  border: 2px solid var(--primario, #3876ff);
  display: flex;
  flex-direction: column;
  z-index: 102;
  background: #000407;
  position: relative;
`;
const CardBottomContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 40px 60px;
  border-radius: 10px;
  background: linear-gradient(135deg, rgba(15, 22, 33, 0.3) 0%, #0f1621 100%);
  backdrop-filter: blur(5px);
  @media (max-width: 500px) {
    padding: 16px;
  }
`;
const Card = styled.div`
  display: flex;
  flex: 1;
  justify-content: start;
  align-items: center;
  flex-direction: column;
  width: 360px;
  height: 400px;
  padding: 36px 24px;
  background: linear-gradient(
    162deg,
    rgba(255, 254, 254, 0.13) 13.02%,
    rgba(255, 254, 254, 0) 100%
  );
  border: 2px solid #353738;
  box-shadow: 0px 4px 5px 0px #000;
  border-radius: 24px;
  p {
    @media (min-width: 1900px) {
      font-size: 28px;
    }
  }
`;
const ReadMoreText = styled.a`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  gap: 16px;
  text-decoration: none;
  cursor: pointer;
  p {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    text-align: right;
    color: #dfdfdf;
    text-align: start;
  }
`;
const BottomContainer = styled.div`
  width: 100%;
  display: flex;
  height: 100%;
  justify-content: flex-end;
  align-items: flex-end;
`;
const HtmlContainer = styled.div`
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  ::-webkit-scrollbar {
    width: 10px;
  }
  ::-webkit-scrollbar-track {
    background: rgba(150, 150, 150, 0.1);
  }
  ::-webkit-scrollbar-thumb {
    background: #5b8bf4;
    height: 40px;
    border-radius: 10px;
  }
  ::-webkit-scrollbar-thumb:hover {
    background: #2e3fff;
  }
  p {
    font-family: Montserrat;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    text-align: start;
    color: #dfdfdf;
  }
  a {
    color: #dfdfdf;
    text-decoration: underline;
  }
  img {
    width: 100%;
    height: auto;
  }
  ul {
    list-style-type: none;
    padding-left: 0;
    li {
      font-family: Montserrat;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 24px;
      text-align: start;
      color: #dfdfdf;
      margin-bottom: 10px;
      &:before {
        content: "•";
        color: #dfdfdf;
        display: inline-block;
        width: 1em;
        margin-left: -1em;
      }
    }
  }
  * {
    color: #dfdfdf;
  }
`;

export default Press;
