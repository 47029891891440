import { Link } from "react-router-dom";
import styled from "styled-components";
import Page from "../../commonComponents/Page";
import Button from "../../commonComponents/Button";
import usePressForm from "../hooks/usePressForm";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

export default function PressForm() {
  const {
    add,
    submit,
    loadedPress,
    content,
    setContent,
    withoutLink,
    setWithoutLink,
  } = usePressForm();
  return (
    <Page
      title={(add ? "Create" : "Edit") + " new"}
      icon="press"
      isHalf={false}
    >
      <ColumnsForm onSubmit={submit}>
        <Row>
          <FieldsContainer style={{
            width:"300px"
          }}>
            <Label>Date</Label>
            <Input
              type="date"
              name="date"
              placeholder="Date"
              defaultValue={loadedPress?.date}
            />
          </FieldsContainer>
          <FieldsContainer style={{
            width:"300px"
          }}>
            <Label>Title</Label>
            <Input
              type="text"
              name="title"
              placeholder="Title"
              defaultValue={loadedPress?.title}
            />
          </FieldsContainer>
        </Row>
        {!withoutLink && (
          <FieldsContainer>
            <Label>Description</Label>
            <Textarea
              name="description"
              placeholder="Description"
              defaultValue={loadedPress?.description}
            />
          </FieldsContainer>
        )}
        {!withoutLink && (
          <FieldsContainer>
            <Label>Link</Label>
            <Input
              type="text"
              name="link"
              placeholder="Link"
              defaultValue={loadedPress?.link}
            />
          </FieldsContainer>
        )}
        <FieldsContainer>
          <CheckBoxContainer>
            <input
              type="checkbox"
              name="withoutLink"
              checked={withoutLink}
              onChange={() => setWithoutLink(!withoutLink)}
            />
            <Label>Customize Content</Label>
          </CheckBoxContainer>
        </FieldsContainer>
        {withoutLink && (
          <FieldsContainer>
            <Label>Content</Label>
            <ReactQuill theme="snow" value={content} onChange={setContent} scrollingContainer={
              ".ql-editor"
            }/>
          </FieldsContainer>
        )}

        <ButtonsContainer style={{ gap: "1rem" }}>
          <Button type={"primary"}>Save</Button>
          <Link to="/admin/press">
            <Button type={"secondary"}>Back</Button>
          </Link>
        </ButtonsContainer>
      </ColumnsForm>
    </Page>
  );
}
const Row = styled.div`
  display: flex;
  gap: 1rem;
  width: 100%;
  justify-content: space-between;
`;
const ColumnsForm = styled.form`
  gap: 1rem;
`;
const FieldsContainer = styled.div`
  margin: 8px 0;
  .ql-editor{
    min-height: 200px;
  }
  .title{
    width: 100%;
  }
`;
const Label = styled.label`
  display: block;
  margin-bottom: 0.25rem;
  font-size: 0.75rem;
  color: #354b79;
`;
const Input = styled.input`
  &:not([type="checkbox"]) {
    padding: 0.5rem;
    width: 100%;
  }
  color: #354b79;
`;
const ButtonsContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  grid-column: 1/-1;
  margin-top: 16px;
`;
const Textarea = styled.textarea`
  padding: 0.5rem;
  width: 100%;
  color: #354b79;
`;
const CheckBoxContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
`;
