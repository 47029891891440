import styled from "styled-components";
import { useRef, useLayoutEffect } from "react";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { BodyMedium, TitleLarge } from "../../commonComponents/Text";

const MicrochipMobile = () => {
  const chipContainer: any = useRef();
  const firstContainer: any = useRef();
  const secondContainer: any = useRef();
  const thirdContainer: any = useRef();
  const firstText: any = useRef();
  const thirdText: any = useRef();
  const fourthText: any = useRef();
  useLayoutEffect(() => {
    //progress video with gsap
    gsap.registerPlugin(ScrollTrigger);
    ScrollTrigger.create({
      trigger: chipContainer.current,
      scrub: true,
      start: "top top",
      end: "+=350%",
      id: "pinChip",
      pinnedContainer: "#microchip",
      onLeave: function (self) {
        const productContainer = document.getElementById("concepts3");
        if (productContainer) {
          productContainer.scrollIntoView();
        }
      },
      onUpdate: function (self) {
        if (firstContainer.current) {
          let scrollPos = self.progress;
          if (scrollPos === 0) {
            firstContainer.current.style.opacity = 1;
            secondContainer.current.style.top = "100%";
            secondContainer.current.style.opacity = 1;
            thirdContainer.current.style.top = "100%";
            thirdContainer.current.style.opacity = 1;
          } else if (scrollPos > 0 && scrollPos < 0.15) {
            slideFirstText(firstContainer, scrollPos * 45);
            secondContainer.current.style.opacity = 0;
            thirdContainer.current.style.opacity = 0;
          } else if (scrollPos >= 0.15 && scrollPos < 0.25) {
            secondContainer.current.style.opacity = 1;
            firstText.current.style.opacity = 1;
            slideFirstText(firstContainer, scrollPos * 45);
            slideSecondText(secondContainer, scrollPos - 0.15);
          } else if (scrollPos >= 0.25 && scrollPos < 0.35) {
            secondContainer.current.style.top = "43%";
          } else if (scrollPos >= 0.35 && scrollPos < 0.45) {
            fadeOutText(firstText, scrollPos - 0.35);
            thirdContainer.current.style.opacity = 0;
          } else if (scrollPos >= 0.45 && scrollPos < 0.5) {
          } else if (scrollPos >= 0.5 && scrollPos < 0.55) {
            fadeOutContainer(secondContainer, scrollPos - 0.5);
            slideSecondText(thirdContainer, scrollPos - 0.5);
            thirdContainer.current.style.opacity = 1;
            thirdText.current.style.opacity = 1;
            fourthText.current.style.opacity = 0.3;
          } else if (scrollPos >= 0.55 && scrollPos < 0.6) {
            slideSecondText(thirdContainer, scrollPos - 0.5);
            thirdContainer.current.style.opacity = 1;
            thirdText.current.style.opacity = 1;
            fourthText.current.style.opacity = 0.3;
          } else if (scrollPos >= 0.6 && scrollPos < 0.7) {
            thirdContainer.current.style.top = "43%";
          } else if (scrollPos >= 0.7 && scrollPos < 0.8) {
            fadeOutText(thirdText, scrollPos - 0.7);
            fadeInText(fourthText, scrollPos - 0.7);
          } else if (scrollPos >= 0.8 && scrollPos < 0.85) {
          } else if (scrollPos >= 0.85 && scrollPos < 1) {
            fadeOutContainer(thirdContainer, scrollPos - 0.85);
          } else {
            firstContainer.current.style.opacity = 0;
            secondContainer.current.style.top = "43%";
            secondContainer.current.style.opacity = 0;
            thirdContainer.current.style.top = "43%";
            thirdContainer.current.style.opacity = 0;
          }
          if (scrollPos >= 0.25) {
            firstContainer.current.style.opacity = 0;
          }
          if (scrollPos < 0.15) {
            secondContainer.current.style.top = "100%";
            secondContainer.current.style.opacity = 1;
          }
          if (scrollPos >= 0.25 && scrollPos < 0.5) {
            secondContainer.current.style.top = "43%";
            secondContainer.current.style.opacity = 1;
          }
          if (scrollPos >= 0.55) {
            secondContainer.current.style.top = "43%";
            secondContainer.current.style.opacity = 0;
          }
          if (scrollPos < 0.5) {
            thirdContainer.current.style.top = "100%";
            thirdContainer.current.style.opacity = 1;
          }
          if (scrollPos >= 0.6 && scrollPos < 0.85) {
            thirdContainer.current.style.top = "43%";
            thirdContainer.current.style.opacity = 1;
          }
        }
      },
    });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  function slideFirstText(firstText: any, progress: number) {
    firstText.current.style.opacity = 1 - 0.1 * progress;
  }

  function slideSecondText(secondContainer: any, progress: number) {
    secondContainer.current.style.top = `${100 - progress * 570}%`;
  }
  function fadeOutText(firstText: any, progress: number) {
    firstText.current.style.opacity = 1 - progress * 7.1;
  }

  function fadeInText(secondText: any, progress: number) {
    secondText.current.style.opacity = 0.3 + progress * 7.1;
  }
  function fadeOutContainer(firstContainer: any, progress: number) {
    firstContainer.current.style.opacity = 1 - progress * 20.5;
  }

  return (
    <>
      <Container ref={chipContainer} id="microchip">
        <ScrollContainer>
          <VideoContainer>
            <Video
              preload="auto"
              webkit-playsinline="true"
              muted
              src="/chip.mp4"
              autoPlay
              loop
              playsInline
            />
            <Shadow />
            <Shadow />
          </VideoContainer>
          <FirstContainer ref={firstContainer}>
            <BodyMedium>
              Accelerate your bioprocesses
              <br /> with our intelligent bioreactor, integrating cutting-edge
              hardware and software for AI-driven process optimization.
            </BodyMedium>
          </FirstContainer>
          <SecondContainer ref={secondContainer}>
            <TitleLarge
              isSecondary
              style={{
                textTransform: "uppercase",
                textShadow:
                  "0px 0px 10px rgba(56, 118, 255, 0.60), 0px 0px 4px rgba(56, 118, 255, 0.25)",
                marginBottom: "12px",
              }}
            >
              Hardware
            </TitleLarge>
            <BodyMedium>
              <span ref={firstText}>
                We create robust and reliable bioreactor
                <br /> hardware with state-of-the-art tech
                <br /> offering scalability for your specific needs
                <br /> through biotech and hardware expertise.
              </span>{" "}
            </BodyMedium>
          </SecondContainer>
          <SecondContainer ref={thirdContainer}>
            <TitleLarge
              isSecondary
              style={{
                textTransform: "uppercase",
                textShadow:
                  "0px 0px 10px rgba(56, 118, 255, 0.60), 0px 0px 4px rgba(56, 118, 255, 0.25)",
                marginBottom: "12px",
              }}
            >
              Software
            </TitleLarge>
            <BodyMedium
              style={{
                marginBottom: "-400px",
              }}
            >
              <span ref={thirdText}>
                Our operating system enables precise control
                <br /> and data analysis.
              </span>{" "}
              <span ref={fourthText}>
                Streamline bioreactor
                <br /> data management, and analysis with
                <br /> our intuitive software, featuring secure data handling
              </span>{" "}
            </BodyMedium>
          </SecondContainer>
        </ScrollContainer>
      </Container>
    </>
  );
};
const ScrollContainer = styled.div`
  position: sticky;
  top: 0;
  left: 0;
  width: 100%;
  height: 100px;
  min-height: 100vh;
`;
const Container = styled.div`
  display: flex;
  flex: 1;
  justify-content: start;
  flex-direction: column;
  position: relative;
  width: 100%;
  min-height: 450vh;
  background: #000407;
`;
const Shadow = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  box-shadow: inset 0px 0px 84px 34px #000407;
`;
const VideoContainer = styled.div`
  position: absolute;
  object-fit: cover;
  z-index: -1;
  width: 100%;
  height: 60%;
  transition: all 0.1s ease;
  left: -20%;
  right: 0;
  margin: auto;
  top: 10%;
`;

const Video = styled.video`
  position: absolute;
  object-fit: cover;
  z-index: -1;
  width: 100%;
  height: auto;
  margin: auto;
`;

const SecondContainer = styled.div`
  position: absolute;
  width: 100%;
  top: 100%;
  opacity: 1;
  transition: all 0.1s ease;
  left: 16px;
  right: 0;
  margin: auto;
  max-width: calc(100% - 32px);
  h5,
  p {
    transition: all 0.1s ease;
  }
`;

const FirstContainer = styled.div`
  position: absolute;
  width: auto;
  height: 120px;
  top: 50%;
  opacity: 1;
  transition: all 0.1s ease;
  left: 16px;
  right: 0;
  max-width: calc(100% - 32px);
  h5,
  p {
    transition: all 0.1s ease;
  }
`;

export default MicrochipMobile;
