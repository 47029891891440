import { Element } from "react-scroll";
import styled, { css, keyframes } from "styled-components";
import { useRef, useLayoutEffect, useContext } from "react";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { sectionContext } from "../../context/sectionSelected";
import {
  BodyMedium,
  Heading3,
  TitleLarge,
  TitleMedium,
} from "../../commonComponents/Text";
const VerticalsMobile = () => {
  const mainContainer: any = useRef();
  const firstContainer: any = useRef();
  const titleContainer: any = useRef();
  const secondContainer: any = useRef();
  const thirdContainer: any = useRef();
  const fourthContainer: any = useRef();
  const firstText: any = useRef();
  const secondText: any = useRef();
  const thirdText: any = useRef();
  const fourthText: any = useRef();
  const fifthText: any = useRef();
  const sixthText: any = useRef();
  const ellipseRef: any = useRef();
  // const videoRef: any = useRef();
  const { section } = useContext(sectionContext);
  useLayoutEffect(() => {
    //progress video with gsap
    gsap.registerPlugin(ScrollTrigger);
    ScrollTrigger.create({
      trigger: mainContainer.current,
      scrub: true,
      start: "top top",
      end: "+=270%",
      id: "pinVerticals",
      pinnedContainer: "#verticals",
      onUpdate: function (self) {
        if (firstContainer.current) {
          let scrollPos = self.progress;
          if (scrollPos === 0) {
            titleContainer.current.style.opacity = 0;
            firstContainer.current.style.opacity = 1;
            firstContainer.current.style.transform = `scale(1)`;
            secondContainer.current.style.opacity = 0;
            thirdContainer.current.style.opacity = 0;
            fourthContainer.current.style.opacity = 0;
            // videoRef.current.style.opacity = 0;
            ellipseRef.current.style.opacity = 0;
            // ellipse2Ref.current.style.opacity = 1;
          } else if (scrollPos > 0 && scrollPos < 0.1) {
            fadeOutContainer(firstContainer, scrollPos * 10);
            fadeInTitle(titleContainer, scrollPos * 10);
            // fadeOutEllipse(ellipse2Ref, scrollPos);
            fadeInEllipse(ellipseRef, scrollPos);
            // fadeInVideo(videoRef, scrollPos);
          } else if (scrollPos >= 0.1 && scrollPos < 0.15) {
            fadeInTitle(titleContainer, scrollPos * 10);
          } else if (scrollPos >= 0.15 && scrollPos < 0.2) {
          } else if (scrollPos >= 0.2 && scrollPos < 0.25) {
            fadeOutTitle(titleContainer, scrollPos - 0.2);
            slideInContainer(secondContainer, scrollPos - 0.2);
          } else if (scrollPos >= 0.25 && scrollPos < 0.3) {
            slideInContainer(secondContainer, scrollPos - 0.2);
            fadeIn(secondContainer, scrollPos - 0.25);
          } else if (scrollPos >= 0.3 && scrollPos < 0.4) {
            fadeOutText(firstText, scrollPos - 0.3);
            fadeInText(secondText, scrollPos - 0.3);
            centerEllipse(ellipseRef, scrollPos - 0.3);
          } else if (scrollPos >= 0.4 && scrollPos < 0.45) {
            fadeOutContainerText(secondContainer, scrollPos - 0.4);
            fadeOut(secondContainer, scrollPos - 0.4);
            slideInContainer(thirdContainer, scrollPos - 0.4);
          } else if (scrollPos >= 0.45 && scrollPos < 0.5) {
            slideInContainer(thirdContainer, scrollPos - 0.4);
            fadeIn(thirdContainer, scrollPos - 0.45);
            slideOutEllipse(ellipseRef, scrollPos - 0.45);
          } else if (scrollPos >= 0.5 && scrollPos < 0.55) {
            slideOutEllipse(ellipseRef, scrollPos - 0.45);
          } else if (scrollPos >= 0.55 && scrollPos < 0.65) {
            fadeOutText(thirdText, scrollPos - 0.55);
            fadeInText(fourthText, scrollPos - 0.55);
            centerEllipse(ellipseRef, scrollPos - 0.55);
          } else if (scrollPos >= 0.65 && scrollPos < 0.7) {
            fadeOutContainerText(thirdContainer, scrollPos - 0.65);
            fadeOut(thirdContainer, scrollPos - 0.65);
            slideInFinalContainer(fourthContainer, scrollPos - 0.65);
          } else if (scrollPos >= 0.7 && scrollPos < 0.75) {
            slideInFinalContainer(fourthContainer, scrollPos - 0.65);
            slideOutEllipse(ellipseRef, scrollPos - 0.7);
            fadeIn(fourthContainer, scrollPos - 0.7);
          } else if (scrollPos >= 0.75 && scrollPos < 0.8) {
            slideOutEllipse(ellipseRef, scrollPos - 0.75);
          } else if (scrollPos >= 0.8 && scrollPos < 0.9) {
            fadeOutText(fifthText, scrollPos - 0.8);
            fadeInText(sixthText, scrollPos - 0.8);
            centerEllipse(ellipseRef, scrollPos - 0.8);
          } else if (scrollPos >= 0.9 && scrollPos < 1) {
            fadeOut(fourthContainer, scrollPos - 0.9);
          }
          if (scrollPos > 0.1) {
            firstContainer.current.style.opacity = 0;
            // videoRef.current.style.opacity = 0.3;
            ellipseRef.current.style.opacity = 1;
            // ellipse2Ref.current.style.opacity = 0;
          }
          if (scrollPos > 0.3) {
            titleContainer.current.style.opacity = 0;
          }
          if (scrollPos > 0.45) {
            secondContainer.current.style.opacity = 0;
          }
          if (scrollPos > 0.7) {
            thirdContainer.current.style.opacity = 0;
          }
          if (scrollPos >= 0.3 && scrollPos < 0.4) {
            secondContainer.current.style.opacity = 1;
            secondContainer.current.style.transform = `translateY(-75vh)`;
          }
          if (scrollPos >= 0.5 && scrollPos < 0.7) {
            thirdContainer.current.style.opacity = 1;
            thirdContainer.current.style.transform = `translateY(-75vh)`;
          }
          if (scrollPos >= 0.75) {
            fourthContainer.current.style.transform = `translateY(-77vh)`;
          }
          if (scrollPos >= 1) {
            fourthContainer.current.style.opacity = 0;
          }
          if (scrollPos < 0.25) {
            secondContainer.current.style.opacity = 0;
          }
          if (scrollPos < 0.45) {
            thirdContainer.current.style.opacity = 0;
          }
          if (scrollPos < 0.7) {
            fourthContainer.current.style.opacity = 0;
          }
          if (scrollPos < 0.1) {
            titleContainer.current.style.opacity = 0;
          }
        }
      },
    });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  function fadeOutContainer(container: any, progress: number) {
    container.current.style.opacity = 1 - progress * 1.1;
    container.current.style.transform = `scale(${1 + progress * 1.3})`;
  }
  function fadeInTitle(title: any, progress: number) {
    title.current.style.opacity = progress * 0.7;
  }

  function fadeOutTitle(title: any, progress: number) {
    title.current.style.opacity = 1 - progress * 20.5;
  }

  function fadeIn(title: any, progress: number) {
    title.current.style.opacity = progress * 21;
  }

  function fadeOut(title: any, progress: number) {
    title.current.style.opacity = 1 - progress * 21;
  }

  function fadeOutText(text: any, progress: number) {
    text.current.style.opacity = 1 - progress * 7.1;
  }

  function fadeInText(text: any, progress: number) {
    text.current.style.opacity = 0.3 + progress * 7.1;
  }
  function centerEllipse(ellipse: any, progress: number) {
    ellipse.current.style.transform = `translateY(${
      progress * 400
    }vh) translateX(${progress * 300}vw)`;
  }

  function slideOutEllipse(ellipse: any, progress: number) {
    ellipse.current.style.transform = `translateY(calc(40vh - ${
      progress * 400
    }vh)) translateX(calc(30vw - ${progress * 300}vw))`;
  }

  function fadeInEllipse(ellipse: any, progress: number) {
    ellipse.current.style.opacity = progress * 11;
  }
  function fadeOutContainerText(container: any, progress: number) {
    container.current.style.transform = `translateY(calc(-70vh - ${
      progress * 1000
    }vh))`;
  }
  function slideInContainer(container: any, progress: number) {
    container.current.style.transform = `translateY(-${progress * 750}vh)`;
  }
  function slideInFinalContainer(container: any, progress: number) {
    container.current.style.transform = `translateY(-${progress * 780}vh)`;
  }

  return (
    <Element name="#verticals">
      <Container ref={mainContainer} id="verticals">
        <ScrollContainer animated={section === "verticals"}>
          {/* <Video
            preload="auto"
            webkit-playsinline="true"
            muted
            src="/cells.mp4"
            autoPlay
            loop
            controls={false}
            ref={videoRef}
            playsInline
          /> */}
          <Shadow />
          {/* <RadialEllipse ref={ellipse2Ref} /> */}
          <EllipseContainer ref={ellipseRef}>
            <Ellipse />
          </EllipseContainer>
          <TitleContainer>
            <FirstText ref={firstContainer}>
              <Heading3
                style={{
                  textAlign: "center",
                }}
              >
                Unlocking possibilities through advanced Bioreactor Technology
              </Heading3>
            </FirstText>
            <TitleText ref={titleContainer}>
              <TitleMedium
                isSecondary
                style={{
                  textTransform: "uppercase",
                  textShadow:
                    "0px 0px 25px rgba(56, 118, 255, 0.50), 0px 0px 4px rgba(56, 118, 255, 0.25)",
                }}
              >
                Industries
              </TitleMedium>
            </TitleText>
            <SecondaryText ref={secondContainer}>
              <SecondarySubTitle
                style={{
                  textTransform: "uppercase",
                }}
              >
                Pharma
              </SecondarySubTitle>
              <TitleLarge
                isSecondary
                style={{
                  textTransform: "uppercase",
                  textShadow:
                    "0px 0px 10px  rgba(56, 118, 255, 0.60), 0px 0px 4px rgba(56, 118, 255, 0.25)",
                  marginBottom: "4px",
                }}
              >
                Future of Healthcare
              </TitleLarge>
              <BodyMedium>
                <span ref={firstText}>
                  Our technology accelerates drug development and optimizes
                  production processes for pharmaceutical companies.
                </span>{" "}
                <span ref={secondText}>
                  Our advanced bioreactors provide precise control and data
                  analytics, enhancing research capabilities and improving
                  efficiency.
                </span>
              </BodyMedium>
            </SecondaryText>
            <SecondaryText ref={thirdContainer}>
              <SecondarySubTitle
                style={{
                  textTransform: "uppercase",
                }}
              >
                Foodtech
              </SecondarySubTitle>
              <TitleLarge
                style={{
                  textTransform: "uppercase",
                  textShadow:
                    "0px 0px 10px  rgba(56, 118, 255, 0.60), 0px 0px 4px rgba(56, 118, 255, 0.25)",
                  marginBottom: "4px",
                }}
                isSecondary
              >
                Next Generation of Food
              </TitleLarge>
              <BodyMedium>
                <span ref={thirdText}>
                  Optimising food production, quality control, and product
                  development. With precise control in our bioreactors, we
                  produce high-quality ingredients, probiotics, and alternative
                  proteins.
                </span>{" "}
                <span ref={fourthText}>
                  We empower FoodTech companies to meet the demand for
                  sustainable, nutritious, and delicious food products, shaping
                  the future of the industry.
                </span>
              </BodyMedium>
            </SecondaryText>
            <SecondaryText ref={fourthContainer}>
              <SecondarySubTitle
                style={{
                  textTransform: "uppercase",
                }}
              >
                Biomaterials
              </SecondarySubTitle>
              <TitleLarge
                style={{
                  textTransform: "uppercase",
                  textShadow:
                    "0px 0px 10px  rgba(56, 118, 255, 0.60), 0px 0px 4px rgba(56, 118, 255, 0.25)",
                  marginBottom: "4px",
                }}
                isSecondary
              >
                Materials of Tomorrow
              </TitleLarge>
              <BodyMedium>
                <span ref={fifthText}>
                  With precise control and analytics, we accelerate development
                  of biodegradable polymers, tissue scaffolds, and regenerative
                  therapies.
                </span>{" "}
                <span ref={sixthText}>
                  Our bioreactors facilitate production of complex biomaterials
                  with enhanced properties, empowering the industry to create
                  groundbreaking solutions for medical devices, tissue
                  engineering, and sustainable materials.
                </span>
              </BodyMedium>
            </SecondaryText>
          </TitleContainer>
        </ScrollContainer>
      </Container>
    </Element>
  );
};
const Shadow = styled.div`
  position: absolute;
  width: 100%;
  height: 100vh;
  background: linear-gradient(180deg, rgb(0 0 0 / 0%) 0%, #000407 100%);
`;
const BounceIn = keyframes`
0% {
    -webkit-transform: translateY(500px);
            transform: translateY(500px);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
    opacity: 0;
  }
  50% {
    -webkit-transform: translateY(-40px);
            transform: translateY(-40px);
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
    opacity: 1;
  }
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
  }
  `;
const ScrollContainer = styled.div<{ animated: boolean }>`
  position: sticky;
  top: 0;
  left: 0;
  width: 100%;
  height: 100px;
  min-height: 100vh;
  animation: ${({ animated }) =>
    animated
      ? css`
          ${BounceIn} 1.5s ease-in-out
        `
      : "none"};
  overflow: hidden;
`;
const Container = styled.div`
  display: flex;
  flex: 1;
  justify-content: start;
  flex-direction: column;
  position: relative;
  width: 100%;
  background: #000407;
  min-height: 370vh;
`;
const TitleContainer = styled.div`
  position: sticky;
  top: 0;
  left: 0;
  width: 100%;
  height: 100px;
  min-height: 100vh;
`;

const FirstText = styled.div`
  position: absolute;
  left: 0;
  width: 100%;
  height: 140px;
  top: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  transition: all 0.1s ease;
  opacity: 1;
  text-align: center;
  padding: 0 16px;
`;

const EllipseContainer = styled.div`
  position: absolute;
  width: 50vw;
  height: 50vw;
  left: -25%;
  top: -50%;
  transition: all 0.1s ease;
  opacity: 0;
`;
const Ellipse = styled.div`
  background: radial-gradient(
    50% 50% at 50% 50%,
    rgba(73, 149, 182, 0.29) 0%,
    rgba(108, 201, 242, 0) 100%
  );
  filter: blur(24.733078002929688px);
  position: absolute;
  width: 50vw;
  height: 50vw;
`;
const SecondaryText = styled.div`
  position: absolute;
  left: 32px;
  width: auto;
  top: calc(100%);
  transition: all 0.1s ease;
  opacity: 0;
  & > p > span:nth-child(1) {
    opacity: 1;
    transition: all 0.1s ease;
  }
  & > p > span:nth-child(2) {
    opacity: 0.3;
    transition: all 0.1s ease;
  }
`;
const TitleText = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.1s ease;
  opacity: 0;
  text-align: center;
`;
const SecondarySubTitle = styled.h2`
  color: #f4f4f4;
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  margin-bottom: 4px;
`;
export default VerticalsMobile;
