import { useCollectionData } from "react-firebase-hooks/firestore";
import { firestore, storageRef } from "../../utils/firebase";
import Toast from "../../utils/toast";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

export default function useSupportList() {
  const supportRef = firestore.collection("support");
  const [support, loading, error] = useCollectionData(supportRef);
  const MySwal = withReactContent(Swal);

  async function handleDelete(uid: string) {
    const swalResponse = await MySwal.fire({
      title: "Eliminar registro",
      text: "Está seguro de querer eliminar este registro?",
      showConfirmButton: true,
      showCancelButton: true,
      showLoaderOnConfirm: true,
      backdrop: true,
      preConfirm: async () => {
        await supportRef.doc(uid).delete();

        try {
          const pngIcon = storageRef.child(`support/${uid}.png`);
          if (pngIcon) await pngIcon.delete();
        } catch (error) {
          console.log(error);
        }
        try {
          const svgIcon = storageRef.child(`support/${uid}.svg`);
          if (svgIcon) await svgIcon.delete();
        } catch (error) {
          console.log(error);
        }

      },
      allowOutsideClick: () => !MySwal.isLoading(),
    });
    if (swalResponse.isConfirmed) {
      Toast.fire({ icon: "success", title: "Registro eliminado con éxito" });
    }
  }

  return {
    support,
    loading,
    error,
    handleDelete
  };
}
