import { Link } from "react-router-dom";
import styled from "styled-components";
import Page from "../../commonComponents/Page";
import Button from "../../commonComponents/Button";
import ImageUploader from "../../commonComponents/ImageUploader";
import useSupportForm from "../hooks/useSupportForm";

export default function SupportForm() {
  const { add, submit, loadedPress, onImageUpload } = useSupportForm();

  return (
    <Page
      title={(add ? "Create" : "Edit") + " support or partnership"}
      icon="support"
      isHalf={true}
    >
      <ColumnsForm onSubmit={submit}>
        <Row>
          <FieldsContainer>
            <Label>Name</Label>
            <Input
              type="text"
              name="name"
              placeholder="Name"
              defaultValue={loadedPress?.name}
            />
          </FieldsContainer>
          <FieldsContainer>
            <Label>Type</Label>
            <Select name="type" defaultValue={loadedPress?.type}>
              <Option disabled value={undefined} selected={!loadedPress?.type}>
                Select
              </Option>
              <Option value="support">Support</Option>
              <Option value="partnership">Partnership</Option>
            </Select>
          </FieldsContainer>
        </Row>
        <FieldsContainer>
          <Label>Image</Label>
          <ImageUploader
            defaultSrc={loadedPress?.imageURL}
            onChange={onImageUpload}
          />
        </FieldsContainer>
        <ButtonsContainer style={{ gap: "1rem" }}>
          <Button type={"primary"}>Save</Button>
          <Link to="/admin/supports-partners">
            <Button type={"secondary"}>Back</Button>
          </Link>
        </ButtonsContainer>
      </ColumnsForm>
    </Page>
  );
}
const Row = styled.div`
  display: flex;
  gap: 1rem;
  width: 100%;
  justify-content: space-between;
`;
const ColumnsForm = styled.form`
  gap: 1rem;
`;
const FieldsContainer = styled.div`
  margin: 8px 0;
`;
const Label = styled.label`
  display: block;
  margin-bottom: 0.25rem;
  font-size: 0.75rem;
  color: #354b79;
`;
const Input = styled.input`
  &:not([type="checkbox"]) {
    padding: 0.5rem;
    width: 100%;
  }
  color: #354b79;
`;
const ButtonsContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  grid-column: 1/-1;
  margin-top: 16px;
`;
const Select = styled.select`
  padding: 0.5rem;
  width: 100%;
  color: #354b79;
`;
const Option = styled.option`
  color: #354b79;
`;
