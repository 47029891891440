import { useEffect, useContext } from "react";
import { scrollContext } from "../context/scrollContext";
import { stepContext } from "../context/stepContext";

const useWindowScroll = ( ) => {
  const { setScroll } = useContext(scrollContext);
  const { setStep} = useContext(stepContext);

  function scrolling(e: any) {
    const scroll = e.target.scrollingElement.scrollTop;
    const newStep = Math.floor(scroll / 100);
    setStep({ stepNumber: newStep });
    setScroll({ scrollToTop: scroll });
  }

  useEffect(() => {
    window.addEventListener("scroll", (e: any) => scrolling(e));
    return () => window.removeEventListener("scroll", (e: any) => scrolling(e));
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
};

export default useWindowScroll;
