import { Element } from "react-scroll";
import styled, { css, keyframes } from "styled-components";
import { useRef, useLayoutEffect, useContext, useState } from "react";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { sectionContext } from "../../context/sectionSelected";
import {
  BodyMedium,
  Heading3,
  TitleLarge,
  TitleSmall,
} from "../../commonComponents/Text";
const AboutMobile = () => {
  const mainContainer: any = useRef();
  const firstText: any = useRef();
  const secondText: any = useRef();
  const thirdText: any = useRef();
  const fourthText: any = useRef();
  const { section } = useContext(sectionContext);
  const [loadMetadata, setLoadMetadata] = useState<boolean>(false);
  useLayoutEffect(() => {
    if (loadMetadata) {
      //progress video with gsap
      gsap.registerPlugin(ScrollTrigger);
      window.scrollTo(0, 0);
      ScrollTrigger.create({
        trigger: mainContainer.current,
        scrub: true,
        start: "top top",
        end: "+=180%",
        id: "pinhome",
        pinnedContainer: "#home",
        onLeave: function (self) {
          const productContainer = document.getElementById("product");
          if (productContainer) {
            productContainer.scrollIntoView();
          }
        },
        onUpdate: function (self) {
          if (firstText.current) {
            let scrollPos = self.progress;
            if (scrollPos >= 0 && scrollPos < 0.2) {
              slideFirstText(firstText, scrollPos * 40);
              secondText.current.style.opacity = 0;
              thirdText.current.style.opacity = 0;
              fourthText.current.style.opacity = 0;
            } else if (scrollPos >= 0.2 && scrollPos < 0.4) {
              slideFirstText(firstText, scrollPos * 40);
              slideSecondText(secondText, scrollPos - 0.2);
            } else if (scrollPos >= 0.4 && scrollPos < 0.6) {
              fadeOutSexondText(secondText, scrollPos - 0.4);
              slideSecondText(thirdText, scrollPos - 0.4);
            } else if (scrollPos >= 0.6 && scrollPos < 0.8) {
              fadeOutSexondText(thirdText, scrollPos - 0.6);
              slideSecondText(fourthText, scrollPos - 0.6);
            } else if (scrollPos >= 0.8 && scrollPos < 1) {
              fadeOutSexondText(fourthText, scrollPos - 0.8);
            } else {
              firstText.current.style.opacity = 0;
              secondText.current.style.opacity = 0;
              fourthText.current.style.top = `22.9173%`;
              secondText.current.style.top = `20.3079%`;
              thirdText.current.style.top = `20.3079%`;
              fourthText.current.style.opacity = 0;
            }
            if (scrollPos >= 0.4) {
              secondText.current.style.top = `20.3079%`;
            }
            if (scrollPos >= 0.6) {
              secondText.current.style.opacity = 0;
              thirdText.current.style.top = `20.3079%`;
            }
          }
        },
      });
    }
  }, [loadMetadata]); // eslint-disable-line react-hooks/exhaustive-deps

  function slideFirstText(firstText: any, progress: number) {
    firstText.current.style.opacity = 1 - 0.1 * progress;
  }
  function slideSecondText(secondText: any, progress: number) {
    secondText.current.style.top = `${100 - progress * 403}%`;
    secondText.current.style.opacity = progress * 5 <= 0.05 ? 0 : progress * 5;
  }
  function fadeOutSexondText(secondText: any, progress: number) {
    secondText.current.style.opacity = 1 - progress * 5.5;
  }

  return (
    <Element name="#home">
      <Container ref={mainContainer} id="home">
        <ScrollContainer animated={section === "home"}>
          <Video
            preload="auto"
            webkit-playsinline="true"
            muted
            src="/background-liquido.mp4"
            onLoadedMetadata={() => setLoadMetadata(true)}
            autoPlay
            loop
            playsInline
          />
          <FirstText ref={firstText}>
            <Heading3
              style={{
                textAlign: "start",
              }}
            >
              Powering innovation with intelligent bioprocessing.
            </Heading3>
            <TitleSmall
              isSecondary
              style={{ width: "343px", textAlign: "start" }}
            >
              We are not just bringing biofabrication into the future. We are
              reinventing it.
            </TitleSmall>
          </FirstText>
          <SecondText ref={secondText}>
            <TitleLarge
              style={{
                textTransform: "uppercase",
                textShadow:
                  "0px 0px 10px rgba(56, 118, 255, 0.60), 0px 0px 4px rgba(56, 118, 255, 0.25)",
                marginBottom: "12px",
              }}
              isSecondary
            >
              Accelerate your bioprocesses
            </TitleLarge>
            <BodyMedium>
              Integrate the latest hardware and software developments, combining
              novel sensor technology
              <br /> with AI-powered bioprocess identification for
              <br />
              precise control, enhanced optimisation
              <br /> and improved usability.
            </BodyMedium>
          </SecondText>
          <SecondText ref={thirdText}>
            <TitleLarge
              style={{
                textTransform: "uppercase",
                textShadow:
                  "0px 0px 10px rgba(56, 118, 255, 0.60), 0px 0px 4px rgba(56, 118, 255, 0.25)",
                marginBottom: "12px",
              }}
              isSecondary
            >
              Defining the future
            </TitleLarge>
            <BodyMedium>
              We give you the tools, data, and
              <br /> insights to create something exceptional
              <br /> and truly different
              <br /> with a simple click.
            </BodyMedium>
          </SecondText>
          <SecondText ref={fourthText}>
            <TitleLarge
              style={{
                textTransform: "uppercase",
                textShadow:
                  "0px 0px 10px rgba(56, 118, 255, 0.60), 0px 0px 4px rgba(56, 118, 255, 0.25)",
                marginBottom: "12px",
              }}
              isSecondary
            >
              Reduce costs and result times
            </TitleLarge>
            <BodyMedium>
              Efficient experiment design, improved control and optimisation
              reduces repetition to
              <br /> minimize time and costs, and maximize results.
            </BodyMedium>
          </SecondText>
        </ScrollContainer>
      </Container>
    </Element>
  );
};
const Video = styled.video`
  position: absolute;
  max-width: 100%;
  max-height: 100%;
  object-fit: cover;
  z-index: -1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  opacity: 0.6;
`;
const BounceIn = keyframes`
0% {
    -webkit-transform: translateY(500px);
            transform: translateY(500px);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
    opacity: 0;
  }
  50% {
    -webkit-transform: translateY(-40px);
            transform: translateY(-40px);
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
    opacity: 1;
  }
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
  }
  `;

const Container = styled.div`
  display: flex;
  flex: 1;
  justify-content: start;
  flex-direction: column;
  position: relative;
  width: 100%;
  min-height: 280vh;
  background: #000407;
`;
const ScrollContainer = styled.div<{ animated: boolean }>`
  position: sticky;
  top: 0;
  left: 0;
  width: 100%;
  height: 100px;
  min-height: 100vh;
  overflow: hidden;
  animation: ${({ animated }) =>
    animated
      ? css`
          ${BounceIn} 1.5s ease-in-out
        `
      : "none"};
`;

const SecondText = styled.div`
  position: absolute;
  left: 16px;
  width: 100%;
  top: 100%;
  display: block;
  opacity: 0;
  transition: 0.1s ease;
`;

const FirstText = styled.div`
  position: absolute;
  left: 16px;
  max-width: 100%;
  height: 300px;
  top: 20%;
  opacity: 1;
  transition: 0.1s ease;
`;

export default AboutMobile;
