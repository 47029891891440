import styled, { keyframes } from "styled-components";
import logo from "../assets/images/logoHeader.svg";
import { sectionContext } from "../context/sectionSelected";
import { useContext, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import CloseIconHeader from "../assets/images/closeIconHeader.svg";
import MenuIconHeader from "../assets/images/openIconHeader.svg";

export default function HeaderResponsive() {
  const { setSection } = useContext(sectionContext);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const navigate = useNavigate();
  const location = useLocation();
  function scrollToSection(nextSection: string) {
    if (nextSection === "home") {
      setTimeout(() => {
        window.scrollTo({
          top: 0,
        });
      }, 100);
      setTimeout(() => {
        window.scrollTo({
          top: 0,
        });
      }, 200);
      setTimeout(() => {
        window.scrollTo({
          top: 0,
        });
      }, 300);
      setTimeout(() => {
        window.scrollTo({
          top: 0,
        });
      }, 400);
      return;
    } else if (nextSection === "description") {
      const prevElementPosition =
        document.getElementById(nextSection)?.offsetTop;
      if (prevElementPosition) {
        setTimeout(() => {
          window.scrollTo({
            top: prevElementPosition + window.innerHeight + window.innerHeight,
          });
        }, 100);
        setTimeout(() => {
          window.scrollTo({
            top: prevElementPosition + window.innerHeight + window.innerHeight,
          });
        }, 200);
        setTimeout(() => {
          window.scrollTo({
            top: prevElementPosition + window.innerHeight + window.innerHeight,
          });
        }, 300);
        setTimeout(() => {
          window.scrollTo({
            top: prevElementPosition + window.innerHeight + window.innerHeight,
          });
        }, 400);
      }
      return;
    } else if (nextSection === "footer") {
      const prevElementPosition = document.getElementById("press")?.offsetTop;
      if (prevElementPosition) {
        setTimeout(() => {
          window.scrollTo({
            top: prevElementPosition + window.innerHeight + window.innerHeight,
          });
        }, 100);
        setTimeout(() => {
          window.scrollTo({
            top: prevElementPosition + window.innerHeight + window.innerHeight,
          });
        }, 200);
        setTimeout(() => {
          window.scrollTo({
            top: prevElementPosition + window.innerHeight + window.innerHeight,
          });
        }, 300);
        setTimeout(() => {
          window.scrollTo({
            top: prevElementPosition + window.innerHeight + window.innerHeight,
          });
        }, 400);
      }
      return;
    } else if (nextSection === "product") {
      const prevElementPosition = document.getElementById("product")?.offsetTop;
      if (prevElementPosition) {
        setTimeout(() => {
          window.scrollTo({
            top: prevElementPosition + 300,
          });
        }, 100);
        setTimeout(() => {
          window.scrollTo({
            top: prevElementPosition + 300,
          });
        }, 200);
        setTimeout(() => {
          window.scrollTo({
            top: prevElementPosition + 300,
          });
        }, 300);
        setTimeout(() => {
          window.scrollTo({
            top: prevElementPosition + 300,
          });
        }, 400);
      }
      return;
    } else if (nextSection === "verticals") {
      const prevElementPosition =
        document.getElementById("verticals")?.offsetTop;
      if (prevElementPosition) {
        setTimeout(() => {
          window.scrollTo({
            top: prevElementPosition,
          });
        }, 100);
        setTimeout(() => {
          window.scrollTo({
            top: prevElementPosition,
          });
        }, 200);
        setTimeout(() => {
          window.scrollTo({
            top: prevElementPosition,
          });
        }, 300);
        setTimeout(() => {
          window.scrollTo({
            top: prevElementPosition,
          });
        }, 400);
      }
      return;
    }

    const nextElementPosition = document.getElementById(nextSection)?.offsetTop;
    if (nextElementPosition) {
      setTimeout(() => {
        window.scrollTo({
          top: nextElementPosition - window.innerHeight,
        });
      }, 100);
      setTimeout(() => {
        window.scrollTo({
          top: nextElementPosition - window.innerHeight,
        });
      }, 200);
      setTimeout(() => {
        window.scrollTo({
          top: nextElementPosition - window.innerHeight,
        });
      }, 300);
      setTimeout(() => {
        window.scrollTo({
          top: nextElementPosition - window.innerHeight,
        });
      }, 400);
    }
  }
  function closeMenu() {
    setIsOpen(false);
  }
  return (
    <>
      <Container>
        <HeaderContainer>
          <LogoContainer>
            <Link
              onClick={() => {
                if (location.pathname !== "/") {
                  navigate("/");
                  setTimeout(() => {
                    scrollToSection("home");
                  }, 10);
                } else {
                  setSection("home");
                  scrollToSection("home");
                }
                closeMenu();
              }}
            >
              <img src={logo} alt="logo" />
            </Link>
          </LogoContainer>
          <MenuIcon>
            <img
              src={CloseIconHeader}
              className={isOpen ? "close" : "open"}
              alt="menu icon"
              onClick={() => {
                setIsOpen(!isOpen);
              }}
            />
            <img
              src={MenuIconHeader}
              className={isOpen ? "open" : "close"}
              alt="menu icon"
              onClick={() => {
                setIsOpen(!isOpen);
              }}
            />
          </MenuIcon>
        </HeaderContainer>
      </Container>
      {isOpen && (
        <LinksContainer>
          <Link
            onClick={() => {
              if (location.pathname !== "/") {
                navigate("/");
                setTimeout(() => {
                  scrollToSection("home");
                }, 10);
              } else {
                scrollToSection("home");
              }
              closeMenu();
            }}
          >
            About
          </Link>

          <Link
            onClick={() => {
              if (location.pathname !== "/") {
                navigate("/");
                setTimeout(() => {
                  // scrollToSection("verticals");
                  setSection("verticals");
                }, 10);
              } else {
                scrollToSection("verticals");
              }
              closeMenu();
            }}
          >
            Industries
          </Link>
          <Link
            onClick={() => {
              if (location.pathname !== "/") {
                navigate("/");
                setTimeout(() => {
                  // scrollToSection("support");
                  setSection("team");
                }, 10);
              } else {
                scrollToSection("support");
              }
              closeMenu();
            }}
          >
            Team
          </Link>
          <Link
            onClick={() => {
              if (location.pathname !== "/") {
                navigate("/");
                setTimeout(() => {
                  // scrollToSection("press");
                  setSection("support");
                }, 10);
              } else {
                scrollToSection("press");
              }
              closeMenu();
            }}
          >
            Support & Partners
          </Link>
          <Link
            onClick={() => {
              if (location.pathname !== "/") {
                navigate("/");
                setTimeout(() => {
                  // scrollToSection("contact");
                  setSection("press");
                }, 10);
              } else {
                scrollToSection("newsletter");
              }
              closeMenu();
            }}
          >
            News
          </Link>
          <Link
            onClick={() => {
              if (location.pathname !== "/") {
                navigate("/");
                setTimeout(() => {
                  // scrollToSection("footer");
                  setSection("contact");
                }, 10);
              } else {
                scrollToSection("footer");
              }
              closeMenu();
            }}
          >
            Contact
          </Link>
          <Link
            className="btn"
            onClick={() => {
              navigate("/joinus");
              closeMenu();
            }}
          >
            Careers
          </Link>
        </LinksContainer>
      )}
    </>
  );
}
const FadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const Link = styled.a`
  cursor: pointer;
  &.btn {
    padding: 6px 21px;
    background-color: #2e6fff;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    border-radius: 4px;
  }
  &.btn:hover {
    color: white;
  }
`;
const MenuIcon = styled.div`
  width: 24px;
  height: 24px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  img {
    width: 24px;
    height: 24px;
    position: absolute;
    top: 0;
    right: 0;
    transition: all 0.3s ease-in-out;
    &.open {
      opacity: 0;
    }
    &.close {
      opacity: 1;
    }
  }
`;
const Container = styled.div`
  transition: all 0.3s;
  padding: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  top: 0;
  z-index: 100;
  position: fixed;
  width: 100%;
  overflow: hidden;
  /* background: linear-gradient(180deg, #000000b3, #00000003); */
`;
const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  position: relative;
  z-index: 0;
`;
const LogoContainer = styled.div`
  img {
    width: 140px;
    height: auto;
  }
`;
const LinksContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 40px;
  align-items: center;
  position: fixed;
  width: 100vw;
  height: 100vh;
  flex-direction: column;
  top: 0;
  left: 0;
  background: #000407;
  animation: ${FadeIn} 0.3s ease-in-out;
  z-index: 5;
  a {
    font-size: 1rem;
    font-weight: 500;
    color: white;
    text-decoration: none;
    &:hover {
      color: #2e6fff;
    }
  }
`;
