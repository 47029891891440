import { useContext } from "react";
import styled from "styled-components";
import { sectionContext } from "../context/sectionSelected";
import TouchIcon from "../assets/images/touchIcon.svg";

export default function GetInTouchButton() {
  const { setSection, suscribed, showButton } = useContext(sectionContext);
  return (
    <FixedButton
      suscribed={!showButton || suscribed}
      onClick={() => setSection("contact")}
    >
      <Image src={TouchIcon} alt="Touch ICon" />
      <Text>Get in touch</Text>
    </FixedButton>
  );
}
const Text = styled.span`
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  min-width: 105px;
  color: transparent;
`;
const FixedButton = styled.button<{ suscribed: boolean }>`
  display: ${(props) => (props.suscribed ? "none" : "flex")};
  position: fixed;
  bottom: 10px;
  right: 10px;
  width: 50px;
  height: 50px;
  background-color: #2e6fff;
  color: #fff;
  border: none;
  border-radius: 23px;
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  outline: none;
  z-index: 299;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  &:hover {
    background-color: #1d4ed8;
    width: 170px;
    ${Text} {
      color: #fff;
    }
  }
  align-items: center;
  justify-content: flex-start;
  padding: 0 10px;
  overflow: hidden;
`;
const Image = styled.img`
  width: 26px;
  height: 26px;
  margin-right: 10px;
  margin-left: 3px;
`;
