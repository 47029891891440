import { createContext, useState } from "react";

interface StepContext {
  step: {
    stepNumber: number;
  };
  setStep: (userState: any) => void;
}

const stepContext = createContext<StepContext>({
  step: {
    stepNumber: 0,
  },
  setStep: () => {},
});

const StepProvider = ({ children }: { children: React.ReactNode }) => {
  const [step, setStep] = useState({
    stepNumber: 0,
  });
  return (
    <stepContext.Provider
      value={{
        step: step,
        setStep: setStep,
      }}
    >
      {children}
    </stepContext.Provider>
  );
};

export { StepProvider, stepContext };
