import Page from "../../commonComponents/Page";
import styled from "styled-components";
import { Link } from "react-router-dom";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import useJobsList from "../hooks/useJobsList";

export default function JobsList() {
  const { jobs, loading, error, handleDelete } = useJobsList();

  return (
    <Page title="Press" icon="jobs" addLink="jobs/add">
      <Table>
        <Thead>
          <Tr>
            <Th>Position</Th>
            <Th>Area</Th>
            <Th>Description</Th>
            <Th>Actions</Th>
          </Tr>
        </Thead>
        <Tbody>
          {jobs?.length &&
            jobs.map((job) => (
              <Tr key={job.uid}>
                <Td>{job.position}</Td>
                <Td>{job.area}</Td>
                <Td>{job.description}</Td>
                <Td>
                  <Link to={"/admin/jobs/" + job.uid}>
                    <Span>
                      <EditIcon
                        style={{
                          fill: "#354b79",
                        }}
                      />
                    </Span>
                  </Link>
                  <Span
                    onClick={() => {
                      handleDelete(job.uid);
                    }}
                  >
                    <DeleteIcon
                      style={{
                        fill: "#354b79",
                      }}
                    />
                  </Span>
                </Td>
              </Tr>
            ))}
          {!loading && !jobs?.length && (
            <Tr>
              <Td>No open positions found</Td>
            </Tr>
          )}
          {loading && (
            <Tr>
              <Td>Loading open positions...</Td>
            </Tr>
          )}
          {error && (
            <Tr>
              <Td>An error occurred while loading open positions</Td>
            </Tr>
          )}
        </Tbody>
      </Table>
    </Page>
  );
}

const Table = styled.table`
  width: 100%;
  color: #354b79;
`;
const Tr = styled.tr`
  color: #354b79;
`;
const Th = styled.th`
  padding: 0.5rem;
  color: #354b79;
`;
const Td = styled.td`
  padding: 0.5rem;
  color: #354b79;
`;
const Thead = styled.thead``;
const Tbody = styled.tbody`
  & ${Tr}:nth-of-type(even) {
    background-color: white;
  }
  & ${Tr}:nth-of-type(odd) {
    background-color: rgba(0, 0, 0, 0.05);
  }
`;
const Span = styled.span`
  color: #354b79;
  cursor: pointer;
`;
