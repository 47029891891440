export const palette = {
  light: {
    primary: "#3876FF",
    complement2: "#2C60D6",
    complement: "#37AEFF",
    white: "#F4F4F4",
    white2: "#DFDFDF",
    gray: "#C5C5C5",
    gray2: "#9D9D9D",
    background: "#303030",
    background2: "#0F1621",
  },
  dark: {
    primary: "#3876FF",
    complement2: "#2C60D6",
    complement: "#37AEFF",
    white: "#F4F4F4",
    white2: "#DFDFDF",
    gray: "#C5C5C5",
    gray2: "#9D9D9D",
    background: "#303030",
    background2: "#0F1621",
  },
};
