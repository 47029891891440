import { Link } from "react-router-dom";
import styled from "styled-components";
import Page from "../../commonComponents/Page";
import Button from "../../commonComponents/Button";
import useUsersForm from "../hooks/useUsersForm";

export default function UsersForm() {
  const { add, submit, loadedUser } = useUsersForm();

  return (
    <Page
      title={(add ? "Create" : "Edit") + " user"}
      icon="users"
      isHalf={true}
    >
      <ColumnsForm onSubmit={submit}>
        <FieldsContainer>
          <Label>Name</Label>
          <Input
            type="text"
            name="username"
            placeholder="Name"
            defaultValue={loadedUser?.name}
          />
        </FieldsContainer>
        <FieldsContainer>
          <Label>E-mail</Label>
          <Input
            type="text"
            name="email"
            placeholder="E-mail"
            defaultValue={loadedUser?.email}
          />
        </FieldsContainer>
        <FieldsContainer>
          <Label>Sex</Label>
          <Select name="sex" defaultValue={loadedUser?.sex}>
            <Option disabled value={undefined} selected={!loadedUser?.sex}>
              Select
            </Option>
            <Option value="male">Male</Option>
            <Option value="female">Female</Option>
          </Select>
        </FieldsContainer>
        <FieldsContainer>
          <Label>Age</Label>
          <Input
            type="number"
            step="1"
            name="age"
            placeholder="Age"
            defaultValue={loadedUser?.age}
          />
        </FieldsContainer>

        {!add && (
          <FieldsContainer>
            <LabelCheck>
              Active
              <InputCheck
                type="checkbox"
                name="active"
                id="txtActive"
                defaultChecked={loadedUser?.active}
              />
              <SpanCheck></SpanCheck>
            </LabelCheck>
          </FieldsContainer>
        )}
        <ButtonsContainer style={{ gap: "1rem" }}>
          <Button type={"primary"}>Save</Button>
          <Link to="/admin/users">
            <Button type={"secondary"}>Back</Button>
          </Link>
        </ButtonsContainer>
      </ColumnsForm>
    </Page>
  );
}

const ColumnsForm = styled.form`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;
`;
const FieldsContainer = styled.div`
  margin: 8px 0;
`;
const Label = styled.label`
  display: block;
  margin-bottom: 0.25rem;
  font-size: 0.75rem;
  color: #354b79;
`;
const LabelCheck = styled.label`
  display: block;
  position: relative;
  padding-left: 1.5em;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  margin: 0;
  color: #354b79;
`;
const SpanCheck = styled.span`
  position: absolute;
  top: 50%;
  left: 0;
  height: 1em;
  width: 1em;
  background-color: white;
  border-radius: 3px;
  transform: translateY(-50%);
`;
const InputCheck = styled.input`
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
  &:not(:checked) + ${SpanCheck} {
    border: 1px solid rgb(53, 75, 121);
  }
  &:checked + ${SpanCheck} {
    background-color: rgb(53, 75, 121);
  }
`;

const Input = styled.input`
  &:not([type="checkbox"]) {
    padding: 0.5rem;
    width: 100%;
  }
  color: #354b79;
`;
const Select = styled.select`
  padding: 0.5rem;
  width: 100%;
  color: #354b79;
`;
const Option = styled.option`
  color: #354b79;
`;
const ButtonsContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  grid-column: 1/-1;
  margin-top: 16px;
`;
