import { CircularProgress } from "@mui/material";
import styled from "styled-components";

export default function LoaderModal() {
  return (
    <Container>
      <CircularProgress
        sx={{
          color: "#2e6fff",
        }}
        size={100}
      />
    </Container>
  );
}

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  position: absolute;
  z-index: 1000;
  circle {
    color: #2e6fff;
  }
`;
