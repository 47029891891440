import styled from "styled-components";
import { auth } from "../../utils/firebase";
import Toast from "../../utils/toast";
import { useNavigate } from "react-router-dom";
import { palette } from "./../../style/palette";
export default function LoginForm() {
  const navigate = useNavigate();
  const loginEvent = async (event: any) => {
    event.preventDefault();
    const { user, password } = event.target.elements;
    try {
      await auth.signInWithEmailAndPassword(user.value, password.value);
      navigate("/admin/users");
      // After login, the redirects occurs in Layout component
    } catch (error: any) {
      if (error.code) {
        let title;
        switch (error.code) {
          case "auth/invalid-email":
            title = "The email entered is invalid";
            break;
          case "auth/user-not-found":
          case "auth/wrong-password":
            title = "Incorrect login or password";
            break;
          case "auth/user-disabled":
            title = "User disabled";
            break;
          default:
            title = "An error has occurred";
            break;
        }
        Toast.fire({ icon: "error", title });
      }
    }
  };
  return (
    <Container>
      <LoginBox>
        <H1>Log in</H1>
        <Form onSubmit={loginEvent}>
          <Input name="user" placeholder="User" />
          <Input name="password" placeholder="Password" type="password" />
          <Button type="submit">Log in</Button>
        </Form>
      </LoginBox>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: var(--primary);
`;
const LoginBox = styled.div`
  width: min(95%, 300px);
  border: none;
  border-radius: 5px;
  background: #273963;
  box-shadow: 0px 0px 24px rgba(0, 0, 0, 0.13);
  padding: 1rem;
  color: ${palette.light.primary};
`;
const H1 = styled.h1`
  margin: 0;
  text-align: center;
  font-size: large;
  margin-bottom: 1rem;
  color: #ffffff;
  text-transform: uppercase;
`;
const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;
const Input = styled.input`
  width: 100%;
  padding: 0.5rem;
  color: ${palette.light.primary};
  border-radius: 8px;
  border: none;
  outline: none;
`;
const Button = styled.button`
  cursor: pointer;
  width: 50%;
  padding: 0.5rem;
  margin: auto;
  color: #273963;
  border-radius: 8px;
  border: none;
  outline: none;
  box-shadow: 0px 0px 3px #0000006e;
`;
