import { Element } from "react-scroll";
import styled, { keyframes } from "styled-components";
import {
  BodyMedium,
  Heading2,
  Heading3,
  TitleMedium,
} from "../../commonComponents/Text";
import Input from "../../components/Input";
import { useEffect, useState } from "react";
import MailchimpSubscribe from "react-mailchimp-subscribe";
import CloseModalIcon from "../../assets/images/closeModalIcon.svg";
import SuccessIcon from "../../assets/images/successIcon.svg";
const NewsletterMobile = () => {
  const [emailAddress, setEmailAddress] = useState<string>("");
  const [emailValidate, setEmailValidate] = useState<boolean>(false);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [complete, setComplete] = useState<boolean>(false);
  const postUrl = `https://cultzyme.us21.list-manage.com/subscribe/post?u=3c9a75d368a60cd350eab1916&id=4cd627d5d7`;

  useEffect(() => {
    function evaluateFormComplete() {
      const emailRegex =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      const validateEmail = emailRegex.test(emailAddress);
      setEmailValidate(validateEmail);
      if (validateEmail) {
        setComplete(true);
      } else {
        setComplete(false);
      }
    }

    evaluateFormComplete();
  }, [emailAddress]);

  function handleSubmit() {}
  return (
    <>
      <Element name="#newsletter">
        <Container id="newsletter">
          <TopContainer>
            <TitleMedium
              isSecondary
              style={{
                textTransform: "uppercase",
                textShadow:
                  "0px 0px 10px rgba(56, 118, 255, 0.60), 0px 0px 4px rgba(56, 118, 255, 0.25)",
                marginBottom: "32px",
              }}
            >
              Newsletter
            </TitleMedium>
            <Heading3
              style={{
                textAlign: "start",
                fontSize: "20px",
                lineHeight: "30px",
              }}
            >
              Subscribe to Cultzyme's newsletter and gain exclusive access to
              industry trends, thought leadership insights, success stories, and
              much more.
            </Heading3>
          </TopContainer>
          <MailchimpSubscribe
            url={postUrl}
            render={({ subscribe, status, message }) => (
              <FormContainer>
                <RowInputs>
                  <InputContainer>
                    <BodyMedium>Email Address</BodyMedium>
                    <Input
                      placeholder="Example@domain.com"
                      value={emailAddress}
                      onChange={(e) => setEmailAddress(e.target.value)}
                      error={!emailValidate}
                    />
                  </InputContainer>
                </RowInputs>
                <RowInputs
                  style={{
                    justifyContent: "flex-end",
                    marginBottom: "0px",
                    paddingBottom: "64px",
                  }}
                >
                  <Button
                    onClick={() => {
                      handleSubmit();
                    }}
                    disabled={!complete}
                  >
                    Suscribe
                  </Button>
                </RowInputs>
              </FormContainer>
            )}
          />
          <SuscriberText>
            I accept to subscribe to the newsletter to receive news and updates.
          </SuscriberText>
        </Container>
      </Element>
      {showModal && (
        <ModalContainer>
          <BackgroundModal
            onClick={() => setShowModal(false)}
            className={showModal ? "show" : ""}
          ></BackgroundModal>
          <CardModal>
            <CardBottomContainer>
              <img
                style={{
                  width: "100px",
                  height: "100px",
                }}
                src={SuccessIcon}
                alt="Success Icon"
              />
              <Heading2
                isSecondary
                style={{
                  textAlign: "center",
                  width: "100%",
                }}
              >
                Thank you for subscribing!
              </Heading2>
            </CardBottomContainer>
            <CloseModal
              src={CloseModalIcon}
              onClick={() => setShowModal(false)}
            />
          </CardModal>
        </ModalContainer>
      )}
    </>
  );
};
const Container = styled.div`
  background: #000407;
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;
  width: 100%;
  min-height: 100vh;
`;
const TopContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  padding: 0 10%;
  width: 100%;
  max-width: 1600px;
  @media (max-width: 540px) {
    padding: 0 5%;
  }
`;
const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 10%;
  width: 100%;
  margin-top: 32px;
  gap: 16px;
  @media (max-width: 540px) {
    padding: 0 5%;
  }
`;
const RowInputs = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-bottom: 32px;
  gap: 16px;
  @media (max-width: 540px) {
    flex-direction: column;
  }
`;
const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 30%;
  gap: 8px;
  position: relative;
  @media (max-width: 540px) {
    width: 100%;
  }
`;
const Button = styled.button<{ disabled: boolean }>`
  background: ${(props) => (props.disabled ? "#9D9D9D" : "#2e6fff")};
  border: none;
  border-radius: 4px;
  color: var(--gray-white, #fff);
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  outline: none;
  cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
  transition: all 0.3s ease-in-out;
  text-align: center;
  width: 115px;
  height: 42px;
  &:hover {
    background: ${(props) => (props.disabled ? "#9D9D9D" : "#1d4ed8")};
  }
`;

const FadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;
const ModalContainer = styled.div`
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 101;
  display: flex;
  justify-content: center;
  align-items: center;
  animation: ${FadeIn} 0.5s ease-in-out;
  top: 0;
  left: 0;
`;
const CloseModal = styled.img`
  width: 24px;
  height: 24px;
  position: absolute;
  top: 30px;
  right: 30px;
  cursor: pointer;
`;
const BackgroundModal = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  background: black;
  opacity: 0;
  z-index: -1;
  transition: all 0.3s ease-in-out;
  &.show {
    opacity: 0.5;
    z-index: 101;
  }
`;

const CardModal = styled.div`
  width: 660px;
  height: 500px;
  border-radius: 10px;
  border: 2px solid var(--primario, #3876ff);
  display: flex;
  flex-direction: column;
  z-index: 102;
  background: #000407;
  position: relative;
  @media (max-width: 768px) {
    width: 95%;
  }
`;
const CardBottomContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 40px 60px;
  border-radius: 10px;
  gap: 32px;
  background: linear-gradient(135deg, rgba(15, 22, 33, 0.3) 0%, #0f1621 100%);
  backdrop-filter: blur(5px);
  @media (max-width: 768px) {
    padding: 40px 8px;
  }
`;
const SuscriberText = styled.p`
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  text-align: start;
  padding: 0 16px;
  margin-top: -16px;
  color: var(--gray-white, #fff);
  @media (max-width: 768px) {
    font-size: 14px;
  }
`;
export default NewsletterMobile;
