import styled, { css, keyframes } from "styled-components";
import { useRef, useLayoutEffect, useContext } from "react";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { Element } from "react-scroll";
import { sectionContext } from "../../context/sectionSelected";

const Product = () => {
  const video2Ref: any = useRef();
  const nameContainer: any = useRef();
  const { section } = useContext(sectionContext);
  useLayoutEffect(() => {
      gsap.registerPlugin(ScrollTrigger);
      ScrollTrigger.create({
        trigger: nameContainer.current,
        scrub: true,
        start: "top top",
        end: "+=250%",
        id: "pinProduct",
        pinnedContainer: "#product",
        onLeave: function (self) {
          const element = document.getElementById("pretank");
          if (element) {
            element.scrollIntoView();
            setTimeout(() => {
              element.scrollIntoView();
            }, 25);
          }
        },
        onUpdate: function (self) {
          if (video2Ref.current && video2Ref.current.duration) {
            let scrollPos = self.progress;
            let videoDuration = video2Ref.current.duration;
            let videoCurrentTime = videoDuration * scrollPos;
            if (scrollPos === 0) {
              video2Ref.current.currentTime = 0;
            } else if (videoCurrentTime) {
              video2Ref.current.currentTime = videoCurrentTime;
            } else {
              video2Ref.current.currentTime = video2Ref.current.duration;
            }
          }
        },
      });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Element name="#product">
      <Container2 ref={nameContainer} id="product">
        <ScrollContainer animated={section === "product"}>
          <Video
            ref={video2Ref}
            preload="auto"
            webkit-playsinline="true"
            muted
            src="/bion.mp4"
          />
        </ScrollContainer>
      </Container2>
    </Element>
  );
};
const BounceIn = keyframes`
0% {
    -webkit-transform: translateY(500px);
            transform: translateY(500px);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
    opacity: 0;
  }
  50% {
    -webkit-transform: translateY(-40px);
            transform: translateY(-40px);
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
    opacity: 1;
  }
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
  }
  `;
const Video = styled.video`
  position: absolute;
  max-width: 100%;
  max-height: 100%;
  object-fit: cover;
  z-index: -1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
`;
const ScrollContainer = styled.div<{ animated: boolean }>`
  position: sticky;
  top: 0;
  left: 0;
  width: 100%;
  height: 100px;
  min-height: 100vh;
  animation: ${({ animated }) =>
    animated
      ? css`
          ${BounceIn} 1.5s ease-in-out
        `
      : "none"};
`;
const Container2 = styled.div`
  display: flex;
  flex: 1;
  justify-content: start;
  flex-direction: column;
  position: relative;
  width: 100%;
  min-height: 350vh;
  background: #000407;
`;


export default Product;
