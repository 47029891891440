import React from 'react'

import ContextsProviders from '../context'
import StylesProvider from '../style'

import GlobalStyles from '../style/globalStyle'

import 'swiper/swiper.min.css'
import 'swiper/swiper-bundle.min.css'
import 'swiper/modules/navigation/navigation.min.css'
import 'swiper/modules/pagination/pagination.min.css'

const Providers: React.FC = ({ children }): JSX.Element => (
  <ContextsProviders>
    <StylesProvider>
      <GlobalStyles />

      {children}
    </StylesProvider>
  </ContextsProviders>
)

export default Providers
