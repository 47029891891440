import { useState } from "react";
import styled from "styled-components";
import ArrowDropDownIcon from "../assets/images/arrowDropDownIcon.svg";

export default function InputCombo({
  value,
  onChange,
  placeholder,
  acronimus,
  setAcronimus,
  height = 56,
  options,
  disabled,
}: {
  value: string;
  onChange: (e: any) => void;
  placeholder: string;
  acronimus: string;
  setAcronimus: (value: string) => void;
  height?: number;
  options: string[];
  disabled?: boolean;
}) {
  const [showDropdown, setShowDropdown] = useState<boolean>(false);
  function selectOption(option: string) {
    setAcronimus(option);
    setShowDropdown(false);
  }
  return (
    <InputContainer
      style={{
        maxHeight: "58px",
      }}
    >
      <DropDownField
        onClick={() => setShowDropdown(disabled ? false : !showDropdown)}
        style={{
          height: `${height}px`,
          zIndex: showDropdown ? 100 : 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <CurrentValueDropDown
          style={{
            paddingTop: "0px",
            fontSize: "16px",
          }}
        >
          {acronimus || "US"}
        </CurrentValueDropDown>
        <IconConatiner
          style={{
            transform: showDropdown ? "rotateZ(-180deg)" : "rotateZ(0deg)",
          }}
        >
          <img src={ArrowDropDownIcon} alt="arrow drop down icon" />
        </IconConatiner>
      </DropDownField>
      {showDropdown && (
        <DropDownItems>
          {options.map((d: any, index: number) => (
            <Option
              key={index}
              onClick={() => selectOption(d)}
              className={d === acronimus ? "selected" : ""}
            >
              {d}
            </Option>
          ))}
        </DropDownItems>
      )}
      <InputComponent
        value={value}
        onChange={onChange}
        placeholder={placeholder}
      />
    </InputContainer>
  );
}

const DropDownField = styled.div`
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-right: none;
  border-radius: 8px;
  padding: 16px 0;
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  cursor: pointer;
  position: absolute;
  color: var(--gray-2, #9d9d9d);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  overflow-y: hidden;
  background-color: #000407;
  z-index: 1;
  transition: all 0.3s ease-in-out;
  width: 100%;
  width: 64px;
  top: 1px;
  left: 1px;
  &:hover ~ input {
    border: 1px solid var(--primario, #f4f4f4);
  }
`;
const DropDownItems = styled.div`
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  z-index: 6;
  transition: all 0.4s;
  max-height: 220px;
  width: 55px;
  overflow-y: scroll;
  top: 63px;
  background-color: #000407;
  position: absolute;
  border: 1px solid var(--gray-3, #333);
  border-radius: 8px;
  &::-webkit-scrollbar {
    width: 0px;
    background: #000407;
  }
`;
const IconConatiner = styled.div`
  position: absolute;
  right: 6px;
  top: 19px;
  transform: rotateZ("0deg");
  transition: all 0.3s;
  width: 20px;
  height: 20px;
`;

const CurrentValueDropDown = styled.div`
  color: var(--gray-2, #9d9d9d);
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  padding-top: 10px;
  text-transform: capitalize;
  padding: 0 12px;
  @media (max-width: 768px) {
    font-family: Montserrat;
    min-height: 34px;
    font-size: 13px;
    line-height: 13px;
    padding-top: 10px;
  }
`;
const Option = styled.div`
  color: var(--white, #f4f4f4);
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  padding: 10px 14px;
  cursor: pointer;
  :hover {
    background: var(--gray-3, #333);
  }
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  :nth-last-child() {
    margin-bottom: 18px;
  }
  text-transform: capitalize;
  &.selected {
    background: var(--gray-3, #333);
  }
`;

const InputContainer = styled.div`
  position: relative;
  width: 100%;
`;
const InputComponent = styled.input`
  outline: none;
  border: 1px solid var(--gray-3, #333);
  border-radius: 8px;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  padding: 16px 12px;
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  color: var(--gray-2, #9d9d9d);
  transition: all 0.3s ease-in-out;
  background: transparent;
  width: 100%;
  padding-left: 66px;
  &::placeholder {
    color: var(--gray-2, #9d9d9d);
  }
  &:focus {
    border: 1px solid var(--primario, #3876ff);
  }
  &:hover:not(:focus) {
    border: 1px solid var(--white, #f4f4f4);
  }
`;
