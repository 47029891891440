import { Link } from "react-router-dom";
import styled from "styled-components";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import ArticleIcon from '@mui/icons-material/Article';
import SupportIcon from '@mui/icons-material/Support';
import WorkIcon from '@mui/icons-material/Work';
export default function Page({
  title,
  icon,
  addLink,
  isHalf,
  children,
}: {
  title?: string;
  icon?: string;
  addLink?: string;
  isHalf?: boolean;
  children: any;
}) {
  return (
    <PageContainer>
      <ContentContainer isHalf={isHalf}>
        <IconContainer>
          {icon === "users" && <PeopleAltIcon />}
          {icon === "press" && <ArticleIcon />}
          {icon === "support" && <SupportIcon />}
          {icon === "jobs" && <WorkIcon />}
        </IconContainer>
        {addLink && (
          <Link to={"/admin/" + addLink}>
            <LinkButton>+</LinkButton>
          </Link>
        )}
        {title && <H1>{title}</H1>}
        {children}
      </ContentContainer>
    </PageContainer>
  );
}
const H1 = styled.h1`
  color: #354b79;
`;
const PageContainer = styled.div`
  padding-top: 5rem;
`;
const ContentContainer = styled.div<{ isHalf?: boolean }>`
  position: relative;
  background: white;
  margin: auto;
  margin-bottom: 4rem;
  padding: 2rem;
  border-radius: 5px;
  width: ${(props) =>
    props.isHalf ? "min(500px, 100%)" : "min(1000px, 100%)"};
  box-shadow: var(--shadow);
`;
const IconContainer = styled.div`
  position: absolute;
  top: 0;
  width: 60px;
  height: 60px;
  background: var(--primary);
  border-radius: 5px;
  transform: translateY(-50%);
  display: flex;
  justify-content: center;
  align-items: center;
  background: #354b79;
  *{
    color: white;
  }
`;
const LinkButton = styled.a`
  height: 30px;
  width: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background: #e2e2e2;
  position: absolute;
  right: 2rem;
  cursor: pointer;
  color: #354b79;
  border: 1px solid #354b79;
`;
