import Providers from "./providers/providers";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./screens";
import Admin from "./admin";
import Press from "./screens/Press";
import JoinUs from "./joinus";
import LoginForm from "./admin/screens/LoginForm";
import PrivateRoute from "./routes/PrivateRoute";
import UsersList from "./admin/screens/UsersList";
import UsersForm from "./admin/screens/UsersForm";
import PressList from "./admin/screens/PressList";
import PressForm from "./admin/screens/PressForm";
import SupportList from "./admin/screens/SupportList";
import SupportForm from "./admin/screens/SupportForm";
import JobsList from "./admin/screens/JobsList";
import JobsForm from "./admin/screens/JobsForm";
import ReactGA from "react-ga4";
import PrivacyPolicy from "./screens/PrivacyPolicy";
const TRACKING_ID = "G-YT1RCFTLGS";
ReactGA.initialize(TRACKING_ID);

const App = () => {
  return (
    <Providers>
      <BrowserRouter basename="/">
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/press" element={<Press />} />
          <Route path="/joinus" element={<JoinUs />} />
          <Route path="/login" element={<LoginForm />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route
            path="/admin"
            element={
              <PrivateRoute>
                <Admin />
              </PrivateRoute>
            }
          >
            <Route index element={<UsersList />} />
            <Route path="users" element={<UsersList />} />
            <Route path="users/:uid" element={<UsersForm />} />
            <Route path="press" element={<PressList />} />
            <Route path="press/:uid" element={<PressForm />} />
            <Route path="jobs" element={<JobsList />} />
            <Route path="jobs/:uid" element={<JobsForm />} />
            <Route path="supports-partners" element={<SupportList />} />
            <Route path="supports-partners/:uid" element={<SupportForm />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </Providers>
  );
};

export default App;
