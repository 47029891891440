import { useDocumentDataOnce } from "react-firebase-hooks/firestore";
import { firestore } from "../../utils/firebase";
import firebase from "firebase/compat/app";
import Toast from "../../utils/toast";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";

interface Press {
  date: Date;
  title: string;
  description?: number;
  content?: string;
  link?: string;
  uid?: string;
  updated_at: any;
  created_at?: any;
}

export default function usePressForm() {
  const navigate = useNavigate();
  let { uid } = useParams();
  const add = uid === "add";
  const pressRef = firestore.doc("press/" + uid);
  const [loadedPress] = useDocumentDataOnce(pressRef);
  const [content, setContent] = useState("");
  const [withoutLink, setWithoutLink] = useState<boolean>(false);

  useEffect(() => {
    if (loadedPress) {
      setContent(loadedPress.content);
      if(loadedPress.content){
        setWithoutLink(true);
      }
    }
  }, [loadedPress]);

  const MySwal = withReactContent(Swal);


  const submit = async (event: any) => {
    event.preventDefault();
    MySwal.fire({
      title: "Guardando",
      showCancelButton: false,
      allowOutsideClick: false,
    });
    MySwal.showLoading();
    // destructuring form fields into variables. Order matters
    let [
      { value: date },
      { value: title },
      { value: description },
      { value: link },
    ] = event.target;
    if (withoutLink) {
      if (!date || !title || !content) {
        Toast.fire({
          icon: "error",
          text: "Por favor complete todos los campos",
        });
        setTimeout(() => {
          MySwal.close();
        }, 2000);
        return;
      }
      const currentTime = firebase.firestore.FieldValue.serverTimestamp();

      const press: Press = {
        date,
        title,
        content, // age needs to be converted from string
        updated_at: currentTime,
      };

      const collectionRef = firestore.collection("press");
      const promises = [];
      try {
        if (add) {
          press.created_at = currentTime;
          const pressSaved = await collectionRef.add(press)
          await pressSaved.update({ uid: pressSaved.id });
        } else {
          promises.push(pressRef.update(press));
        }
        await Promise.all(promises);
        MySwal.close();
        Toast.fire({ icon: "success", title: "Noticia guardada correctamente" });
        navigate("/admin/press");
      } catch (error) {
        MySwal.close();
        Toast.fire({
          icon: "error",
          title: "Ocurrió un error al guardar la noticia",
        });
        console.log("Error", error);
      }
    } else {
      if (!date || !title || !description || !link) {
        Toast.fire({
          icon: "error",
          text: "Por favor complete todos los campos",
        });
        setTimeout(() => {
          MySwal.close();
        }, 2000);
        return;
      }
      const currentTime = firebase.firestore.FieldValue.serverTimestamp();

      const press: Press = {
        date,
        title,
        description, // age needs to be converted from string
        link,
        updated_at: currentTime,
      };

      const collectionRef = firestore.collection("press");
      const promises = [];
      try {
        if (add) {
          press.created_at = currentTime;
          const pressSaved = await collectionRef.add(press)
          await pressSaved.update({ uid: pressSaved.id });
        } else {
          promises.push(pressRef.update(press));
        }
        await Promise.all(promises);
        MySwal.close();
        Toast.fire({ icon: "success", title: "Noticia guardada correctamente" });
        navigate("/admin/press");
      } catch (error) {
        MySwal.close();
        Toast.fire({
          icon: "error",
          title: "Ocurrió un error al guardar la noticia",
        });
        console.log("Error", error);
      }
    }


  };

  return {
    add,
    submit,
    loadedPress,
    content,
    setContent,
    withoutLink,
    setWithoutLink
  }
}
