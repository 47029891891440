import Page from "../../commonComponents/Page";
import styled from "styled-components";
import usePressList from "../hooks/usePressList";
import { Link } from "react-router-dom";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";

export default function PressList() {
  const { press, loading, error, handleDelete } = usePressList();

  return (
    <Page title="News" icon="press" addLink="press/add">
      <Table>
        <Thead>
          <Tr>
            <Th>Date</Th>
            <Th>Title</Th>
            <Th>Description</Th>
            <Th>Link</Th>
            <Th>Actions</Th>
          </Tr>
        </Thead>
        <Tbody>
          {press?.length &&
            press.map((pressArticle) => (
              <Tr key={pressArticle.uid}>
                <Td>{pressArticle.date}</Td>
                <Td>{pressArticle.title}</Td>
                <Td>{pressArticle.description}</Td>
                <Td>{pressArticle.link}</Td>
                <Td>
                  <Link to={"/admin/press/" + pressArticle.uid}>
                    <Span>
                      <EditIcon
                        style={{
                          fill: "#354b79",
                        }}
                      />
                    </Span>
                  </Link>
                  <Span
                    onClick={() => {
                      handleDelete(pressArticle.uid);
                    }}
                  >
                    <DeleteIcon
                      style={{
                        fill: "#354b79",
                      }}
                    />
                  </Span>
                </Td>
              </Tr>
            ))}
          {!loading && !press?.length && (
            <Tr>
              <Td>No news found</Td>
            </Tr>
          )}
          {loading && (
            <Tr>
              <Td>Loading news...</Td>
            </Tr>
          )}
          {error && (
            <Tr>
              <Td>An error occurred while loading the news</Td>
            </Tr>
          )}
        </Tbody>
      </Table>
    </Page>
  );
}

const Table = styled.table`
  width: 100%;
  color: #354b79;
`;
const Tr = styled.tr`
  color: #354b79;
`;
const Th = styled.th`
  padding: 0.5rem;
  color: #354b79;
`;
const Td = styled.td`
  padding: 0.5rem;
  color: #354b79;
`;
const Thead = styled.thead``;
const Tbody = styled.tbody`
  & ${Tr}:nth-of-type(even) {
    background-color: white;
  }
  & ${Tr}:nth-of-type(odd) {
    background-color: rgba(0, 0, 0, 0.05);
  }
`;
const Span = styled.span`
  color: #354b79;
  cursor: pointer;
`;
