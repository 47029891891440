import styled from "styled-components";

export default function TextArea({
  value,
  onChange,
  placeholder,
}: {
  value: string;
  onChange: (e: any) => void;
  placeholder: string;
}) {
  return (
    <TextAreaComponent
      value={value}
      onChange={onChange}
      placeholder={placeholder}
    />
  );
}

const TextAreaComponent = styled.textarea`
  outline: none;
  border: 1px solid var(--gray-3, #333);
  border-radius: 8px;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  padding: 16px 12px;
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  color: var(--gray-2, #9d9d9d);
  transition: all 0.3s ease-in-out;
  background: transparent;
  width: 100%;
  resize: none;
  &::placeholder {
    color: var(--gray-2, #9d9d9d);
  }
  &:focus {
    border: 1px solid var(--primario, #3876ff);
  }
  &:hover:not(:focus) {
    border: 1px solid var(--white, #f4f4f4);
  }
  &::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }
  &::-webkit-scrollbar-track {
    background: transparent;
  }
  &::-webkit-scrollbar-thumb {
    background-color: var(--primario, #3876ff);
    border-radius: 20px;
    border: 3px solid var(--primario, #3876ff);
  }
  &::-webkit-scrollbar-thumb:hover {
    background-color: var(--primario, #3876ff);
  }
  &::-webkit-scrollbar-thumb:active {
    background-color: var(--primario, #3876ff);
  }
  &::-webkit-scrollbar-corner {
    background-color: transparent;
  }
  &::-webkit-scrollbar-resizer {
    background-color: transparent;
  }
  &::-webkit-scrollbar-button {
    display: none;
  }
  &::-webkit-scrollbar-button:start:decrement,
  &::-webkit-scrollbar-button:end:increment {
    height: 0;
    background-color: transparent;
  }
`;
