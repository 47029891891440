import { useRef, useState, useLayoutEffect,useContext } from "react";
import { Element } from "react-scroll";
import styled, { keyframes } from "styled-components";
import { Navigation, Pagination, Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react/swiper-react.js";
import Chevron from "../../assets/images/chevron.svg";
import SwiperCore from "swiper";
import ViewMoreIcon from "../../assets/images/viewMoreIcon.svg";
import CloseModalIcon from "../../assets/images/closeModalIcon.svg";
import LinkIcon from "../../assets/images/linkIcon.svg";
import {
  BodyLarge,
  BodyMedium,
  Heading2,
  TitleMedium,
  TitleSmall,
} from "../../commonComponents/Text";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { useNavigate } from "react-router-dom";
import { useCollectionData } from "react-firebase-hooks/firestore";
import { firestore } from "../../utils/firebase";
import { sectionContext } from "../../context/sectionSelected";
export interface PressArticle {
  id: number;
  date: string;
  title: string;
  description: string;
  link?: string;
}
SwiperCore.use([Pagination, Navigation, Autoplay]);

const PressCards = () => {
  const mainContainer: any = useRef();
  const titleRef: any = useRef();
  const pressRef: any = useRef();
  const ellipseRef: any = useRef();
  const [cardSelected, setCardSelected] = useState<number>(0);
  const navigate = useNavigate();
  const pressFierstoreRef = firestore
    .collection("press")
    .orderBy("date", "desc");
  const [pressCards, ,] = useCollectionData(pressFierstoreRef);
  const { setShowButton } = useContext(sectionContext);


  useLayoutEffect(() => {
    //progress video with gsap
    gsap.registerPlugin(ScrollTrigger);
    ScrollTrigger.create({
      trigger: mainContainer.current,
      scrub: true,
      start: "top top",
      end: "+=100%",
      id: "pinPress",
      pinnedContainer: "#press",
      onEnter: function (self) {
        setShowButton(true);
      },
      onUpdate: function (self) {
        if (mainContainer.current) {
          let scrollPos = self.progress;
          if (scrollPos === 0) {
            titleRef.current.style.opacity = 0;
            pressRef.current.style.opacity = 0;
            pressRef.current.style.top = "100vh";
            ellipseRef.current.style.opacity = 0;
          } else if (scrollPos > 0 && scrollPos < 0.5) {
            if (scrollPos > 0 && scrollPos < 0.3) {
              fadeInEllipse(ellipseRef, scrollPos);
            }
            fadeInTitle(titleRef, scrollPos);
          } else if (scrollPos >= 0.5 && scrollPos < 0.6) {
          } else if (scrollPos >= 0.6 && scrollPos < 1) {
            slideInPressCards(pressRef, scrollPos - 0.6);
          } else {
            pressRef.current.style.top = "25vh";
            pressRef.current.style.opacity = 1;
            ellipseRef.current.style.opacity = 1;
          }
          if (scrollPos > 0.3) {
            ellipseRef.current.style.opacity = 1;
          }
          if (scrollPos > 0.5) {
            titleRef.current.style.opacity = 1;
          }
          if (scrollPos < 0.6) {
            pressRef.current.style.opacity = 0;
            pressRef.current.style.top = "100vh";
          }
        }
      },
    });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  function fadeInTitle(container: any, progress: number) {
    container.current.style.opacity = progress * 2.1;
  }
  function slideInPressCards(container: any, progress: number) {
    container.current.style.top = `${100 - progress * 188}vh`;
    container.current.style.opacity = progress * 2.6;
  }
  function fadeInEllipse(ellipse: any, progress: number) {
    ellipse.current.style.opacity = progress * 3.4;
  }
  return (
    <Element name="#press">
      <Container ref={mainContainer} id="press">
        <ScrollContainer animated={false}>
          <EllipseContainer ref={ellipseRef}>
            <Ellipse />
          </EllipseContainer>
          <TitleContainer ref={titleRef}>
            <Heading2
              isSecondary
              style={{
                textTransform: "uppercase",
                textShadow:
                  "0px 0px 10px rgba(56, 118, 255, 0.60), 0px 0px 4px rgba(56, 118, 255, 0.25)",
              }}
            >
              News
            </Heading2>
          </TitleContainer>
          <PressContainer ref={pressRef}>
            <ViewMoreContainer>
              <div
                onClick={() => {
                  navigate("/press");
                }}
                style={{
                  cursor: "pointer",
                }}
              >
                <BodyLarge isSecondary>View more</BodyLarge>
              </div>
              <ViewMoreSVG src={ViewMoreIcon} alt="View More Icon" />
            </ViewMoreContainer>
            <SwiperWrapper slidesPerView={4} className="mySwiper2">
              {pressCards &&
                pressCards.map((pressArticle, index) => (
                  <SwiperSlide style={{ color: "white" }} key={index + 1}>
                    <Card>
                      <TitleMedium
                        style={{
                          textTransform: "uppercase",
                          textAlign: "start",
                          width: "100%",
                          fontSize: "18px",
                          height: "100%",
                        }}
                        isSecondary
                      >
                        {pressArticle.title.length > 80
                          ? pressArticle.title.slice(0, 80) + "..."
                          : pressArticle.title}
                      </TitleMedium>
                      <TitleSmall
                        style={{
                          textTransform: "uppercase",
                          textAlign: "start",
                          width: "100%",
                          lineHeight: "20px",
                        }}
                      >
                        {pressArticle.date}
                      </TitleSmall>
                      <BottomContainer>
                        {pressArticle.content ? (
                          <ReadMoreText
                            onClick={() => setCardSelected(index + 1)}
                          >
                            <p>Read more</p>
                            <img src={Chevron} alt="chevron" />
                          </ReadMoreText>
                        ) : (
                          <ReadMoreText
                            href={pressArticle.link}
                            target="_blank"
                          >
                            <p>Read more</p>
                            <img src={Chevron} alt="chevron" />
                          </ReadMoreText>
                        )}
                      </BottomContainer>
                    </Card>
                  </SwiperSlide>
                ))}
            </SwiperWrapper>
          </PressContainer>
        </ScrollContainer>
        {cardSelected !== 0 && (
          <ModalContainer>
            <BackgroundModal
              onClick={() => setCardSelected(0)}
              className={cardSelected !== 0 ? "show" : ""}
            ></BackgroundModal>
            <CardModal>
              <CardBottomContainer>
                <div>
                  <TitleMedium
                    isSecondary
                    style={{
                      textTransform: "uppercase",
                      textAlign: "start",
                      width: "100%",
                    }}
                  >
                    {pressCards && pressCards[cardSelected - 1].title}{" "}
                  </TitleMedium>
                  <BodyMedium
                    style={{
                      textAlign: "start",
                      width: "100%",
                      textTransform: "uppercase",
                    }}
                  >
                    {pressCards && pressCards[cardSelected - 1].date}
                  </BodyMedium>
                </div>

                <HtmlContainer
                  dangerouslySetInnerHTML={
                    pressCards && {
                      __html: pressCards[cardSelected - 1].content || "",
                    }
                  }
                ></HtmlContainer>
                {pressCards && pressCards[cardSelected - 1].link && (
                  <LinkContainer>
                    <a
                      href={pressCards && pressCards[cardSelected - 1].link}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <BodyMedium
                        style={{
                          fontSize: "16px",
                          lineHeight: "24px",
                        }}
                        isSecondary
                      >
                        View note
                      </BodyMedium>
                      <img
                        style={{
                          width: "16px",
                          height: "16px",
                        }}
                        src={LinkIcon}
                        alt="Link Icon"
                      />
                    </a>
                  </LinkContainer>
                )}
              </CardBottomContainer>
              <CloseModal
                src={CloseModalIcon}
                onClick={() => setCardSelected(0)}
              />
            </CardModal>
          </ModalContainer>
        )}
      </Container>
    </Element>
  );
};
const FadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;
const LinkContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  a {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 20px;
    cursor: pointer;
    text-decoration: none;
    p {
      color: var(--primario, #3876ff);
      font-family: Montserrat;
      font-size: 24px;
      font-style: normal;
      font-weight: 600;
      line-height: 40px;
    }
  }
`;
const ModalContainer = styled.div`
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 101;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  animation: ${FadeIn} 0.5s ease-in-out;
  padding-bottom: 32px;
`;
const CloseModal = styled.img`
  width: 24px;
  height: 24px;
  position: absolute;
  top: 30px;
  right: 30px;
  cursor: pointer;
`;
const BackgroundModal = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  background: black;
  opacity: 0;
  z-index: -1;
  transition: all 0.3s ease-in-out;
  &.show {
    opacity: 0.5;
    z-index: 101;
  }
`;

const CardModal = styled.div`
  width: 1000px;
  height: 500px;
  border-radius: 10px;
  border: 2px solid var(--primario, #3876ff);
  display: flex;
  flex-direction: column;
  z-index: 102;
  background: #000407;
  position: relative;
`;
const CardBottomContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 40px 60px;
  border-radius: 10px;
  background: linear-gradient(135deg, rgba(15, 22, 33, 0.3) 0%, #0f1621 100%);
  backdrop-filter: blur(5px);
  overflow: scroll;
  ::-webkit-scrollbar {
    width: 0px;
  }
`;
const ViewMoreContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  cursor: pointer;
  width: 100%;
  padding-right: 5vw;
`;
const ViewMoreSVG = styled.img`
  margin-left: 8px;
`;
const TitleContainer = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  margin: auto;
  z-index: 1;
  height: 30vh;
  opacity: 0;
  transition: all 0.1s ease;
`;
const PressContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: absolute;
  top: 100vh;
  left: 0;
  right: 0;
  margin: auto;
  z-index: 1;
  height: 75vh;
  gap: 16px;
  transition: all 0.1s ease;
  opacity: 0;
`;
const ScrollContainer = styled.div<{ animated: boolean }>`
  position: sticky;
  top: 0;
  left: 0;
  width: 100%;
  height: 100px;
  min-height: 100vh;
  overflow: hidden;
`;
const Container = styled.div`
  background: #000407;
  display: flex;
  flex: 1;
  justify-content: start;
  align-items: center;
  flex-direction: column;
  position: relative;
  width: 100%;
  height: 200vh;
  .swiper-wrapper {
    height: 360px;
    gap: 16px;
    max-height: 55vh;
  }
`;
const SwiperWrapper = styled(Swiper)`
  width: calc(90% - 64px);
  padding: 0 32px;
  max-height: 360px;
  width: 100%;
  @media (max-width: 480px) {
    margin: 0;
    padding: 0px;
    width: calc(100% - 32px);
  }
`;
const Card = styled.div`
  display: flex;
  flex: 1;
  justify-content: flex-end;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding: 36px 24px;
  cursor: grab;
  background: linear-gradient(
    162deg,
    rgba(255, 254, 254, 0.13) 13.02%,
    rgba(255, 254, 254, 0) 100%
  );
  border: 2px solid #353738;
  box-shadow: 0px 4px 5px 0px #000;
  border-radius: 24px;
`;
const ReadMoreText = styled.a`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  gap: 16px;
  text-decoration: none;
  cursor: pointer;
  p {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    text-align: right;
    color: #dfdfdf;
    text-align: start;
  }
`;
const BottomContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
`;
const EllipseContainer = styled.div`
  position: absolute;
  width: 60vw;
  height: 60vw;
  left: -20%;
  top: -54%;
  transition: all 0.1s ease;
  opacity: 0;
`;
const Ellipse = styled.div`
  background: radial-gradient(
    50% 50% at 50% 50%,
    rgba(73, 149, 182, 0.29) 0%,
    rgba(108, 201, 242, 0) 100%
  );
  filter: blur(24.733078002929688px);
  position: absolute;
  width: 60vw;
  height: 60vw;
`;
const HtmlContainer = styled.div`
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  ::-webkit-scrollbar {
    width: 10px;
  }
  ::-webkit-scrollbar-track {
    background: rgba(150, 150, 150, 0.1);
  }
  ::-webkit-scrollbar-thumb {
    background: #5b8bf4;
    height: 40px;
    border-radius: 10px;
  }
  ::-webkit-scrollbar-thumb:hover {
    background: #2e3fff;
  }
  p {
    font-family: Montserrat;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    text-align: start;
    color: #dfdfdf;
  }
  a {
    color: #dfdfdf;
    text-decoration: underline;
  }
  img {
    width: 100%;
    height: auto;
  }
  ul {
    list-style-type: none;
    padding-left: 0;
    li {
      font-family: Montserrat;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 24px;
      text-align: start;
      color: #dfdfdf;
      margin-bottom: 10px;
      &:before {
        content: "•";
        color: #dfdfdf;
        display: inline-block;
        width: 1em;
        margin-left: -1em;
      }
    }
  }
  * {
    color: #dfdfdf;
  }
`;

export default PressCards;
