import { useLayoutEffect, useContext, useState } from "react";
import Header from "../components/Header";
import { sectionContext } from "../context/sectionSelected";
import styled, { keyframes } from "styled-components";
import Chevron from "../assets/images/chevron.svg";
import CloseModalIcon from "../assets/images/closeModalIcon.svg";
import LinkIcon from "../assets/images/linkIcon.svg";
import {
  BodyLarge,
  BodyMedium,
  BodySmallRegular,
  Heading2,
  TitleLarge,
  TitleMedium,
  TitleSmall,
} from "../commonComponents/Text";
import HeaderResponsive from "../components/HeaderResponsive";
import FooterResponsive from "../components/FooterResponsive";
import Footer from "../components/Footer";
import BackIcon from "../assets/images/backIcon.svg";
import useWindowSize from "../hooks/useWindowSize";

const PrivacyPolicy = () => {
  const { width } = useWindowSize();
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      {width > 768 && (
        <>
          <Header/>
          <MiddleContainer fade={true}>
            <TextContainer>
              <TitleLarge
                style={{
                  witdth: "100%",
                  textAlign: "center",
                }}
              >
                Privacy Policy
              </TitleLarge>
              <BodyMedium
                style={{
                  marginTop: "24px",
                }}
              >
                Effective Date: 17/10/2023
              </BodyMedium>
              <BodyMedium
                style={{
                  marginTop: "24px",
                }}
              >
                1. Introduction
              </BodyMedium>
              <BodyMedium>
                We respect your privacy and are committed to protecting your
                personal information. This Privacy Policy is designed to help
                you understand how we collect, use, and safeguard your email
                address and other sensitive data when you visit our website and
                use our services.
              </BodyMedium>
              <BodyMedium
                style={{
                  marginTop: "24px",
                }}
              >
                2. Information We Collect
              </BodyMedium>
              <BodyMedium>
                a. Email Address: We may collect and store your email address
                when you provide it to us, such as when you subscribe to our
                newsletter or contact us through our website.
              </BodyMedium>
              <BodyMedium
                style={{
                  marginTop: "24px",
                }}
              >
                3. How We Use Your Email Address
              </BodyMedium>
              <BodyMedium>
                We use your email address for the following purposes:
              </BodyMedium>
              <BodyMedium>
                a. Communication: We may use your email address to send you
                updates, newsletters, or other information related to our
                products, services, or promotions.
              </BodyMedium>
              <BodyMedium>
                b. Customer Support: If you contact us with a question or
                request for support, we may use your email address to respond to
                your inquiry.
              </BodyMedium>
              <BodyMedium>
                c. Marketing: With your consent, we may use your email address
                for marketing purposes, such as sending you promotional offers,
                product updates, and other relevant content.
              </BodyMedium>
              <BodyMedium
                style={{
                  marginTop: "24px",
                }}
              >
                4. Sharing Your Email Address
              </BodyMedium>
              <BodyMedium>
                We do not sell, trade, or rent your email address to third
                parties. Your email address is used solely for the purposes
                outlined in this Privacy Policy.
              </BodyMedium>
              <BodyMedium
                style={{
                  marginTop: "24px",
                }}
              >
                5. Data Security
              </BodyMedium>
              <BodyMedium>
                We are committed to protecting your email address and sensitive
                data. We implement reasonable security measures to safeguard
                your information from unauthorized access, disclosure,
                alteration, and destruction.
              </BodyMedium>
              <BodyMedium
                style={{
                  marginTop: "24px",
                }}
              >
                6. Your Choices
              </BodyMedium>
              <BodyMedium>
                a. Opt-Out: You may choose not to receive marketing emails from
                us by using the opt-out or unsubscribe option provided in our
                communications.
              </BodyMedium>
              <BodyMedium>
                b. Access and Update: You have the right to access and update
                your email address in our records. If you would like to do so,
                please contact us at support@cultzyme.com.
              </BodyMedium>
              <BodyMedium
                style={{
                  marginTop: "24px",
                }}
              >
                7. Changes to this Privacy Policy
              </BodyMedium>
              <BodyMedium>
                We may update this Privacy Policy to reflect changes in our
                practices or for other operational, legal, or regulatory
                reasons. We encourage you to review this Privacy Policy
                periodically.
              </BodyMedium>
              <BodyMedium
                style={{
                  marginTop: "24px",
                }}
              >
                8. Contact Us
              </BodyMedium>
              <BodyMedium>
                If you have any questions or concerns about our Privacy Policy
                or the handling of your email address, please contact us at
                support@cultzyme.com.
              </BodyMedium>
            </TextContainer>
          </MiddleContainer>
          <Footer />
        </>
      )}
      {width < 480 && (
        <>
          <HeaderResponsive />
          <MiddleContainer fade={true}>
            <TextContainer>
              <TitleMedium
                style={{
                  witdth: "100%",
                  textAlign: "center",
                }}
              >
                Privacy Policy
              </TitleMedium>
              <BodyMedium
                style={{
                  marginTop: "24px",
                }}
              >
                Effective Date: 17/10/2023
              </BodyMedium>
              <BodyMedium
                style={{
                  marginTop: "24px",
                }}
              >
                1. Introduction
              </BodyMedium>
              <BodyMedium>
                We respect your privacy and are committed to protecting your
                personal information. This Privacy Policy is designed to help
                you understand how we collect, use, and safeguard your email
                address and other sensitive data when you visit our website and
                use our services.
              </BodyMedium>
              <BodyMedium
                style={{
                  marginTop: "24px",
                }}
              >
                2. Information We Collect
              </BodyMedium>
              <BodyMedium>
                a. Email Address: We may collect and store your email address
                when you provide it to us, such as when you subscribe to our
                newsletter or contact us through our website.
              </BodyMedium>
              <BodyMedium
                style={{
                  marginTop: "24px",
                }}
              >
                3. How We Use Your Email Address
              </BodyMedium>
              <BodyMedium>
                We use your email address for the following purposes:
              </BodyMedium>
              <BodyMedium>
                a. Communication: We may use your email address to send you
                updates, newsletters, or other information related to our
                products, services, or promotions.
              </BodyMedium>
              <BodyMedium>
                b. Customer Support: If you contact us with a question or
                request for support, we may use your email address to respond to
                your inquiry.
              </BodyMedium>
              <BodyMedium>
                c. Marketing: With your consent, we may use your email address
                for marketing purposes, such as sending you promotional offers,
                product updates, and other relevant content.
              </BodyMedium>
              <BodyMedium
                style={{
                  marginTop: "24px",
                }}
              >
                4. Sharing Your Email Address
              </BodyMedium>
              <BodyMedium>
                We do not sell, trade, or rent your email address to third
                parties. Your email address is used solely for the purposes
                outlined in this Privacy Policy.
              </BodyMedium>
              <BodyMedium
                style={{
                  marginTop: "24px",
                }}
              >
                5. Data Security
              </BodyMedium>
              <BodyMedium>
                We are committed to protecting your email address and sensitive
                data. We implement reasonable security measures to safeguard
                your information from unauthorized access, disclosure,
                alteration, and destruction.
              </BodyMedium>
              <BodyMedium
                style={{
                  marginTop: "24px",
                }}
              >
                6. Your Choices
              </BodyMedium>
              <BodyMedium>
                a. Opt-Out: You may choose not to receive marketing emails from
                us by using the opt-out or unsubscribe option provided in our
                communications.
              </BodyMedium>
              <BodyMedium>
                b. Access and Update: You have the right to access and update
                your email address in our records. If you would like to do so,
                please contact us at support@cultzyme.com.
              </BodyMedium>
              <BodyMedium
                style={{
                  marginTop: "24px",
                }}
              >
                7. Changes to this Privacy Policy
              </BodyMedium>
              <BodyMedium>
                We may update this Privacy Policy to reflect changes in our
                practices or for other operational, legal, or regulatory
                reasons. We encourage you to review this Privacy Policy
                periodically.
              </BodyMedium>
              <BodyMedium
                style={{
                  marginTop: "24px",
                }}
              >
                8. Contact Us
              </BodyMedium>
              <BodyMedium>
                If you have any questions or concerns about our Privacy Policy
                or the handling of your email address, please contact us at
                support@cultzyme.com.
              </BodyMedium>
            </TextContainer>
          </MiddleContainer>
          <FooterResponsive />
        </>
      )}
    </>
  );
};
const FadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;
const FadeOut = keyframes`
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
`;
const MiddleContainer = styled.div<{ fade: boolean }>`
  animation: ${(props) => (props.fade ? FadeOut : FadeIn)} 0.5s ease-in-out;
`;
const TextContainer = styled.div`
  width: 100%;
  max-width: 1440px;
  margin: 0 auto;
  padding: 32px 24px;
  padding-top: 100px;
  min-height: calc(100vh - 80px);
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  @media (max-width: 768px) {
    padding: 32px 16px;
    padding-top: 60px;
  }
`;

export default PrivacyPolicy;
