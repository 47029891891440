import styled from "styled-components";
import { useRef, useLayoutEffect, useState, useContext } from "react";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { sectionContext } from "../../context/sectionSelected";

const Tank = () => {
  const containerRef: any = useRef();
  const tankVideoRef: any = useRef();
  const tank2VideoRef: any = useRef();
  const firstSceneRef: any = useRef();
  const secondtSceneRef: any = useRef();
  const firstTextRef: any = useRef();
  const secondTextRef: any = useRef();
  const thirdTextRef: any = useRef();
  const fourthTextRef: any = useRef();
  // const fifthTextRef: any = useRef();
  const [loadMetadata, setLoadMetadata] = useState<boolean>(false);
  const { setShowButton } = useContext(sectionContext);

  useLayoutEffect(() => {
    if (loadMetadata) {
      if (tankVideoRef.current) {
        gsap.registerPlugin(ScrollTrigger);
        ScrollTrigger.create({
          trigger: containerRef.current,
          scrub: true,
          start: "top top",
          end: "+=300%",
          id: "pinTank",
          pinnedContainer: "#tank",
          onLeaveBack: function (self) {
            setShowButton(false);
            const element = document.getElementById("tank")?.offsetTop;
            if (element) {
              window.scrollTo({
                top: element - window.innerHeight,
              });
            }
          },
          onEnter: function (self) {
            setShowButton(true);
          },
          onUpdate: function (self) {
            if (tankVideoRef.current && tankVideoRef.current.duration) {
              let scrollPos = self.progress;
              let videoDuration = tankVideoRef.current.duration;
              let video2Duration = tank2VideoRef.current.duration;
              let videoCurrentTime = videoDuration * scrollPos * 1.4;
              let video2CurrentTime = video2Duration * (scrollPos - 0.7) * 3.35;
              if (scrollPos === 0) {
                tankVideoRef.current.currentTime = 0;
              } else if (scrollPos > 0 && scrollPos < 0.7) {
                if (videoCurrentTime)
                  tankVideoRef.current.currentTime = videoCurrentTime;
              } else if (scrollPos >= 0.7 && scrollPos < 1) {
                if (video2CurrentTime)
                  tank2VideoRef.current.currentTime = video2CurrentTime;
              } else {
                tankVideoRef.current.currentTime =
                  tankVideoRef.current.duration;
                tank2VideoRef.current.currentTime =
                  tank2VideoRef.current.duration;
              }
            }
            if (firstTextRef.current) {
              let scrollPos = self.progress;
              if (scrollPos === 0) {
                tankVideoRef.current.style.transform = "translateX(-400px)";
                tankVideoRef.current.style.opacity = 1;
                firstSceneRef.current.style.opacity = 1;
                firstTextRef.current.style.opacity = 1;
                secondTextRef.current.style.opacity = 0.3;
                thirdTextRef.current.style.opacity = 0.3;
                secondtSceneRef.current.style.opacity = 0;
                fourthTextRef.current.style.opacity = 1;
                // fifthTextRef.current.style.opacity = 0.3;
              } else if (scrollPos > 0 && scrollPos < 0.2) {
                fadeOutText(firstTextRef, scrollPos);
                fadeInText(secondTextRef, scrollPos);
                slideFirstScene(firstTextRef, scrollPos, 0);
              } else if (scrollPos >= 0.2 && scrollPos < 0.4) {
                fadeOutText(secondTextRef, scrollPos - 0.2);
                fadeInText(thirdTextRef, scrollPos - 0.2);
                slideFirstScene(firstTextRef, scrollPos - 0.3, 15);
              } else if (scrollPos >= 0.4 && scrollPos < 0.55) {
                fadeOutContainer(firstSceneRef, scrollPos - 0.4);
                slideTankRight(tankVideoRef, scrollPos - 0.4);
              } else if (scrollPos >= 0.55 && scrollPos < 0.7) {
                slideTankRight(tankVideoRef, scrollPos - 0.4);
                fadeInContainer(secondtSceneRef, scrollPos - 0.55);
                fadeInSecondTank(tank2VideoRef, scrollPos - 0.55);
              } else if (scrollPos >= 0.7 && scrollPos < 0.9) {
                fadeOutText(fourthTextRef, scrollPos - 0.7);
                // fadeInText(fifthTextRef, scrollPos - 0.7);
                slideFirstScene(fourthTextRef, scrollPos - 0.7, 0);
              } else if (scrollPos >= 0.9 && scrollPos < 1) {
              } else {
                firstSceneRef.current.style.opacity = 0;
                firstTextRef.current.style.opacity = 0.3;
                secondTextRef.current.style.opacity = 0.3;
                thirdTextRef.current.style.opacity = 1;
                secondtSceneRef.current.style.opacity = 1;
                fourthTextRef.current.style.opacity = 0.3;
                // fifthTextRef.current.style.opacity = 1;
                tankVideoRef.current.style.transform = "translateX(300px)";
                tankVideoRef.current.style.opacity = 0;
                tank2VideoRef.current.style.opacity = 1;
              }
              if (scrollPos > 0.7) {
                tankVideoRef.current.style.opacity = 0;
              }
              if (scrollPos > 0.25) {
                firstTextRef.current.style.opacity = 0.3;
              }
              if (scrollPos < 0.4) {
                tankVideoRef.current.style.transform = "translateX(-400px)";
              }
              if (scrollPos < 0.55) {
                tank2VideoRef.current.style.opacity = 0;
                secondtSceneRef.current.style.opacity = 0;
              }
              if (scrollPos >= 0.55) {
                firstSceneRef.current.style.opacity = 0;
              }
              if (scrollPos > 0.65) {
                firstSceneRef.current.style.opacity = 0;
                firstTextRef.current.style.opacity = 0.3;
                secondTextRef.current.style.opacity = 0.3;
                thirdTextRef.current.style.opacity = 1;
                firstTextRef.current.style.marginTop = "-30px";
              }
            }
          },
        });
      }
    }
  }, [loadMetadata]); // eslint-disable-line react-hooks/exhaustive-deps

  function fadeOutContainer(containerRef: any, progress: number) {
    containerRef.current.style.opacity = 1 - progress * 7;
  }
  function fadeInContainer(containerRef: any, progress: number) {
    containerRef.current.style.opacity = progress * 7;
  }
  function fadeOutText(textRef: any, progress: number) {
    textRef.current.style.opacity = 1 - progress * 3.6;
  }
  function fadeInText(textRef: any, progress: number) {
    textRef.current.style.opacity = 0.3 + progress * 3.6;
  }
  function slideFirstScene(textRef: any, progress: number, top: number) {
    textRef.current.style.marginTop = `-${top + progress * 75}px`;
  }
  function slideTankRight(containerRef: any, progress: number) {
    containerRef.current.style.transform = `translateX(${
      progress * 1835 - 300
    }px)`;
    containerRef.current.style.opacity = 1 - progress * 5.5;
  }
  function fadeInSecondTank(containerRef: any, progress: number) {
    containerRef.current.style.opacity = progress * 7;
  }

  return (
    <Container ref={containerRef} id="tank">
      <ScrollContainer>
        <Video
          ref={tankVideoRef}
          preload="auto"
          webkit-playsinline="true"
          muted
          src="/tank.mp4"
          onLoadedMetadata={() => setLoadMetadata(true)}
        />
        <Video2
          ref={tank2VideoRef}
          preload="auto"
          webkit-playsinline="true"
          muted
          src="/tankmagn.mp4"
        />
        <FirstScene ref={firstSceneRef}>
          <TitleLarge ref={firstTextRef}>
            Unlock real-time information
            <br />
            without the necessity of extractions.
          </TitleLarge>
          <TitleLarge ref={secondTextRef}>Simplified scale up to</TitleLarge>
          <TitleLarge ref={thirdTextRef}>The volume you determine.</TitleLarge>
        </FirstScene>
        <SecondScene ref={secondtSceneRef}>
          <TitleLarge ref={fourthTextRef} style={{ textAlign: "start" }}>
            With just a click, reduce
            <br />
            experiment design.
            <br />
            Our cutting-edge tech streamlines bioprocessing for high-quality
            biotech production.
          </TitleLarge>
          {/* <TitleLarge ref={fifthTextRef} style={{ textAlign: "start" }}>
            BION is where the future of
            <br />
            biofabrication begins.
          </TitleLarge> */}
        </SecondScene>
      </ScrollContainer>
    </Container>
  );
};

const Video = styled.video`
  position: absolute;
  object-fit: cover;
  z-index: -1;
  width: 75vw;
  height: 75vh;
  transition: all 0.1s ease;
  left: 0;
  right: 0;
  margin: auto;
  top: 10%;
  bottom: 0;
  transform: translateX(-400px);
`;
const Video2 = styled.video`
  position: absolute;
  object-fit: cover;
  z-index: -1;
  width: 75vw;
  height: 75vh;
  opacity: 0;
  transition: all 0.1s ease;
  left: 0;
  right: 0;
  margin: auto;
  top: 10%;
  bottom: 0;
  transform: translateX(300px);
`;
const ScrollContainer = styled.div`
  position: sticky;
  top: 0;
  left: 0;
  width: 100%;
  height: 100px;
  min-height: 100vh;
  overflow: hidden;
`;
const Container = styled.div`
  background: #000407;
  display: flex;
  flex: 1;
  justify-content: start;
  flex-direction: column;
  position: relative;
  width: 100%;
  min-height: 400vh;
`;
const FirstScene = styled.div`
  position: absolute;
  width: 636px;
  top: 35%;
  opacity: 1;
  transition: 0.1s ease;
  left: 0;
  right: 0;
  margin: auto;
  transform: translateX(300px);
  @media (min-width: 1900px) {
    width: 800px;
    transform: translateX(400px);
  }
`;
const SecondScene = styled.div`
  position: absolute;
  width: 636px;
  top: 35%;
  opacity: 0;
  transition: 0.1s ease;
  left: 0;
  right: 0;
  margin: auto;
  transform: translateX(-200px);
  @media (min-width: 1900px) {
    width: 800px;
    transform: translateX(-380px);
  }
`;
const TitleLarge = styled.h5`
  font-weight: 600;
  font-size: 32px;
  line-height: 40px;
  opacity: 1;
  color: #f4f4f4;
  margin-bottom: 8px;
  transition: all 0.1s ease;
  &:nth-of-type(2) {
    opacity: 0.3;
    transition: all 0.1s ease;
  }
  &:nth-of-type(3) {
    opacity: 0.3;
    transition: all 0.1s ease;
  }
  @media (min-width: 1500px) {
    font-size: 40px;
    line-height: 48px;
  }
  @media (min-width: 1900px) {
    font-size: 48px;
    line-height: 56px;
  }
`;
export default Tank;
