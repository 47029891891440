import styled from "styled-components";
import { useRef, useLayoutEffect, useContext } from "react";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { BodyLarge, TitleMedium } from "../../commonComponents/Text";
import ReduceIcon from "../../assets/images/reduceIcon.svg";
import AchieveIcon from "../../assets/images/achieveIcon.svg";
import MaximizeIcon from "../../assets/images/maximizeIcon.svg";
import { Element } from "react-scroll";
import { sectionContext } from "../../context/sectionSelected";
const Concepts = () => {
  const mainContainer: any = useRef();
  const concept1Ref: any = useRef();
  const concept2Ref: any = useRef();
  const concept3Ref: any = useRef();
  const { setShowButton } = useContext(sectionContext);
  useLayoutEffect(() => {
    //progress video with gsap
    gsap.registerPlugin(ScrollTrigger);
    ScrollTrigger.create({
      trigger: mainContainer.current,
      scrub: true,
      start: "top top",
      end: "+=100%",
      id: "pinConcepts",
      pinnedContainer: "#concepts2",
      onEnter: function (self) {
        setShowButton(true);
      },
      onUpdate: function (self) {
        if (mainContainer.current) {
          let scrollPos = self.progress;
          if (scrollPos >= 0 && scrollPos <= 0.3) {
            slideFirstContainer(concept1Ref, scrollPos);
            concept2Ref.current.style.opacity = 0;
            concept3Ref.current.style.opacity = 0;
          } else if (scrollPos >= 0.3 && scrollPos < 0.6) {
            slideSecondContainer(concept2Ref, scrollPos - 0.3);
            concept1Ref.current.style.opacity = 1;
            concept1Ref.current.style.left = "calc(50% - 39vw - 6vw)";
          } else if (scrollPos >= 0.6 && scrollPos < 0.9) {
            slideThirdContainer(concept3Ref, scrollPos - 0.6);
            concept1Ref.current.style.opacity = 1;
            concept2Ref.current.style.opacity = 1;
            concept2Ref.current.style.left = "calc(50% - 14vw)";
          } else if (scrollPos >= 0.9 && scrollPos < 1) {
            concept3Ref.current.style.left = "calc(50% + 11vw + 6vw)";
          } else {
            concept1Ref.current.style.opacity = 1;
            concept2Ref.current.style.opacity = 1;
            concept3Ref.current.style.opacity = 1;
            concept1Ref.current.style.left = "calc(50% - 39vw - 6vw)";
            concept2Ref.current.style.left = "calc(50% - 14vw)";
            concept3Ref.current.style.left = "calc(50% + 11vw + 6vw)";
          }
        }
      },
    });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  function slideFirstContainer(container: any, progress: number) {
    container.current.style.left = `calc(${
      100 - progress * 167
    }% - 39vw - 6vw)`;
    container.current.style.opacity = progress * 4;
  }
  function slideSecondContainer(container: any, progress: number) {
    container.current.style.left = `calc(${100 - progress * 167}% - 14vw)`;
    container.current.style.opacity = progress * 4;
  }
  function slideThirdContainer(container: any, progress: number) {
    container.current.style.left = `calc(${
      100 - progress * 167
    }% + 11vw + 6vw)`;
    container.current.style.opacity = progress * 4;
  }
  return (
    <Element name="#concepts2">
      <Container ref={mainContainer}>
        <ScrollContainer>
          <ConceptContainer ref={concept1Ref}>
            <IconContainer>
              <img src={ReduceIcon} alt="Reduce Icon" />
            </IconContainer>
            <TitleMedium
              style={{
                textTransform: "uppercase",
                marginTop: "16px",
                textAlign: "justify",
              }}
            >
              Reduce
            </TitleMedium>
            <TitleMedium
              isSecondary
              style={{
                textTransform: "uppercase",
                textAlign: "justify",
              }}
            >
              Contamination Risks
            </TitleMedium>
            <BodyLarge style={{ textAlign: "justify" }}>
              Protect your products from contamination and ensure the safety of
              your processes.
            </BodyLarge>
          </ConceptContainer>
          <ConceptContainer className="second" ref={concept2Ref}>
            <IconContainer>
              <img src={AchieveIcon} alt="Achieve Icon" />
            </IconContainer>
            <TitleMedium
              style={{
                textTransform: "uppercase",
                marginTop: "16px",
                textAlign: "justify",
              }}
            >
              Optimize
            </TitleMedium>
            <TitleMedium
              isSecondary
              style={{
                textTransform: "uppercase",
                textAlign: "justify",
              }}
            >
              Resource consumption
            </TitleMedium>
            <BodyLarge style={{ textAlign: "justify" }}>
              Optimize resource usage to reduce costs enhance sustainability.
            </BodyLarge>
          </ConceptContainer>
          <ConceptContainer className="third" ref={concept3Ref}>
            <IconContainer>
              <img src={MaximizeIcon} alt="Maximize Icon" />
            </IconContainer>

            <TitleMedium
              style={{
                textTransform: "uppercase",
                marginTop: "16px",
                textAlign: "justify",
              }}
            >
              Maximize
            </TitleMedium>
            <TitleMedium
              isSecondary
              style={{
                textTransform: "uppercase",
                textAlign: "justify",
              }}
            >
              Efficiency
            </TitleMedium>
            <BodyLarge style={{ textAlign: "justify" }}>
              Get the most out of our bioreactors and improve your production
              yields.
            </BodyLarge>
          </ConceptContainer>
        </ScrollContainer>
      </Container>
    </Element>
  );
};
const ScrollContainer = styled.div`
  position: sticky;
  top: 0;
  left: 0;
  width: 100%;
  height: 100px;
  min-height: 100vh;
  overflow: hidden;
`;
const Container = styled.div`
  display: flex;
  flex: 1;
  justify-content: start;
  flex-direction: column;
  position: relative;
  width: 100%;
  min-height: 200vh;
  background: #000407;
`;
const ConceptContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  position: absolute;
  top: calc(20%);
  left: 100%;
  width: 25vw;
  height: 80vh;
  gap: 6px;
  opacity: 0;
  transition: all 0.1s ease;
  &.second {
    left: 100%;
  }
  &.third {
    left: 100%;
  }
  h5:nth-child(3) {
    font-size: 23px;
  }
  @media (min-width: 1900px) {
    h5:nth-child(2) {
      font-size: 38px;
    }
    h5:nth-child(3) {
      font-size: 28px;
    }
    p {
      font-size: 32px;
    }
  }
`;
const IconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 13vw;
  img {
    width: 13vw;
    height: 13vw;
  }
`;

export default Concepts;
