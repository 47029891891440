import styled from "styled-components";
import { useRef, useLayoutEffect, useContext } from "react";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { TitleLarge } from "../../commonComponents/Text";
import { sectionContext } from "../../context/sectionSelected";

const ProductDescription = () => {
  const mainContainer: any = useRef();
  const backVideoRef: any = useRef();
  const firstSceneRef: any = useRef();
  const secondSceneRef: any = useRef();
  const firstTextRef: any = useRef();
  const secondTextRef: any = useRef();
  const thirdTextRef: any = useRef();
  const fourthTextRef: any = useRef();
  const { setShowButton } = useContext(sectionContext);
  useLayoutEffect(() => {
    //progress video with gsap
    gsap.registerPlugin(ScrollTrigger);
    ScrollTrigger.create({
      trigger: mainContainer.current,
      scrub: true,
      start: "top top",
      end: "+=150%",
      id: "pinDescription",
      pinnedContainer: "#description",
      onEnter: function (self) {
        setShowButton(true);
      },
      onUpdate: function (self) {
        if (backVideoRef.current && backVideoRef.current.duration) {
          let scrollPos = self.progress;
          let videoDuration = backVideoRef.current.duration;
          let videoCurrentTime = videoDuration * scrollPos;
          if (scrollPos === 0) {
            backVideoRef.current.currentTime = 0;
          } else if (scrollPos > 0 && scrollPos < 1) {
            if (videoCurrentTime)
              backVideoRef.current.currentTime = videoCurrentTime;
          } else {
            backVideoRef.current.currentTime = backVideoRef.current.duration;
          }
        }
        if (firstTextRef.current) {
          let scrollPos = self.progress;
          if (scrollPos === 0) {
            firstSceneRef.current.style.top = "30%";
            firstSceneRef.current.style.opacity = 1;
            firstTextRef.current.style.opacity = 1;
            secondTextRef.current.style.opacity = 0.3;
            backVideoRef.current.style.transform = `translateX(-600px)`;
          } else if (scrollPos > 0 && scrollPos < 0.2) {
            fadeOutText(firstTextRef, scrollPos);
            fadeInText(secondTextRef, scrollPos);
            slideFirstScene(firstTextRef, scrollPos, 0);
          } else if (scrollPos >= 0.2 && scrollPos < 0.3) {
          } else if (scrollPos >= 0.3 && scrollPos < 0.4) {
            slideFirstScene(firstTextRef, scrollPos - 0.3, 15);
            fadeOutTopContainer(firstSceneRef, scrollPos - 0.3);
            sliderVideo(backVideoRef, scrollPos - 0.3);
          } else if (scrollPos >= 0.4 && scrollPos < 0.5) {
            slideFirstScene(firstTextRef, scrollPos - 0.3, 15);
            fadeOutTopContainer(firstSceneRef, scrollPos - 0.3);
            sliderVideo(backVideoRef, scrollPos - 0.3);
            fadeInTopContainer(secondSceneRef, scrollPos - 0.4);
          } else if (scrollPos >= 0.5 && scrollPos < 0.6) {
            fadeInTopContainer(secondSceneRef, scrollPos - 0.4);
          } else if (scrollPos >= 0.6 && scrollPos < 0.7) {
          } else if (scrollPos >= 0.7 && scrollPos < 0.9) {
            fadeOutText(thirdTextRef, scrollPos - 0.7);
            fadeInText(fourthTextRef, scrollPos - 0.7);
          } else {
            firstSceneRef.current.style.opacity = 0.483512;
            secondSceneRef.current.style.opacity = 1;
            firstTextRef.current.style.opacity = 0.3;
            secondTextRef.current.style.opacity = 1;
            thirdTextRef.current.style.opacity = 0.3;
            fourthTextRef.current.style.opacity = 1;
          }
          if (scrollPos < 0.3) {
            backVideoRef.current.style.transform = `translateX(-600px)`;
          }
          if (scrollPos >= 0.5) {
            backVideoRef.current.style.transform = `translateX(100px)`;
            firstSceneRef.current.style.top = "-29.5948vh";
            firstSceneRef.current.style.opacity = 0;
          }
          if (scrollPos < 0.4) {
            secondSceneRef.current.style.top = "100vh";
            secondSceneRef.current.style.opacity = 0;
          }
          if (scrollPos >= 0.6) {
            secondSceneRef.current.style.top = "30vh";
          }
        }
      },
    });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  function fadeOutText(textRef: any, progress: number) {
    textRef.current.style.opacity = 1 - progress * 3.6;
  }
  function fadeInText(textRef: any, progress: number) {
    textRef.current.style.opacity = 0.3 + progress * 3.6;
  }
  function slideFirstScene(textRef: any, progress: number, top: number) {
    textRef.current.style.marginTop = `-${top + progress * 75}px`;
  }
  function fadeOutTopContainer(containerRef: any, progress: number) {
    containerRef.current.style.opacity = 1 - progress * 2.6;
    containerRef.current.style.top = `${30 - progress * 370}vh`;
  }
  function fadeInTopContainer(containerRef: any, progress: number) {
    containerRef.current.style.opacity = progress * 5.2;
    containerRef.current.style.top = `${100 - progress * 355}vh`;
  }
  function sliderVideo(videoRef: any, progress: number) {
    videoRef.current.style.transform = `translateX(${
      -600 + progress * 3500
    }px)`;
  }

  return (
    <Container ref={mainContainer} id="description">
      <ScrollContainer>
        <Video
          ref={backVideoRef}
          preload="auto"
          webkit-playsinline="true"
          muted
          src="/background-2.mp4"
        />
        <FirstContainer ref={firstSceneRef}>
          <TitleLarge
            isSecondary
            style={{
              textTransform: "uppercase",
              textShadow:
                "0px 0px 10px rgba(56, 118, 255, 0.60), 0px 0px 4px rgba(56, 118, 255, 0.25)",
              marginBottom: "10px",
            }}
          >
            EMBRACE THE POWER
          </TitleLarge>
          <TitleLarge>
            <span ref={firstTextRef} style={{ opacity: 1 }}>
              Control of every aspect,
              <br /> with just a compact
              <br /> bioreactor and power supply.
            </span>
          </TitleLarge>
          <TitleLarge>
            <span ref={secondTextRef} style={{ opacity: 0.3 }}>
              No more probes, pumps,
              <br /> gas inlets or even autoclaving.
            </span>
          </TitleLarge>
        </FirstContainer>
        <SecondContainer ref={secondSceneRef} style={{ textAlign: "end" }}>
          <TitleLarge
            isSecondary
            style={{
              textTransform: "uppercase",
              textShadow:
                "0px 0px 10px rgba(56, 118, 255, 0.60), 0px 0px 4px rgba(56, 118, 255, 0.25)",
              marginBottom: "10px",
            }}
          >
            Let us handle the complexity
          </TitleLarge>
          <TitleLarge>
            <span ref={thirdTextRef} style={{ opacity: 1 }}>
              Easy to use with little training, BION is <br />
              continuously learning.
            </span>
          </TitleLarge>
          <TitleLarge>
            <span ref={fourthTextRef} style={{ opacity: 0.3 }}>
              to improve performance.
            </span>
          </TitleLarge>
        </SecondContainer>
      </ScrollContainer>
    </Container>
  );
};
const Video = styled.video`
  position: absolute;
  object-fit: fill;
  z-index: -1;
  top: -20%;
  left: -35vw;
  width: 100%;
  height: 100vh;
  transition: all 0.1s ease;
  left: 0;
  right: 0;
  margin: auto;
  bottom: 0;
  transform: translateX(-600px);
  width: 800px;
`;
const ScrollContainer = styled.div`
  position: sticky;
  top: 0;
  left: 0;
  width: 100%;
  height: 100px;
  min-height: 100vh;
  overflow: hidden;
`;
const Container = styled.div`
  background: #000307;
  display: flex;
  flex: 1;
  justify-content: start;
  flex-direction: column;
  position: relative;
  width: 100%;
  min-height: 250vh;
  transition: all 0.3s ease-in-out;
`;
const FirstContainer = styled.div`
  position: absolute;
  width: 561px;
  top: 30vh;
  opacity: 1;
  transition: 0.1s all ease;
  margin-top: 0px;
  h5 {
    transition: 0.1s all ease;
  }
  left: 0;
  right: 0;
  margin: auto;
  bottom: 0;
  transform: translateX(300px);
  @media (min-width: 1900px) {
    width: 800px;
    transform: translateX(420px);
  }
`;
const SecondContainer = styled.div`
  position: absolute;
  width: 561px;
  top: 100vh;
  opacity: 0;
  left: 5%;
  transition: 0.1s all ease;
  margin-top: 0px;
  left: 0;
  right: 0;
  margin: auto;
  bottom: 0;
  transform: translateX(-400px);
  h5 {
    transition: 0.1s all ease;
  }
  @media (min-width: 1900px) {
    width: 700px;
    transform: translateX(-500px);
  }
`;

export default ProductDescription;
