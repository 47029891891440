import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import "firebase/compat/auth";
import "firebase/compat/storage";

const config = {
    apiKey: "AIzaSyDLd31hTVmBuovXSosjKl9HMH4aXPs8LoI",
    authDomain: "cultzyme-9cbeb.firebaseapp.com",
    projectId: "cultzyme-9cbeb",
    storageBucket: "cultzyme-9cbeb.appspot.com",
    messagingSenderId: "236613968635",
    appId: "1:236613968635:web:e888537fb1e6021008465e",
    measurementId: "G-YT1RCFTLGS"
};


firebase.initializeApp(config);


const auth = firebase.auth();
const firestore = firebase.firestore();
const storageRef = firebase.storage().ref();

export { firestore, auth, storageRef };
