import { Element } from "react-scroll";
import styled, { keyframes } from "styled-components";
import { useLayoutEffect, useRef, useState } from "react";
import { TitleMedium } from "../../commonComponents/Text";
import { Autoplay, Navigation, Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react/swiper-react.js";
import SwiperCore from "swiper";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { useCollectionData } from "react-firebase-hooks/firestore";
import { firestore } from "../../utils/firebase";
export interface SuuportOrPartnership {
  id: number;
  imageURL: string;
  name: string;
}
SwiperCore.use([Pagination, Navigation, Autoplay]);
const SupportMobile = () => {
  const [buttonSelected, setButtonSelected] = useState<
    "support" | "partnership"
  >("support");
  const [render, setRender] = useState<boolean>(true);
  const mainContainer: any = useRef();
  const titleRef: any = useRef();
  const swipperRef: any = useRef();
  const bottomRef: any = useRef();
  const ellipseRef: any = useRef();
  const supportRef = firestore
    .collection("support")
    .where("type", "==", "support");
  const partnershipRef = firestore
    .collection("support")
    .where("type", "==", "partnership");
  const [supportContent, ,] = useCollectionData(supportRef);
  const [partnerShipContent, ,] = useCollectionData(partnershipRef);
  function handleChange(button: "support" | "partnership") {
    setButtonSelected(button);
    setRender(false);
    setTimeout(() => {
      setRender(true);
    }, 100);
  }

  useLayoutEffect(() => {
    //progress video with gsap
    gsap.registerPlugin(ScrollTrigger);
    ScrollTrigger.create({
      trigger: mainContainer.current,
      scrub: true,
      start: "top top",
      end: "+=100%",
      id: "pinTeam",
      pinnedContainer: "#support",
      onUpdate: function (self) {
        if (mainContainer.current) {
          let scrollPos = self.progress;
          if (scrollPos === 0) {
            titleRef.current.style.opacity = 0;
            swipperRef.current.style.opacity = 0;
            bottomRef.current.style.opacity = 0;
            ellipseRef.current.style.opacity = 0;
          } else if (scrollPos > 0 && scrollPos < 0.3) {
            fadeInContainer(titleRef, scrollPos);
            fadeInEllipse(ellipseRef, scrollPos);
          } else if (scrollPos >= 0.3 && scrollPos < 0.35) {
          } else if (scrollPos >= 0.35 && scrollPos < 0.65) {
            fadeInContainer(swipperRef, scrollPos - 0.35);
            fadeInContainer(bottomRef, scrollPos - 0.35);
          } else if (scrollPos >= 0.65 && scrollPos < 0.7) {
          } else if (scrollPos >= 0.7 && scrollPos < 1) {
          } else {
            titleRef.current.style.opacity = 1;
            swipperRef.current.style.opacity = 1;
            bottomRef.current.style.opacity = 1;
            ellipseRef.current.style.opacity = 1;
          }
          if (scrollPos > 0.3) {
            titleRef.current.style.opacity = 1;
            ellipseRef.current.style.opacity = 1;
          }
          if (scrollPos > 0.65) {
            swipperRef.current.style.opacity = 1;
          }
          if (scrollPos < 0.35) {
            swipperRef.current.style.opacity = 0;
            bottomRef.current.style.opacity = 0;
          }
        }
      },
    });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  function fadeInContainer(containerRef: any, progress: number) {
    containerRef.current.style.opacity = `${progress * 3.35}`;
  }
  function fadeInEllipse(ellipse: any, progress: number) {
    ellipse.current.style.opacity = progress * 3.4;
  }
  return (
    <Element name="#support">
      <Container ref={mainContainer} id="support">
        <ScrollContainer animated={false}>
          <EllipseContainer ref={ellipseRef}>
            <Ellipse />
          </EllipseContainer>
          <TopContainer ref={titleRef}>
            <TitleMedium
              style={{
                textTransform: "uppercase",
                textShadow:
                  "0px 0px 25px rgba(56, 118, 255, 0.50), 0px 0px 4px rgba(56, 118, 255, 0.25)",
                textAlign: "center",
              }}
              isSecondary
            >
              SUPPORT & PARTNERSHIP
            </TitleMedium>
          </TopContainer>
          <SwipperContainer ref={swipperRef}>
            <ToggleContainer>
              <Toggle>
                <ToggleButton
                  onClick={() => handleChange("support")}
                  className={buttonSelected === "support" ? "selected" : ""}
                >
                  <ToggleText>Support</ToggleText>
                </ToggleButton>
                <ToggleButton
                  onClick={() => handleChange("partnership")}
                  className={buttonSelected === "partnership" ? "selected" : ""}
                >
                  <ToggleText>Partnership</ToggleText>
                </ToggleButton>
              </Toggle>
            </ToggleContainer>
            <SwiperContainer>
              {render && buttonSelected === "support" && (
                <SwiperWrapper
                  slidesPerView={1}
                  autoplay={{
                    delay: -1000,
                    disableOnInteraction: false,
                    pauseOnMouseEnter: true,
                  }}
                  className={`mySwiper2`}
                  speed={5500}
                  freeMode={true}
                  loopedSlides={supportContent?.length}
                  loop={true}
                >
                  {supportContent &&
                    supportContent.map((item: SuuportOrPartnership, index) => (
                      <SwiperSlide style={{ color: "white" }} key={index}>
                        <Card>
                          <CardImageContainer>
                            <CardImageStyled
                              src={item.imageURL}
                              alt={item.name}
                            />
                          </CardImageContainer>
                        </Card>
                      </SwiperSlide>
                    ))}
                </SwiperWrapper>
              )}
              {render && buttonSelected === "partnership" && (
                <SwiperWrapper
                  slidesPerView={1}
                  className={`mySwiper2`}
                  autoplay={{
                    delay: -1000,
                    disableOnInteraction: false,
                    pauseOnMouseEnter: true,
                  }}
                  speed={5500}
                  freeMode={true}
                  loopedSlides={partnerShipContent?.length}
                  loop={true}
                >
                  {partnerShipContent &&
                    partnerShipContent.map(
                      (item: SuuportOrPartnership, index) => (
                        <SwiperSlide style={{ color: "white" }} key={index}>
                          <Card>
                            <CardImageContainer>
                              <CardImageStyled
                                src={item.imageURL}
                                alt={item.name}
                              />
                            </CardImageContainer>
                          </Card>
                        </SwiperSlide>
                      )
                    )}
                </SwiperWrapper>
              )}
            </SwiperContainer>
          </SwipperContainer>
          <BottomContainer ref={bottomRef}>
            <TitleMedium
              style={{
                textTransform: "uppercase",
                textShadow:
                  "0px 0px 10px  rgba(56, 118, 255, 0.60), 0px 0px 4px rgba(56, 118, 255, 0.25)",
                textAlign: "center",
              }}
              isSecondary
            >
              WORKING TOGETHER TO MAKE A DIFFERENCE
            </TitleMedium>
          </BottomContainer>
        </ScrollContainer>
      </Container>
    </Element>
  );
};
const SwiperContainer = styled.div`
  width: 100%;
  height: auto;
  min-height: 72px;
`;
const ButtonIn = keyframes`
  from {
    background:transparent;
    border: 1px solid transparent;
  }
  to {
    background: #3876ff;
    border: 1px solid #2c60d6;
  }
`;
const ButtonOut = keyframes`
  from {
    background: #3876ff;
  }
  to {
    background:transparent;
  }
`;
const SwipperContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  gap: 32px;
  width: 100%;
  height: 55%;
  z-index: 2;
  opacity: 0;
  transition: all 0.1s ease;
  padding: 9vh 0;
`;
const BottomContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  gap: 32px;
  width: 100%;
  height: 15%;
  z-index: 2;
  transition: all 0.1s ease;
  opacity: 0;
  padding: 0 16px;
`;
const ToggleContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 74px;
  z-index: 2;
`;
const Toggle = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 272px;
  height: 72px;
  background: #1f1f1f;
  border-radius: 100px;
  gap: 8px;
  border: 1px solid var(--grey-3, #333);
`;
const ToggleButton = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 120px;
  height: 48px;
  border-radius: 100px;
  background: transparent;
  border: 1px solid transparent;
  animation: ${ButtonOut} 0.5s ease-in-out;
  cursor: pointer;
  &.selected {
    animation: ${ButtonIn} 0.5s ease-in-out;
    background: #3876ff;
    border: 1px solid #2c60d6;
  }
`;
const ToggleText = styled.h2`
  color: #fff;
  font-family: Montserrat;
  color: #f4f4f4;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
`;
const ScrollContainer = styled.div<{ animated: boolean }>`
  position: sticky;
  top: 0;
  left: 0;
  width: 100%;
  height: 100px;
  min-height: 100vh;
  overflow: hidden;
`;
const Container = styled.div`
  background: #000407;
  display: flex;
  flex: 1;
  justify-content: start;
  align-items: center;
  flex-direction: column;
  position: relative;
  width: 100%;
  height: 200vh;
`;
const TopContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 25%;
  position: relative;
  justify-content: flex-end;
  padding-left: 0vw;
  opacity: 0;
  transition: all 0.1s ease;
`;
const CardImageContainer = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
`;
const SwiperWrapper = styled(Swiper)`
  width: calc(90% - 64px);
  padding: 0 32px;
  width: 100%;
  max-height: 100px;
  .swiper-wrapper {
    transition-timing-function: cubic-bezier(0.06, 0.15, 1, 0.86);
  }
  @media (max-width: 480px) {
    margin: 0;
    padding: 0px;
    width: 100%;
  }
  transition-timing-function: cubic-bezier(0.06, 0.15, 1, 0.86);
`;
const Card = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 90vw;
  height: 100%;
  max-height: 100px;
  position: relative;
  border-radius: 4px;
  z-index: 10;
  transition: all 0.3s ease-in-out;
  overflow: hidden;
  border-radius: 4px;
`;
const CardImageStyled = styled.img`
  width: 100%;
  height: 100%;
  max-height: 70px;
  object-fit: fill;
  position: relative;
`;
const EllipseContainer = styled.div`
  position: absolute;
  width: 60vw;
  height: 60vw;
  left: -17%;
  top: -50%;
  transition: all 0.1s ease;
  z-index: 0;
  opacity: 0;
`;
const Ellipse = styled.div`
  background: radial-gradient(
    50% 50% at 50% 50%,
    rgba(73, 149, 182, 0.29) 0%,
    rgba(108, 201, 242, 0) 100%
  );
  filter: blur(24.733078002929688px);
  position: absolute;
  width: 60vw;
  height: 60vw;
`;
export default SupportMobile;
