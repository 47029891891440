import styled from "styled-components";
import { useRef, useLayoutEffect, useState } from "react";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

const Pretank = () => {
  const containerRef: any = useRef();
  const tank2VideoRef: any = useRef();
  const firstSceneRef: any = useRef();
  const [loadMetadata, setLoadMetadata] = useState<boolean>(false);
  useLayoutEffect(() => {
    if (loadMetadata) {
      if (tank2VideoRef.current) {
        gsap.registerPlugin(ScrollTrigger);
        ScrollTrigger.create({
          trigger: containerRef.current,
          scrub: true,
          start: "top top",
          end: "+=200%",
          id: "pinPretank",
          pinnedContainer: "#pretank",
          onLeaveBack: function (self) {
            const element = document.getElementById("pretank")?.offsetTop;
            if (element) {
              setTimeout(() => {
                window.scrollTo({
                  top: element - window.innerHeight,
                });
              }, 25);
            }
          },
          onLeave: function (self) {
            const element = document.getElementById("tank");
            if (element) {
              element.scrollIntoView();
            }
          },
          onUpdate: function (self) {
            if (tank2VideoRef.current && tank2VideoRef.current.duration) {
              let scrollPos = self.progress;
              let video2Duration = tank2VideoRef.current.duration;
              let video2CurrentTime = video2Duration * scrollPos;
              if (scrollPos === 0) {
                tank2VideoRef.current.style.opacity = 1;
                tank2VideoRef.current.currentTime = 0;
                tank2VideoRef.current.style.transform = "translateX(-0px)";
                tank2VideoRef.current.style.width = "100vw";
                tank2VideoRef.current.style.height = "100vh";
                tank2VideoRef.current.style.top = "0";
                firstSceneRef.current.style.opacity = "0";
              } else if (scrollPos > 0 && scrollPos < 0.66) {
                if (video2CurrentTime) {
                  tank2VideoRef.current.currentTime = video2CurrentTime;
                }
              } else if (scrollPos >= 0.66 && scrollPos < 1) {
                tank2VideoRef.current.currentTime = video2CurrentTime;
                resizeTank(tank2VideoRef, scrollPos - 0.66);
                if (scrollPos > 0.8) {
                  fadeIn(firstSceneRef, scrollPos - 0.8);
                }
              } else {
                tank2VideoRef.current.currentTime =
                  tank2VideoRef.current.duration;
                tank2VideoRef.current.style.transform = "translateX(-400px)";
                tank2VideoRef.current.style.width = "75vw";
                tank2VideoRef.current.style.height = "75vh";
                tank2VideoRef.current.style.top = "10%";
                firstSceneRef.current.style.opacity = "1";
              }
              if (scrollPos < 0.8) {
                if (firstSceneRef.current) {
                  firstSceneRef.current.style.opacity = "0";
                }
              }
            }
          },
        });
      }
    }
  }, [loadMetadata]); // eslint-disable-line react-hooks/exhaustive-deps

  function resizeTank(containerRef: any, progress: number) {
    containerRef.current.style.width = `calc(100vw - ${progress * 76}vw)`;
    containerRef.current.style.height = `calc(100vh - ${progress * 76}vh)`;
    containerRef.current.style.top = `calc(${progress * 34}%)`;
    containerRef.current.style.transform = `translateX(-${progress * 1212}px)`;
  }

  function fadeIn(containerRef: any, progress: number) {
    containerRef.current.style.opacity = `${progress * 5}`;
  }

  return (
    <Container ref={containerRef} id="pretank">
      <ScrollContainer>
        <Video2
          ref={tank2VideoRef}
          preload="auto"
          webkit-playsinline="true"
          muted
          src="/zoomin.mp4"
          onLoadedMetadata={() => {
            setLoadMetadata(true);
          }}
        />
        <FirstScene ref={firstSceneRef}>
          <TitleLarge>
            Unlock real-time information
            <br />
            without the necessity of extractions.
          </TitleLarge>
          <TitleLarge>Simplified scale up to</TitleLarge>
          <TitleLarge>The volume you determine.</TitleLarge>
        </FirstScene>
      </ScrollContainer>
    </Container>
  );
};
const FirstScene = styled.div`
  position: absolute;
  width: 636px;
  top: 35%;
  opacity: 0;
  transition: 0.1s ease;
  left: 0;
  right: 0;
  margin: auto;
  transform: translateX(300px);
  @media (min-width: 1900px) {
    width: 800px;
    transform: translateX(400px);
  }
`;
const Video2 = styled.video`
  position: absolute;
  object-fit: cover;
  z-index: -1;
  width: 100vw;
  height: 100vh;
  transition: all 0.1s ease;
  left: 0;
  right: 0;
  margin: auto;
  top: 0;
  bottom: 0;
  opacity: 1;
`;
const ScrollContainer = styled.div`
  position: sticky;
  top: 0;
  left: 0;
  width: 100%;
  height: 100px;
  min-height: 100vh;
  overflow: hidden;
`;
const Container = styled.div`
  background: #000407;
  display: flex;
  flex: 1;
  justify-content: start;
  flex-direction: column;
  position: relative;
  width: 100%;
  min-height: 300vh;
`;

const TitleLarge = styled.h5`
  font-weight: 600;
  font-size: 32px;
  line-height: 40px;
  opacity: 1;
  margin-bottom: 8px;
  color: #f4f4f4;
  transition: all 0.1s ease;
  &:nth-of-type(2) {
    opacity: 0.3;
    transition: all 0.1s ease;
  }
  &:nth-of-type(3) {
    opacity: 0.3;
    transition: all 0.1s ease;
  }
  @media (min-width: 1500px) {
    font-size: 40px;
    line-height: 48px;
  }
  @media (min-width: 1900px) {
    font-size: 48px;
    line-height: 56px;
  }
`;
export default Pretank;
